.faq-second-section .accordion-item {
  border-bottom: 1px solid var(--border-color-1);
}

.faq-second-section .accordion-button {
  color: inherit !important;
}

.accordian-dot-icons {
  color: inherit !important;
}

.height-container .form-control {
  background-color: transparent;
}

.blue-dark .custom-dropdown .dropdown-item {
  color: #fff;
  font-size: 14px;
}

.custom-dropdown .dropdown-item:focus,
.custom-dropdown .dropdown-item:hover {
  color: #1e2125 !important;
}

.deposit-fourth-section th {
  color: var(--color-1);
  background-color: var(--background-color-1);
}

.deposit-fourth-section td {
  color: var(--color-1);
}

.transaction-history-tabs-section .nav-link {
  color: var(--color-1);
}

.deposit-second-section {
  background-color: var(--background-color-1);
}

.deposit-crypto-modal .modal-content {
  background-color: var(--background-color-1);
}

#myInput2 {
  background-color: transparent;
  border: 1px solid rgb(14, 203, 129);
  color: var(--color-1);
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.payment-qrcode-optional-button {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.table {
  color: var(--color-1) !important;
}

.css-1mxz8qt-MuiPaper-root {
  background-color: var(--background-color-1) !important;
}

.css-11w94w9-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-10dfkli-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-177gid-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-1azl6jz-MuiTableCell-root {
  color: var(--color-1) !important;
}

.css-jtlhu6-MuiTablePagination-root {
  color: var(--color-1) !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  color: var(--color-1) !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--color-1) !important;
}

.css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--color-1) !important;
}

.referral-page-top-banner-section .nav-tabs .nav-link {
  color: var(--color-1) !important;
}

.bg-card {
  background-color: var(--background-color-1) !important;
}

.bg-card-inner {
  background-color: var(--background-color-2) !important;
  border-radius: 20px;
}

.bg-Dark-New {
  color: var(--color-1) !important;
}

.enter-amount-section {
  background-color: var(--background-color-1) !important;
}

.f-control {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.p2p-trade-table .p2p-trade-table-tr {
  background-color: var(--background-color-1) !important;
}

.p2p-trade-table .p2p-trade-table-th {
  color: var(--color-1) !important;
}

.faq-section {
  margin-top: 40px;
  background-color: var(--background-color-1) !important;
  padding: 5vh 0 5vh;
}

.p2p-trade-hero-section-two-nav-bg {
  background-color: var(--background-color-1) !important;
}

.p2p-user-center-blocked-user-table table th {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

#scrollspy-section-3-para {
  background-color: var(--background-color-2) !important;
  border: 1px solid var(--border-color-1);
}

#scrollspy-section-2-para {
  background-color: var(--background-color-2) !important;
  border: 1px solid var(--border-color-1);
}

#scrollspy-section-1-para {
  background-color: var(--background-color-2) !important;
  border: 1px solid var(--border-color-1);
}

.select-dropdown-btn {
  color: var(--color-1) !important;
}

.password-text-33 {
  font-weight: 500;
  font-size: 15px;
  font-family: sans-serif;
}

.password-text-44 {
  font-weight: 800;
  font-size: 18px;
  font-family: sans-serif;
}

.password-text-55 {
  font-weight: 800;
  font-size: 22px;
  font-family: sans-serif;
}

.password-text-66 {
  font-weight: 600;
  font-size: 17px;
  font-family: sans-serif;
}

.password-text-77 {
  font-weight: 500;
  font-size: 16px;
  font-family: sans-serif;
}

/* .btn-light {
    background-color: var(--background-color-1);
    color: var(--color-1) !important;
} */
.btn-light:hover {
  background-color: var(--btnClr);
}

.card {
  border: 1px solid var(--border-color-1);
}

.device-management-table td {
  font-weight: 400;
  font-size: 15px;
  font-family: sans-serif;
}

.nav-tabs-buy-sell .nav-link.active {
  background-color: var(--primary-color) !important;
  color: var(--primary-text-color) !important;
}

.nav-pills-buy-sell .nav-pills .nav-link {
  color: var(--color-1) !important;
}

.p2p-second-nav-content-title {
  color: var(--color-1) !important;
}

.p2p-second-nav-icons {
  color: var(--color-1) !important;
}

.dropdown-item {
  /* color: var(--color-1) !important; */
}

.dropdown-item:hover {
  background-color: var(--navLightBg) !important;
  color: var(--goldColor) !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--btnClr) !important;
  border-color: var(--btnClr) !important;
}

.page-link {
  color: var(--color-1) !important;
  box-shadow: none !important;
}

.select-dropdown-btn-buysell {
  background-color: var(--input-box-bg) !important;
  border: none;
  border-radius: 5px;
  color: var(--input-box-text-color) !important;
}

.place-order-buy-sell-tabs .nav-pills {
  background-color: var(--input-box-bg) !important;
  /* border: 1px solid var(--border-color-1); */
  border: none;
  border-radius: 5px;
}
.place-order-buy-sell-tabs .nav-pills .nav-item:focus-visible{
  border: none !important;
}
.add-payment-method-cancel-button {
  background-color: var(--background-color-1) !important;
}

.postAd-top-section .form-control {
  background-color: var(--background-color-2) !important;
  color: var(--color-1) !important;
  border: 1px solid var(--border-color-1);
}

.pan-input-text {
  color: var(--color-1) !important;
}

.dropbtn1 {
  background-color: transparent !important;
}

.dropdown-content1 {
  background-color: var(--background-color-1) !important;
}

.paired-trade-dropdown-table th {
  color: var(--color-1) !important;
}

.place-order-section-top .form-check-input {
  background-color: #b5fff1 !important;
}
.spot-place-order .form-check-input {
  background-color: var(--primary-color) !important;
}
.banking-border-bottom {
  border-bottom: 1px solid var(--border-color-1);
}

.dark-new .inner-third-section {
  background-color: #14161a !important;
}

.dark-new .top-banner-eight-section .card {
  background-color: #14161a !important;
}

.dark-new .benefits-text-1 {
  color: #fff;
}

.phone-number-verification-change-top-banner .form-control {
  color: var(--color-1) !important;
}

.PhoneInputInput {
  color: var(--color-1) !important;
}

.placeorder-container .form-control {
  /* color: var(--color-1) !important; */
}

.dark-new .height-container .btn-light {
  border: none;
  background-color: #14161a !important;
  color: var(--color-1) ;
}

.dark-new  .height-container  .btn-light.active,
.dark-new .height-container .btn-light:active {
  color: var(--primary-text-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: #ffd748 0px 0px 10px,
  rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
  rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
  #f7a600 0px 0px 15px inset !important; 
  /* box-shadow: none !important;*/
}
.bg-sidenav .btn-light.active{
  background-color: var(--btnClr) !important;
  box-shadow: none !important;
}
.css-xc836r-control {
  border-color: var(--border-color-1) !important;
}

.dark-new .css-1dimb5e-singleValue {
  color: var(--color-1) !important;
}

.dark-new .css-1nmdiq5-menu {
  background-color: #c7c7c791 !important;
}

.dark-new .status_dropdown .dropdown-item:hover {
  color: #fff !important;
}

.dark-new .status_dropdown .dropdown-item:hover {
  color: #000 !important;
}

/* .modal-content {
    
    background-color: transparent !important;
    
} */

/* .dark-new .thememodal   {
    background-color:rgb(0, 0, 0) !important;
  } */

/* .modal-body {
    background-color: var(--background-color-1) !important;;
} */

/* .light-new .thememodal .modal-content {
    background-color: #000 !important;
  } */

.form-control {
  /* background-color: var(--background-color-1) !important; */
  /* color: var(--color-1) !important; */
}

.dropdown-content {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.dark-new .rmsc.dark {
  --rmsc-main: #4285f4;
  --rmsc-hover: #0e0c0a;
  --rmsc-selected: #1d1915;
  --rmsc-border: #333333;
  --rmsc-gray: #555555;
  --rmsc-bg: #000000;
  color: #fff;
}

.dark-new.thememodal {
  background-color: transparent !important;
  color: #fff !important;
}

.light-new.thememodal {
  background-color: transparent !important;
  color: #000 !important;
}

.dark-new.thememodal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.dark-new .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid rgb(255, 255, 255) !important;
}

.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: 1px solid rgb(0, 0, 0) !important;
}

.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgb(255, 255, 255) !important;
}

.dark-new
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff !important;
}
.dark-new .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: #fff !important;
}

.register-section-top .PhoneInput {
  border: 1px solid #ced4da !important;
  padding-left: 10px !important;
}

.register-section-top .PhoneInput {
  border-radius: 0.25rem;
  color: var(--color-1) !important;
  background-color: var(--background-color-1) !important;
}

.dark-new .shadow-lg {
  box-shadow: 0 1rem 3rem #00dbaf1f !important;
}
.accordion-item {
  border: 1px solid var(--border-color-1);
}
.dark-new .modal-content {
  background-color: rgb(0, 0, 0);
}
.datepicker-css-styling {
  color: var(--color-1) !important;
}
.form-select {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}
.rmsc .dropdown-container {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
  border: 1px solid var(--border-color-1);
}
.height-container .PhoneInput {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  padding-left: 15px !important;
  background-color: var(--background-color-1) !important;
}
.placeorder-container input{
  color: var(--input-box-text-color) !important;
}
input {
  color: var(--color-1) !important;
}
/* .pan-input-text .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    color: var(--color-1) !important;
  }
  */

fieldset {
  border: 1px solid var(--border-color-1) !important;
  color: var(--color-1) !important;
}

/* .pan-input-text{
    color: var(--color-1) !important;
    } */

label {
  color: var(--color-1) !important;
}

.phone-number-verification-change-top-banner .form-control {
  color: var(--color-1) !important;
}

.dark-new .tab-content {
  background-color: transparent !important;
  color: white !important;
}

table {
  background-color: var(--background-color-1);
  color: var(--color-1) !important;
}

table tr {
  background-color: var(--background-color-1);
  color: var(--color-1) !important;
}

table th {
  /* / background-color: var(--background-color-1); / */
  color: var(--color-1) !important;
}

table td {
  /* / background-color: var(--background-color-1); / */
  color: var(--color-1) !important;
}

table thead {
  /* / background-color: var(--background-color-1); / */
  color: var(--color-1) !important;
}

.MuiTablePagination-root {
  background-color: var(--background-color-1) !important ;
  color: var(--color-1) !important;
}

.MuiSvgIcon-root {
  color: var(--color-1) !important;
}

.form-control {
  /* background-color: var(--background-color-1) !important; */
  color: var(--color-1);
}
.dropdown-content {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.dark-new .rmsc.dark {
  --rmsc-main: #4285f4;
  --rmsc-hover: #0e0c0a;
  --rmsc-selected: #1d1915;
  --rmsc-border: #333333;
  --rmsc-gray: #555555;
  --rmsc-bg: #000000;
  color: #fff;
}

.rmsc .dropdown-container {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
  border: 1px solid var(--border-color-1) !important;
}

.datepicker-css-styling {
  color: var(--color-1) !important;
}
.dark-new .modal-content {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.form-select {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}

.overflow-x-scroll-arrow-section-2 {
  position: relative;
  z-index: 999;
}

.horizon-prev {
  position: absolute;
  left: 5px;
  top: 35px;
  z-index: 999;
}

.horizon-next {
  position: absolute;
  right: 5px;
  top: 35px;
  z-index: 999;
}
.horizon-next,
.horizon-prev {
  background-color: rgba(128, 128, 128, 0.747);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.644) !important;
  line-height: 20px;
  font-size: 12px;
}
.deposit-select-coin-section {
  background-color: var(--background-color-1);
}
.markets-search-group-section .input-group-text {
  background-color: var(--background-color-1) !important;
  /* color: var(--color-1) !important; */
}
.page-item.disabled .page-link {
  background-color: var(--background-color-1) !important;
}
.page-link {
  background-color: var(--background-color-1) !important;
}

.rdt_TableHeadRow {
  background-color: #ccc !important;
  color: #000 !important;
}
.rdt_TableRow {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}
.rdt_Pagination {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}
.trading-competition-inner-box {
  background-color: var(--background-color-3) !important;
}
.trading-card-box-design {
  background-color: var(--background-color-3) !important;
}

.crypto-loan-input-field {
  background-color: var(--background-color-3) !important;

}
.prediction-overrall-border{
  border: 1px solid var(--border-color-1) !important;
}
/* .prediction-page-left-section {
  background-color: var(--background-color-1) !important;
}
.prediction-page-right-section {
  background-color: var(--background-color-1) !important;
}
.prediction-page-features-section {
  background-color: var(--background-color-4) !important;
}
.prediction-page-features-section-2 {
  background-color: var(--background-color-4) !important;
}
.prediction-chat-section {
  background-color: var(--background-color-4) !important;
}
.prediction-features-list:hover {
  background-color: var(--background-color-4) !important;
}
.prediction-page-right-section .form-control {
  border: 1px solid var(--border-color-2) !important;
}
.prediction-page-right-section .input-group-text {
  border: 1px solid var(--border-color-2) !important;
  color: var(--color-1) !important;
}
.prediction-caro-icon-2 {
  background-color: var(--background-color-4) !important;
  color: var(--color-1) !important;
}
.prediction-page-middle-section .swiper-card {
  background-color: var(--background-color-4) !important;
  color: var(--color-1) !important;
}
.prediction-swiper-card-button {
  background-color: #8f8f8f2d !important;
  color: var(--color-1) !important;
}
.dark-new .prediction-bets-table-section tr {
  background-color: var(--background-color-4) !important;
  border-bottom: 3px solid #07080a !important;
}
.light-new .prediction-bets-table-section tr {
  background-color: var(--background-color-4) !important;
  border-bottom: 3px solid #ffffff !important;
}
.prediction-trade-left-section {
  background-color: var(--background-color-1) !important;
}
.prediction-trade-tick-button {
  background-color: var(--background-color-4) !important;
}
.prediction-trade-tick-button {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}
.prediction-trade-right-2 {
  background-color: var(--background-color-1) !important;
}
.prediction-manual-updown-tabs .nav-link {
  background-color: var(--background-color-4) !important;
}
.prediction-trade-updown-input {
  background-color: var(--background-color-4) !important;
}
.prediction-updown-button {
  background-color: var(--background-color-1) !important;
} */
.simpleearn-top-value-section {
  background-color: var(--background-color-1) !important;
}
.simpleearn-top-filter-section .input-group-text-1 {
  color: var(--color-1) !important;
}
.simpleearn-top-filter-section .input-group-text-2 {
  color: var(--color-1) !important;
}
.optionContainer {
  background-color: var(--background-color-1) !important;
  color: var(--color-1) !important;
}
.simpleearn-table-tabs-duration .nav-link {
  padding: 4px 8px;
  background-color: var(--background-color-1) !important;
  border: 1px solid transparent;
}
.subscription-amount-group-2{
  color: var(--color-1) !important;
}
.simpleearn-auto-Subscribe{
  padding: 16px 10px;
  background-color: var(--background-color-1) !important;
}
.accountearn-top-value-section{
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--background-color-1) !important;
}
.accountearn-all-assest{
  background-color: var(--background-color-1) !important;
}
.accountearn-staking-table-tabs-section .nav-pills .nav-link.active,
.accountearn-staking-table-tabs-section .nav-pills .show>.nav-link {
  background-color: var(--border-color-2) !important;
}
.accountearn-all-assest{
  background-color: var(--border-color-2) !important;
}
.accountearn-redeem-1{
  padding: 10px;
  font-size: 13px;
  background-color: var(--border-color-2) !important;
  border: none;
}
.accountearn-redeem-2{
  padding: 10px;
  font-size: 13px;
  opacity: 0.5;
  background-color: var(--border-color-2) !important;
  border: none;
}
.simpleearn-history-filter-2{
  background-color: var(--border-color-2) !important;
  font-size: 14px;
}

.eoption-left-tabs-1 .nav-link {
  background-color: var(--background-color-3);
}
.eoption-left-tabs-3 th {
  background-color: var(--background-color-1) !important;
}
.eiption-table-row-dropdown{
  background-color: var(--background-color-3) !important;
}
.eiption-table-dropdown-menu{
  border: 1px solid var(--border-color-2) !important;
}
.eoption-left-tabs-5 thead th{
  background-color: var(--background-color-3) !important;
  border-bottom: 1px solid var(--border-color-2) !important;
}
.eoption-left-tabs-4 thead th{
  background-color: var(--background-color-3) !important;
  border-bottom: 1px solid var(--border-color-2) !important;
}
.eoption-left-tabs-4 tbody td{
  border-bottom: 1px solid var(--border-color-2) !important;
  padding: 0.6rem 0.3rem !important;
}
.eoption-left-tabs-3 thead th{
  border-bottom: 1px solid var(--border-color-2) !important;
}
.eoption-left-tabs-3 tbody td{
  border-bottom: 1px solid var(--border-color-2) !important;
}
.eoption-left-side-section {
  border-right: 5px solid var(--border-color-2);
}
.eoption-left-tabs-2 {
  border-bottom: 1px solid var(--border-color-2);
}
.eoption-left-tabs-1 {
  border-bottom: 1px solid var(--border-color-2);
}
.eoption-left-tabs-5 thead th {
  border-bottom: 1px solid var(--border-color-2) !important;
}
.api-page-top-section .bg-grey{
  background-color: var(--background-color-3) !important;
}