@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.light-new {
  --mainTextColor: black;
  --navBackground: #292735;
  --navFontColor: #fff;
  --navLightBg: #333537;
  --goldColor: rgb(255, 177, 26);
  --border: rgba(243, 245, 247, 1);
}

.dark-new {
  --mainTextColor: white;
  --navBackground: #292735;
  --navFontColor: #fff;
  --navLightBg: #333537;
  --goldColor: rgb(255, 177, 26);
  --border: #000;
}

.light-new .under-line-effect {
  display: none;
  /* opacity: 0;
  border: 5px solid black; */
}

/* Price Card Start */
.price-card-container {
  background-color: var(--background-color-1);
  border: 1px solid var(--primary-color);
  border-radius: 10% 0px 0px 5px;
  margin-top: 20px;
  flex: 20%;
}
.token-price-btn {
  background-color: var(--primary-color) !important;
  border-radius: 5%;
  color: var(--primary-text-color) !important;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}
.price-card-container {
  margin-right: 40px;
  box-shadow: #ffd748 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, #f7a600 0px 0px 15px inset;
}
.token-nav-name {
  padding-left: 10px;
}
.token-input,
.token-input:focus {
  background-color: transparent;
}
.token-popup-header {
  border-bottom: #403b22;
}
.token-popup-label {
  font-size: 13px;
}
.token-price-container {
  min-height: 385px;
  overflow-y: auto;
}
.price-card-main-container {
  /* margin-top: 80px; */
  margin-bottom: 50px;
  display: flex;
  justify-content: center;  
  flex-wrap: wrap;
  color: var(--color-1) !important;
}
/* Price Card End */
.switch {
  border-radius: 8px;
  height: 32px;
  width: 72px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 2px;
  box-sizing: border-box;
  --tw-bg-opacity: 1;
  background-color: rgba(37, 40, 44, var(--tw-bg-opacity));
  border: 2px solid #000;
  border-radius: 5px;
}

.dark-active,
.light-active {
  background-color: #000;
}

.switch-ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  list-style: none;
  padding-left: 0px;
}

.item img {
  width: 25px;
  height: 25px;
}

.dark-img {
  width: 20px !important;
  height: 20px !important;
}

.item {
  border-radius: 4px;
  width: 32px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexible-saving input,
.flexible-saving input:focus {
  background-color: var(--input-box-bg);
  color: var(--input-box-text-color);
}

.flexible-saving input:focus {
  border: 1px solid var(--goldColor);
}

.spot-container {
  height: 810px !important;
}

.popup-grid-btn {
  display: flex;
  justify-content: center;
}

.close-btn {
  cursor: pointer;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.input-group-text {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.web-hide {
  display: none;
}

.user-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-top-banner-section {
  margin-top: 34px;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.market-trade-button-small-position {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--color-1);
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
}

.switch-modes-section .form-check-label {
  color: #fff !important;
}

.inner-container {
  background-color: #000;
}

.market-trade-button-small-position:hover {
  color: var(--goldColor);
}

.read-me-link {
  color: #ffd748;
  font-weight: 800;
}

.usdt-perpetual .history-container {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.hamburger-icon div {
  width: 12px;
  height: 2px;
  background-color: var(--color-1);
  margin: 4px 0;
}
.hamburger-icon {
  padding-right: 2px;
}

.table-container {
  padding: 0px 10px;
}

.paired-trade-dropdown-tabs-1 .form-control {
  height: 12px !important;
}

.pairList-col {
  padding-left: 0px;
  padding-right: 0px;
}

.icon {
  width: 100%;
  height: 50%;
  padding-right: 5px;
}

.theme-mode-dropdown .navbar-top-modes-dropdown {
  left: 319px !important;
}

.sign-btn {
  color: #000 !important;
}

.offcanvas-body .nav-item-li:hover {
  background-color: var(--navLightBg) !important;
  color: var(--goldColor) !important;
}

.offcanvas-body .nav-item-li:hover .nav-link {
  color: var(--goldColor) !important;
}

.nav-item:hover:has(.nav-register-button) a {
  color: #000 !important;
}

.under-line-effect {
  display: none;
}

.under-line-effect img {
  /* height: 114px; */
}

.offcanvas-body .nav-item-li:hover span {
  color: var(--goldColor) !important;
}

.dropdown-item-hover:hover .top-nav-dropdown-item-text-2 {
  color: var(--goldColor);
}

.spot-main {
  margin-top: 33px !important;
}

.sign-btn,
.grid-btn {
  box-shadow: #ffd748 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, #f7a600 0px 0px 15px inset !important;
}

.modal-content {
  width: 100% !important;
  padding: 20px 10px;
  box-shadow: #ffd748 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, #f7a600 0px 0px 15px inset;
}

.read-me {
  color: #81858c;
  font-size: 12px;
}

.trading-inner-right-section {
  width: 100% !important;
  height: var(--mapHeight) !important;
}

.flexible-saving {
  font-size: 12px;
}

.spot-main-bg {
  background-color: var(--background-color-1) !important;
}

#tradingviewwidget {
  width: 100% !important;
  height: 100% !important;
}

.earn-col-main {
  padding-left: 10px;
  padding-right: 10px;
}

.placeorder-container .input-group {
  /* background-color: var(--tv-color-toolbar-button-text,#787b86);
   */
  background-color: var(--input-box-bg);
  border-radius: 5px;
  color: #fff;
}

.spot-place-order .nav-pills .nav-link.active,
.spot-place-orde .nav-pills .show > .nav-link {
  color: var(--primary-color) !important;
}

.spot-place-order .nav-pills {
  font-size: 14px;
}

.order-trade-ul .nav-pills .nav-link.active,
.order-trade-ul .nav-pills .show > .nav-link {
  border-bottom: 1px solid var(--primary-color);
  border-radius: 0px;
  color: var(--color-1) !important;
}

.nav-pills-buy-sell .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #f0b90b !important;
  border-bottom: 2px solid #f0b90b;
}

.place-order-buy-sell-percentage-tabs .nav-pills .nav-link.active,
.place-order-buy-sell-percentage-tabs .nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: var(--btnClr);
  border: 1px solid var(--btnClr);
}

.coin-count::placeholder {
  color: #81858c !important;
}

/* Convert Start */
.convet-container {
  padding: 20px;
  background-color: var(--background-color-1);
  border-radius: 5px;
}

.convert-main-container {
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.convert-drop-down-container {
  display: flex;
  justify-content: end;
}

.usdt-perpetual {
  margin-bottom: 8px;
  margin-top: 22px;
}

#funding-dropdown {
  background-color: var(--background-color-1);
  border: 1px solid #f7a600;
  font-size: 12px;
}

.mobile-justify-end > .color-green-price {
  font-size: 12px !important;
  font-weight: 800 !important;
}

.color-green-price {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1px;
  line-height: 24px;
}

::ng-deep #funding-dropdown .dropdown-item {
  font-size: 12px;
}

.from-label,
.from-tranfer-container {
  font-size: 16px;
  color: #81858c;
}

.convert-small-label {
  color: #81858c;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-top: -2px;
}

.arrow-container {
}

.arrow-main-container {
  display: flex;
  justify-content: center;
}

.coin-to-coin {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  line-height: 18px;
  padding: 0 18px;
}

.arrow-icon {
  background-color: #f7a600;
  border-radius: 50px;
  padding: 5px 18px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.arrow-icon-span {
  color: #000;
  font-size: 16px;
  font-weight: 800;
}

.swap-data,
.receive-price {
  font-size: 16px !important;
}

.coin-count {
  background-color: var(--input-box-bg);
  width: 100%;
  border: none;
  height: 35px;
  border-radius: 5px;
  padding: 0px 20px;
  font-size: 14px !important;
}

.coin-count:focus-visible {
  border: 1px solid #f7a600;
}

.search-text {
  background-color: var(--input-box-bg);
  width: 100%;
  border: 1px solid #81858c;
  height: 50px;
  border-radius: 5px;
  padding: 0px 20px;
}

/* .down-arrow, .up-arrow{
  padding-left: 15%;
} */

.down-arrow img,
.up-arrow img {
  height: 10px;
  width: 12px;
  cursor: pointer;
}

.main-container {
  padding-bottom: 10px;
  padding-top: 10px;
  /* border-bottom: 5px solid var(--border); */
  border-top: 5px solid var(--border);
}

.dropdown-container {
  /* position: absolute; */
  top: 0px;
  left: 100px;
  display: flex;
  justify-content: center;
  /* border: 1px solid #81858c; */
  border-radius: 5px;
}

/* .select-pair-close {
  position: absolute;
  left: 95%;
  top: 45px;
  font-size: 10px;
  cursor: pointer;
}

.paired-search-box {
  position: relative;

} */

.paired-title {
  /* background-color: var(--navBackground); */
  padding: 10px;
}

.modal.show .modal-dialog {
  margin-top: 150px;
}

.convert-list {
  cursor: pointer;
  padding: 10px;
}

.convert-list:hover {
  background-color: var(--input-box-bg);
}

.search-dropdown-container {
  min-height: 300px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-body {
  padding: 0px 12px;
}

.convert-icon {
  height: 78%;
  width: 100%;
}

.popup-search {
  margin-left: 0px;
  margin-right: 0px;
}

.popup-search .search-text {
  height: 35px;
}

.popup-search .search-text::placeholder {
  color: #81858c;
}

.convert-icon-popup {
  height: 100%;
  width: 100%;
}

.coin-popup-close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.convert-popup-image {
  padding-left: 10px;
  cursor: pointer;
  /* position: relative; */
}

.convert-all {
  color: #d6850d;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 4px;
}

.deposit-transfer {
  font-size: 12px;
  color: #d6850d;
}

.coin-price-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

.from-tranfer-container {
  display: flex;
  justify-content: end;
}

.from-container {
  padding: 20px;
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid #f7a600;
  border-radius: 15px;
}

.convert-header {
  font-size: 18px;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
}

/* Convert End */
.form-control {
  height: 40px;
}

.navbar-nav {
  align-items: center !important;
  justify-content: center !important;
}

.markets-search-group-section .form-control {
  color: var(--color-1) !important;
  background-color: var(--background-color-1) !important;
}

.btc-icon {
  width: 24px;
  height: 24px;
}

.spot-list-container,
.TVChartContainer_TVChartContainer__R0L-L,
.order-trade-container,
.history-container,
.placeorder-container {
  background-color: var(--background-color-1);
  border-radius: 5px;
  /* border: 1px solid var(--primary-color); */
  /* box-shadow: 1px 0px 9px var(--primary-color); */
}

body {
  /* background: #ffffff;
  color: #000; */
  height: 100vh;
  outline: none !important;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.enter-amount-section {
  height: 38px;
}

/* Earn Start */
.grid-header span {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
  color: #81858c;
  text-align: center;
}

.data-container {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.convert-btn {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  background: #ffb11a;
  color: #000;
}

.convert-btn:hover,
.arrow-icon:hover {
  box-shadow: #ffd748 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, #f7a600 0px 0px 15px inset;
}

.grid-btn {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 3px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  background: #ffb11a;
  color: #000;
  display: flex;
  align-items: center;
}

.grid-apr-text,
.grid-duration-text {
  color: #20b26c;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.grid-duration-text {
  color: var(--color-1);
}

.grid-img-container span {
  color: var(--color-1);
}

.grid-img-container img {
  width: 25px;
  margin-right: 10px;
}

.grid-chip-success,
.receive-price {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
  padding: 2px 8px;
}

.receive-price {
  color: var(--color-1);
}

.grid-chip-success {
  color: #20b26c;
  background: rgba(32, 178, 108, 0.12);
}

.grid-body-container {
  /* min-height: 200px; */
  /* max-height: 200px;
  overflow-y: auto; */
}

.earn-banner-container {
  background-image: url("../assets/images/bg_coin.jpg");
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.earn-banner-inside {
  width: 90%;
  padding-top: 110px;
  color: var(--color-1);
}

.earn-header {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--whiteClr);
  margin-bottom: 8px;
}

.earn-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--whiteClr);
}

.grid-chip-stay {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
  padding: 2px 8px;
  color: #e18e12;
  background: rgba(247, 166, 0, 0.12);
}

.section-break {
  margin-bottom: 12px;
}

.earn-container {
  width: 90%;
  margin-top: 50px;
}

.new-button-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.new-button {
  background: linear-gradient(97.12deg, #ffd748 0.93%, #f7a600);
  border-radius: 4px;
  padding: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #121214;
  text-align: center;
}

.earn-col-container:hover {
  box-shadow: #ffd748 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, #f7a600 0px 0px 15px inset;
}

.earn-col-container {
  cursor: pointer;
}

.earn-col-container,
.all-product-container {
  background-color: var(--background-color-1);
  padding: 15px;
  border-radius: 5px;
}

.coin-img-1 {
  position: absolute;
  top: 15px;
  left: 20px;
}

.input-group-image {
  width: 35px;
  height: 25px;
  padding-left: 5px;
}

.img-container {
  position: relative;
}

.img-container img {
  width: 36px;
  height: 26px;
}

.earn-text-secoundary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #81858c;
}

.earn-text-primary {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-1);
}

.earn-text-primary-spl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #20b26c;
}

.earn-col-big-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-1);
}

.earn-col-small-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #81858c;
}

.convert-price-container {
  display: flex;
  justify-content: end;
}

.header-dropdown .dropdown-option {
  background-color: var(--input-box-bg) !important;
}

.convert-price-container .convert-price-label {
  background-color: none !important;
  color: var(--color-1) !important;
}

/* Earn End */
.card {
  background-color: transparent;
}

.currency-pipe-border {
  color: var(--tv-color-toolbar-button-text, #787b86);
  padding-right: 12px;
  padding-left: 12px;
}

.currency-primary {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
}

.pair-primary {
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 14px;
}

.pair-secoundary {
  font-size: 12px;
}

.price-secoundary {
  --tw-text-opacity: 1;
  color: rgba(113, 117, 122, var(--tw-text-opacity));
  margin-bottom: 2px;
  line-height: 18px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
}

.price-primary {
  font-size: 12px;
  --tw-text-opacity: 1;
  font-weight: 500;
  white-space: nowrap;
  line-height: 18px;
  color: var(--color-1);
}

.currency-secoundary {
  font-size: 12px;
  line-height: 18px;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
  --tw-text-opacity: 1;
  color: rgba(129, 133, 140, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border: none;
  border-bottom: 1px dashed rgba(173, 177, 184, var(--tw-border-opacity));
  /* margin: 0px .5rem ; */
}

.currency-name {
  padding: 0px;
}

.currency-container {
  padding-right: 20px;
  border-right: 1px solid var(--tv-color-toolbar-button-text, #787b86);
}

.color-green-price {
  color: #24ae64;
}

.token-container {
  border-right: 1px solid var(--tv-color-toolbar-button-text, #787b86);
}

.dark-new {
  background-color: #07080a;
  color: #ffffff;
}

.pills-buy-tab {
  justify-content: left !important;
}

#pills-buy-tab {
  /* padding-top: 2px;
  padding-bottom: 2px; */
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 0px;
}

.form-control {
  background-color: none !important;
}

.order-trade-ul {
  border: none !important;
}

.order-trade > .nav-link {
  background: none !important;
  border: none;
  color: #727581 !important;
}

.order-trade > .nav-link:active {
  /* border-bottom: 1px solid var(--primary-color) !important; */
}

.light-new {
  background-color: #ffffff;
  color: #252547;
}

.blue-whale {
  background-color: #0c3040;
  color: rgb(255, 255, 255);
}

.blue-dark {
  background-color: #13212e;
  color: #ffffff;
}

.brown {
  background-color: #141414;
  color: #ffffff;
}

.dark-black {
  background-color: #000000;
  color: #ffffff;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.description-width-styling {
  width: 100%;
}

.border-none {
  border: none !important;
}

.PhoneInputCountry {
  border: none !important;
  padding: 0 !important;
  height: auto !important;
}

.PhoneInputInput {
  border: none !important;
}

.register-section-top .PhoneInput {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  margin-bottom: 15px;
}

.chatbox-section-height {
  height: 110px;
}

.chatbox-buy-modal-1-styling {
  background-color: var(--chatPopupBg);
  position: absolute;
  top: 180px;
  margin: 3em;
  width: 320px;
  z-index: 999;
}

.chatbox-sell-modal-1-styling {
  background-color: var(--chatsellPopupBg);
  position: absolute;
  top: 180px;
  margin: 3em;
  width: 320px;
  z-index: 999;
}

#chatboxmodal .btn-close {
  box-sizing: content-box;
  width: 2em;
  height: 2em;
  padding: 0.25em;
  background: transparent url("./images/red-cross.png") center/1em auto
    no-repeat;
  border: 0;
  border-radius: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--btnClr);
  border-radius: 5px;
}

.text-css {
  color: var(--btnClr);
}

/* Typography css begin */
h5 {
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

/* Color css begin */

.navbar-toggler-icon {
  color: rgb(0, 0, 0) !important;
}

.nav-link:focus,
.nav-link:hover {
  color: rgb(255, 255, 255);
}

input {
  box-shadow: none !important;
}

img {
  width: 100%;
  height: 100%;
}

#navbar-img {
  /* width: 150px !important; */
  width: 85px !important;
  /* height: 40px !important; */
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  /* padding-bottom: 60px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.offcanvas {
  background-color: var(--background-color-1);
}

.btn-close {
  /* background-image: url("../assets/images/cross.white.svg"); */
  opacity: 1;
  box-shadow: none !important;
}

.offcanvas .btn-close {
  background-image: url("../assets/images/cross.white.svg");
  opacity: 1;
  box-shadow: none !important;
}

.bg-light-green {
  background-color: rgb(209, 255, 209);
  border-radius: 0.5em;
  font-size: 11px;
}

.bg-light-green-2 {
  background-color: rgb(209, 255, 209);
  border-radius: 0.5em;
}

.bg-light-red {
  background-color: #e12424 !important;
  border-radius: 0.5em;
  font-size: 11px;
  color: white !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-13 {
  font-size: 13px;
}

.f-15 {
  font-size: 15px;
}

.f-14 {
  font-size: 14px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-25 {
  font-size: 25px;
}

.f-35 {
  font-size: 35px;
}

.f-40 {
  font-size: 40px;
}

.fc-r {
  color: rgb(234, 0, 112);
}

.fc-g {
  color: rgb(14, 203, 129);
}

.fc-w {
  color: rgb(255, 255, 255);
}

.border-radius-1em {
  border-radius: 1em !important;
}

.border-radius-small {
  border-radius: 0.7em !important;
}

.chart-page .chart-container-border {
  background-color: red !important;
  border: none;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}

.navbar-shrink {
  background-color: red;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.nav-tabs-buy-sell-section .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

/* =======prediction bear and bull CSS start===============*/
.dark-new .form-switch .form-check-input {
  background-image: url("../assets/images/select-circle-dark.png");
}

.prediction-manual-updown-tabs .nav-pills .nav-link.manual-active-up.active,
.prediction-manual-updown-tabs .nav-pills .show > .nav-link.manual-active-up {
  color: #72f238 !important;
  background-color: #0c491d !important;
}

.prediction-manual-updown-tabs .nav-pills .nav-link.manual-active-down.active,
.prediction-manual-updown-tabs .nav-pills .show > .nav-link.manual-active-down {
  color: #ff4949 !important;
  background-color: #360c0e !important;
}

.dark-new
  .prediction-manual-updown-tabs
  .nav-pills
  .nav-link.manual-active-up.active,
.dark-new
  .prediction-manual-updown-tabs
  .nav-pills
  .show
  > .nav-link.manual-active-up {
  color: #72f238 !important;
  background-color: #2a3c2f !important;
}

.dark-new
  .prediction-manual-updown-tabs
  .nav-pills
  .nav-link.manual-active-down.active,
.dark-new
  .prediction-manual-updown-tabs
  .nav-pills
  .show
  > .nav-link.manual-active-down {
  color: #ff4949 !important;
  background-color: #3c2631 !important;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 100%;
    -webkit-appearance: none;
    background: #00dbaf46;
    border-radius: 50px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 20px;
    -webkit-appearance: none;
    color: #13bba4;
    width: 100%;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    cursor: ew-resize;
    background: linear-gradient(120deg, #ffffff 0%, #00dbae 100%);
    border-radius: 50px;
  }
}

/* =======prediction bear and bull CSS end===============*/
/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  /*====================== Simple-earn CSS Start===============================*/
  .simpleearn-history-filter-status {
    background-color: #7cffe5;
    padding: 10px;
    color: #000 !important;
  }

  .simpleearn-history-filter-1 {
    background-color: #00dbae;
    font-size: 14px;
  }

  .accountearn-staking-table-tabs-section .form-check {
    margin-bottom: 0px;
  }

  .simpleearn-redeem {
    color: #00dbae !important;
  }

  .accountearn-staking-table-tabs-section .nav-link {
    font-size: 14px;
  }

  .accountearn-text-4 {
    font-size: 20px;
  }

  .accountearn-staking-tabs-section .nav-link {
    font-size: 14px;
  }

  .accountearn-staking-tabs-section .nav-pills .nav-link.active,
  .accountearn-staking-tabs-section .nav-pills .show > .nav-link {
    color: #000000 !important;
    background-color: #7cffe5;
  }

  .accountearn-top-value-section-3 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .accountearn-all-assest {
    padding: 6px 10px;
    border-radius: 4px;
    position: relative;
    top: -4px;
    font-size: 14px;
  }

  .accountearn-text-3 {
    font-size: 24px;
  }

  .accountearn-top-value-section-2 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .accountearn-text-2 {
    font-size: 18px;
  }

  .accountearn-text-1 {
    font-size: 2vw;
  }

  .accountearn-top-banner-section {
    padding-top: 65px;
  }

  .simpleearn-staking-subscribe-table #row-1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .simpleearn-subscribe-button {
    background-color: #00dbae;
    border: none;
    padding: 8px;
    color: #000;
    border-radius: 4px;
    font-size: 14px;
  }

  .simpleearn-text-17 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .simpleearn-text-16 {
    font-size: 10px;
  }

  .simpleearn-text-15 {
    font-size: 13px;
  }

  .simpleearn-enough-crypto {
    background-color: #78f5dc;
    padding: 4px;
    border-radius: 4px;
    margin-top: 20px;
    box-shadow: rgba(24, 26, 32, 0.1) 0px 0px 1px,
      rgba(71, 77, 87, 0.08) 0px 7px 14px, rgba(24, 26, 32, 0.08) 0px 3px 6px;
  }

  .simpleearn-text-14 {
    font-size: 12px;
    text-decoration: underline;
  }

  .simpleearn-text-13 {
    font-size: 20px;
  }

  .simpleearn-text-12 {
    color: #00dbae;
    text-decoration: underline;
  }

  .subscription-amount-group-1 {
    border-left: none;
    border-right: none;
  }

  .subscription-amount-group-2 {
    border-left: none;
  }

  .simpleearn-subscription-amount .form-control {
    background-color: transparent !important;
    border-right: 0px;
    border-right: 0px;
    font-size: 12px;
  }

  .simpleearn-subscription-amount .form-control:focus {
    border-color: #ced4da;
  }

  .simpleearn-subscription-amount .input-group-text {
    background-color: transparent !important;
  }

  .simpleearn-max-button {
    background-color: transparent;
    border: none;
    color: #00dbae !important;
    padding-right: 14px !important;
    border-right: 1px solid #ced4da;
    padding: 0px;
  }

  .simpleearn-text-11 {
    font-size: 12px;
  }

  .simpleearn-subscribe-img {
    width: 20px;
    height: 20px;
  }

  .btn-simpleearn-filter-table {
    background-color: #00dbae;
    color: #000 !important;
    padding: 6px 10px;
    border-radius: 4px;
    border: 0px;
  }

  .simpleearn-table-tabs-duration .nav-link {
    position: relative;
  }

  .simpleearn-table-tabs-duration .nav-link.active::before {
    transition: 0.15s;
    content: "✔";
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 6px;
    width: 8px;
    height: 8px;
    background-color: #00dbae;
    border-bottom-left-radius: 10px;
    color: #000000;
  }

  .simpleearn-table-tabs-duration.nav-pills .nav-link.active,
  .simpleearn-table-tabs-duration.nav-pills .show > .nav-link {
    color: #fff;
    background-color: transparent !important;
    border: 1px solid #00dbae;
  }

  .simpleearn-text-10 {
    font-size: 14px;
  }

  .simpleearn-text-9 {
    font-size: 16px;
  }

  .simpleearn-table-coin-img {
    width: 25px;
    height: 25px;
  }

  .simpleearn-text-7 {
    font-size: 26px;
  }

  .simpleearn-text-8 {
    font-size: 14px;
  }

  .simpleearn-top-filter-section .searchBox {
    font-size: 14px;
  }

  .simpleearn-top-filter-section .chip {
    align-items: center;
    background: #00dbae !important;
    margin-bottom: 0px !important;
  }

  .simpleearn-top-filter-section .multiSelectContainer li:hover {
    background: #00dbae !important;
    color: #fff;
    cursor: pointer;
  }

  .simpleearn-top-filter-section .highlightOption {
    background: #00dbae !important;
    color: #fff;
  }

  .simpleearn-top-filter-section .Multiselect-simple-earn {
    background-color: transparent;
    border-color: #ced4da;
    font-size: 14px;
  }

  .simpleearn-top-filter-section .search-wrapper {
    padding: 7px 10px !important;
  }

  .simpleearn-top-filter-section .input-group-text-1 {
    background-color: transparent !important;
    border-right: 0px;
  }

  .simpleearn-top-filter-section .input-group-text-2 {
    background-color: transparent !important;
    border-left: 0px;
    font-size: 12px;
  }

  .simpleearn-top-filter-section .form-control {
    background-color: transparent !important;
    color: #fff !important;
    border-left: 0px;
    border-right: 0px;
    font-size: 14px;
    padding: 9px 0px;
  }

  .simpleearn-top-filter-section .form-control:focus {
    border-color: #ced4da;
  }

  .simpleearn-text-6 {
    font-size: 10px;
  }

  .simpleearn-text-5 {
    font-size: 14px;
    font-weight: 700;
  }

  .simpleearn-text-4 {
    font-size: 12px;
  }

  .simpleearn-text-3 {
    font-size: 20px;
    cursor: pointer;
  }

  .simpleearn-top-value-section .card {
    border: 1px solid #00dbaf60;
    padding: 16px 18px 28px 18px;
  }

  .simpleearn-text-1 {
    font-size: 2.3vw;
    margin-bottom: 0px;
  }

  .simpleearn-text-2 {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .simpleearn-top-value-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .simpleearn-top-banner-section {
    padding-top: 65px;
  }

  /*====================== Simple-earn CSS End===============================*/
  #trading-competition-width {
    width: 100%;
  }

  #trading-competition-hover-show-hide {
    display: none;
  }

  #classy-navbar-mobile {
    /* background-image: url("../assets/images/bg_coin.jpg"); */
  }

  #classy-navbar-mobile .tournament-dropdown-menu .dropdown-menu {
    top: 100%;
    left: 0px !important;
    right: auto;
    margin-top: 0rem;
  }

  /* ==================prediction bear and bull CSS start============================*/
  .prediction-trade-right-section .f-control {
    font-size: 12px !important;
  }

  .prediction-trade-right-section .f-dropdown {
    border: 1px solid transparent !important;
  }

  .trade-right-section-table-anchor {
    font-size: 14px;
    color: #00dbae;
  }

  .trade-right-section-table-anchor:hover {
    color: #00dbae;
    text-decoration: underline;
  }

  .bets-table-trade-1 {
    width: 10%;
  }

  .bets-table-trade-2 {
    width: 6%;
  }

  .bets-table-trade-3 {
    width: 14%;
  }

  .bets-table-trade-4 {
    width: 10%;
  }

  .bets-table-trade-5 {
    width: 10%;
  }

  .bets-table-trade-6 {
    width: 10%;
  }

  .bets-table-trade-7 {
    width: 10%;
  }

  .bets-table-trade-8 {
    width: 14%;
  }

  .bets-table-trade-9 {
    width: 10%;
  }

  .bets-table-trade-10 {
    width: 6%;
  }

  .prediction-bets-table-section .bets-trade-tablebd-1 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .prediction-bets-table-section .bets-trade-tablebd-2 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .prediction-trade-right-section-table-section {
    padding: 10px 30px;
  }

  .prediction-bet-button-up {
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    color: rgb(20, 23, 34) !important;
    background: rgb(134, 244, 84);
    box-shadow: rgba(118, 255, 25, 0.4) 0px 0px 10px,
      rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
      rgb(59, 198, 14) 0px 0px 15px inset;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    letter-spacing: 0.5px;
  }

  .prediction-bet-button-down {
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    color: rgb(20, 23, 34) !important;
    background: rgb(255, 106, 106);
    box-shadow: rgba(255, 73, 73, 0.42) 0px 0px 10px,
      rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
      rgb(255, 55, 55) 0px 0px 15px inset;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    letter-spacing: 0.5px;
  }

  /* Disabled placebet buttons */
  .prediction-bet-button-up:disabled,
  .prediction-bet-button-down:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 8px;
  }

  .prediction-trade-text-6 {
    font-size: 12px;
  }

  .prediction-trade-updown-input {
    border-radius: 4px;
  }

  .prediction-updown-button {
    border: none !important;
    margin: 6px;
    padding: 4px 12px;
    font-size: 11px;
    background-color: #00dbae !important;
    color: #000 !important;
    border-radius: 4px !important;
  }

  .prediction-trade-updown-input .input-group-text {
    background-color: transparent;
    border: none !important;
  }

  .prediction-trade-updown-input .form-control {
    border-right: 0px;
    border-left: 0px;
    border: none !important;
    background-color: transparent !important;
    font-size: 13px;
  }

  .prediction-trade-updown-input .form-control:active {
    border-right: 0px;
    border-left: 0px;
    border: none !important;
  }

  .prediction-trade-updown-input .form-control:focus {
    border-color: #ced4da !important;
  }

  .prediction-manual-coin {
    width: 16px;
    height: auto;
  }

  .prediction-manual-updown-tabs .nav-link {
    font-size: 14px;
    padding: 10px 1px;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 0px;
  }

  .prediction-trade-text-5 {
    font-size: 18px;
    position: relative;
    top: -1px;
  }

  .prediction-trade-text-4 {
    font-size: 12px;
    font-weight: 1000;
  }

  .prediction-manual-auto-tabs .nav-pills .nav-link.active,
  .prediction-manual-auto-tabs .nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #00dbae;
  }

  .prediction-manual-auto-tabs .nav-link {
    font-size: 12px;
    padding: 6px 12px;
    text-transform: uppercase;
  }

  .prediction-trade-settings-section label {
    font-size: 13px;
  }

  .prediction-trade-settings-section .dropdown-menu {
    left: auto !important;
    right: 0px !important;
    width: 18rem !important;
    padding: 6px 16px;
  }

  .prediction-trade-tick-button {
    border: none;
    font-size: 18px;
    padding: 4px 12px;
    border-radius: 4px;
  }

  .prediction-trade-right-1 .f-control {
    border: none;
    font-size: 14px;
    padding: 6px 12px !important;
    border-radius: 4px;
    border: none !important;
  }

  .prediction-trade-text-3 {
    opacity: 0.8;
  }

  .prediction-trade-text-2 {
    font-size: 12px;
  }

  .prediction-trade-text-1 {
    font-size: 1.4rem;
    margin-right: 2px;
  }

  .prediction-trade-image-1 {
    width: 40px;
    height: auto;
    margin-right: 10px;
  }

  .prediction-trade-right-1 {
    padding: 10px 16px;
    width: 75%;
    min-height: 75vh;
  }

  .prediction-trade-right-2 {
    padding: 10px 16px;
    width: 25%;
  }

  .prediction-trade-top-banner {
    padding-top: 65px;
  }

  .prediction-trade-top-banner ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

  .prediction-trade-top-banner ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .prediction-trade-left-section {
    width: 16%;
    /* height: calc(100vh); */
    padding: 10px 12px;
    /* overflow-y: scroll; */
  }

  .prediction-trade-right-section {
    /* width: 84%; */

    /* height: calc(100vh - 65px);
    overflow-y: scroll; */
  }

  .prediction-bets-table-section .bets-tablebd-1 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .prediction-bets-table-section .bets-tablebd-2 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .bets-table-1 {
    width: 30%;
  }

  .bets-table-2 {
    width: 20%;
  }

  .bets-table-3 {
    width: 8%;
  }

  .prediction-bets-table-section .table > :not(caption) > * > * {
    border: none !important;
  }

  .prediction-bets-table-section tr {
    background: rgba(203, 215, 255, 0.075) !important;
    border-bottom: 3px solid #000 !important;
  }

  .prediction-bets-table-section table {
    background-color: #30323600 !important;
    font-size: 13px;
  }

  .prediction-bets-table-section table thead {
    opacity: 0.7;
    text-transform: uppercase;
    font-size: 11px;
  }

  .prediction-bets-table-section th {
    border: none !important;
  }

  .prediction-bets-table-section .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .prediction-bets-tabs-section .nav-pills .nav-link.active,
  .prediction-bets-tabs-section .nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #3cffd8;
  }

  .prediction-bets-tabs-section .nav-link {
    display: block;
    padding: 0.2rem 0.5rem;
    color: #0d6efd;
    text-decoration: none;
    font-size: 12px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .prediction-page-top-banner ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

  .prediction-page-top-banner ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .prediction-page-left-section {
    width: 13%;
    height: calc(100vh - 65px);
    padding: 10px 12px;
    overflow-y: scroll;
  }

  .prediction-page-middle-section {
    width: 70%;
    padding: 10px 22px;
    height: calc(100vh - 65px);
    overflow-y: scroll;
  }

  .prediction-page-right-section {
    width: 17%;
    height: calc(100vh - 65px);
    overflow-y: hidden;
  }

  .prediction-swiper-card-button {
    font-size: 10px;
    padding: 6px 8px;
    color: #ffffffc2;
    margin-top: 4px;
  }

  .prediction-text-9 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .prediction-text-10 {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .swiper-card-logo img {
    width: 32px;
    height: auto;
    margin: auto;
  }

  .prediction-text-8 {
    padding: 8px 0px 0px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .prediction-page-middle-section .swiper-card {
    background: rgba(203, 215, 255, 0.075);
    padding: 12px 4px;
    border-radius: 4px;
  }

  .prediction-caro-icon {
    color: #e9e9e986;
  }

  .prediction-caro-2 img {
    width: 50%;
  }

  .prediction-text-7 {
    font-size: 12px;
  }

  .prediction-text-6 {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 4px;
  }

  .prediction-caro-icon {
    background: rgba(203, 215, 255, 0.075);
    padding: 8px;
    font-size: 30px;
    border-radius: 8px;
    cursor: pointer;
  }

  .prediction-caro-1 {
    width: 10%;
    text-align: center;
  }

  .prediction-caro-2 {
    width: 80%;
  }

  .prediction-caro-3 {
    width: 10%;
    text-align: center;
  }

  .prediction-total-rewards {
    background: rgba(203, 215, 255, 0.075);
    padding: 8px 14px;
    width: fit-content;
    border-radius: 4px;
  }

  .prediction-image-medal {
    width: 16px;
    height: auto;
  }

  .prediction-text-5 {
    color: #e9e9e9b6;
  }

  .prediction-text-4 {
    font-size: 0.86rem;
    letter-spacing: 1px;
  }

  .prediction-text-3 {
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .prediction-page-middle-slider {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../assets/images/prediction-bg.jpg");
    border-radius: 8px;
  }

  .prediction-page-middle-slider-inner {
    background: radial-gradient(
      circle at 79.5% 88%,
      rgb(17, 51, 103) 8%,
      transparent 90%
    );
    padding: 20px 40px;
    border-radius: 8px;
  }

  .prediction-chat-section {
    background-color: #00000059;
    margin-bottom: 6px;
    padding: 2px 8px;
    border-radius: 4px;
  }

  .prediction-text-1 {
    font-size: 13px;
    margin-right: 6px;
    font-weight: 600;
  }

  .prediction-text-2 {
    font-size: 10px;
  }

  .prediction-image-1 {
    width: 22px;
    height: auto;
    margin-right: 6px;
  }

  .prediction-page-right-2 {
    padding-top: 10px;
    height: calc(100vh - 159px);
    overflow-y: scroll;
  }

  .prediction-page-right-1 {
    height: 44px;
  }

  .prediction-page-right-3 {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
  }

  .prediction-page-right-section .input-group-text {
    background-color: transparent !important;
    font-size: 14px !important;
    border-left: 0px !important;
    color: #fff;
  }

  .prediction-page-features-section-2 {
    padding: 8px 8px;
    border-radius: 4px;
    padding-bottom: 14px !important;
  }

  .prediction-page-right-section .form-control {
    background-color: transparent !important;
    font-size: 14px !important;
    border-right: 0px !important;
  }

  .prediction-page-right-section ::placeholder {
    font-size: 12px !important;
  }

  .prediction-page-right-section .nav-pills .nav-link.active,
  .prediction-page-right-section .nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #3cffd8;
  }

  .prediction-page-right-section .nav-link {
    padding: 4px 8px;
    font-size: 13px;
  }

  #features-dropdown-close {
    display: none;
  }

  .prediction-page-text-5 {
    font-size: 20px;
    position: relative;
    top: -2px;
    margin-left: 6px;
  }

  .prediction-features-list {
    padding: 4px 8px;
    border-radius: 4px;
  }

  .prediction-page-text-4 {
    font-size: 13px;
    vertical-align: middle;
    opacity: 0.9;
  }

  .prediction-page-image-1 {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .prediction-page-top-banner li {
    list-style: none;
  }

  .prediction-page-features-section {
    padding: 8px 8px;
    border-radius: 4px;
  }

  .prediction-page-text-3 {
    font-size: 20px;
  }

  .prediction-page-text-2 {
    font-size: 13px;
  }

  .prediction-page-text-1 {
    font-size: 16px;
  }

  .prediction-padding-right {
    padding: 10px 12px;
  }

  .prediction-page-top-banner {
    padding-top: 65px;
  }

  /* =======prediction bear and bull CSS end===============*/

  .popup {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    background-color: transparent;

    z-index: 999;
  }

  #scrollmodalopen {
    width: 600px;
    margin: auto;
    position: relative;
    top: 20vh;
  }

  .scrollmodalopenimg {
    width: 600px;
    height: 300px;
  }

  .crossclose-img {
    width: 20px;
    height: 20px;
  }

  .scrollmodal-bg {
    background-color: #03125e;
    width: 600px;
    height: 300px;
    color: #ffffff !important;
  }

  .copytrading-portfolio-nav-tabs-css .nav-tabs .nav-link.active {
    font-size: 18px !important;
    padding: 1em;
    /* background-color: transparent; */
  }

  .copytrading-portfolio-nav-tabs-css .nav-tabs .nav-link {
    font-size: 18px !important;
    padding: 1em;
    /* background-color: transparent; */
  }

  .p2p-trade-table {
    border: none !important;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .markets-search-group-section .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.575rem 0.75rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 5rem;
    border-right: 0px;
  }

  .markets-search-group-section .form-control {
    padding: 0.575rem 0.75rem;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5rem;
    border-left: 0px;
  }

  .card-width-style {
    width: 50%;
  }

  .paired-trade-dropdown-table tbody {
    display: block;
    height: 350px;
    overflow: auto;
  }

  .paired-trade-dropdown-table th {
    background-color: var(--goldColor) !important;
  }

  .paired-trade-dropdown-table thead,
  .paired-trade-dropdown-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .paired-trade-dropdown-table .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .paired-trade-dropdown-table .table > :not(caption) > * > * {
    border: 0px !important;
    padding: 0.4rem 0.5rem;
  }

  .paired-trade-dropdown-table .table tbody tr {
    border-bottom: 1px solid #ced4da;
  }

  .paired-trade-dropdown-table .table tbody tr:hover {
    background-color: #ced4da;
  }

  .paired-trade-dropdown-table .table tbody tr td:first-child {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .paired-trade-dropdown-table .table tbody tr td {
    padding-left: 20px;
    font-size: 13px;
    font-weight: 400;
  }

  .paired-trade-dropdown-table th {
    padding-left: 20px !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    letter-spacing: 00.045em;
  }

  .paired-trade-dropdown-tabs-1 .form-control {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 .input-group-text {
    padding: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 {
    border: 1px solid #dbdde6;
    padding: 5px 5px;
    /* margin-top: 0px; */
  }

  .paired-trade-dropdown-tabs {
    margin-left: 2px;
  }

  .paired-trade-dropdown-tabs .nav-pills .nav-link.active,
  .paired-trade-dropdown-tabs .nav-pills .show > .nav-link {
    color: var(--btnClr) !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-trade-dropdown-tabs .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: #727581 !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    border-radius: 0px;
    margin-right: 40px;
  }

  .spot-page-pair-select-dropdown .dropdown-item {
    min-width: 36rem !important;
  }

  .spot-page-pair-select-dropdown .dropdown-item:hover {
    background-color: transparent !important;
  }

  .spot-page-pair-select-dropdown .dropdown-toggle {
    padding-bottom: 30px;
  }

  .spot-table-search-button {
    color: #000;
    border: 0px;
    width: 100%;
    padding: 0.2rem 1rem;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid rgb(238, 240, 242);
  }

  .paired-refresh-button {
    color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 0px;
    letter-spacing: 0.2px;
    border: 0px;
    padding: 0px;
    background: transparent;
  }

  .trading-page-order-tabs-section {
    height: 120px !important;
  }

  .p2p-trade-buy-sell-collapse-container {
    border: none;
    /* background-color: rgb(48, 48, 48); */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .p2p-trade-buy-sell-collapse-container .form-select:focus {
    border: 1px solid rgb(114, 114, 114);
    outline: 0;
    box-shadow: none !important;
  }

  .p2p-trade-buy-sell-collapse-container .form-control {
    background-color: transparent !important;
  }

  .p2p-trade-buy-sell-collapse-container .form-select {
    background-color: transparent;
  }

  .buynow-btn {
    background-color: var(--btnClr);
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .sellnow-btn {
    background-color: rgba(241, 55, 55);
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .add-payment-method-heading-styling {
    font-size: 24px;
    padding: 20px 0px;
    font-weight: 550;
  }

  .add-payment-method-text-1 {
    font-size: 14px;
    font-family: sans-serif;
  }

  .add-payment-method-text-2 {
    font-size: 11px;
    font-family: sans-serif;
    color: rgb(177, 177, 177);
  }

  .add-payment-method-top-banner .form-control-payment-method {
    outline: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .add-payment-method-cancel-button {
    background-color: #eaecef;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: sans-serif;
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .add-payment-method-confirm-button {
    background-color: var(--btnClr);
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: sans-serif;
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .payment-qrcode-optional-image {
    width: 70px;
  }

  .p2p-trade-hero-section-two-nav-bg .dropdown-toggle::after {
    display: none;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  .noresponse-image-styling {
    width: 90px;
  }

  .profile-images-letter-styling {
    background-color: #000000 !important;
    border-radius: 1em !important;
    color: #ffffff;
    padding: 3px;
    font-size: 1.3em !important;
  }

  /* .scrollspy-payments-row-bg-styling {
    background: rgb(250, 250, 250);
  } */

  .font-size-style {
    font-size: 17px;
    font-weight: 600;
  }

  .p2puser-center-button {
    background-color: rgb(14, 203, 129);
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2puser-center-scrollspy-styling-active {
    border-bottom: 3px solid #00dbae !important;
  }

  .p2puser-center-scrollsp-border {
    border-bottom: 1px solid #dee2e6;
  }

  .p2puser-center-scrollspy-button {
    border: none;
    box-shadow: none !important;
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    letter-spacing: 0.08em;
    padding: 20px 20px;
    background-color: transparent;
    border-bottom: 3px solid transparent;
  }

  .p2puser-center-scrollspy-styling .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: inherit;
    font-size: 16px !important;
    font-weight: 500;
  }

  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-item.show .nav-link,
  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-link.active {
    color: #495057 !important;
    background-color: #fff;
    border-bottom: 3px solid var(--btnClr) !important;
  }

  .p2puser-center-scrollspy-styling .dropdown-toggle::after {
    /* display: none; */
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .p2p-user-scrollspy-button {
    color: rgb(30, 35, 41);
    background-color: rgb(234, 236, 239);
    font-size: 14px;
  }

  .p2p-user-center-scrollspy-tops-heading {
    min-width: 0px;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .border-line-styling {
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    width: 4px;
    height: 14px;
    border-radius: 4px;
    background-color: rgb(95, 37, 159) !important;
  }

  .scrollspy-tops-heading-paragraph {
    min-width: 0px;
    max-width: 100%;
    font-size: 14px !important;
  }

  #scrollspy-section-1-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    background: rgb(255, 255, 255);
    padding: 24px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-2-para {
    margin: 0px 0px 32px;
    padding: 24px;
    border-radius: 4px;
  }

  #scrollspy-section-3-para {
    margin: 0px 0px 32px;
    padding: 24px;
    border-radius: 4px;
  }

  .p2p-usercenter-progressbar {
    position: relative !important;
    right: 0px;
    z-index: 1;
    height: 9px;
    width: 100px;
    background-color: var(--btnClr);
  }

  .p2p-usercenter-progressbar-top {
    height: 9px;
    width: 100px;
  }

  .datepicker-css-styling {
    border: none !important;
    width: 100px !important;
    outline: none !important;
    font-size: 0.9rem;
    background-color: transparent;
  }

  .datepicker-css-styling input {
    border: none !important;
    box-shadow: none !important;
  }

  .order-p2p-trade-dropdowns.select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    background-color: transparent;
  }

  .order-p2p-reset-content-title {
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    color: rgb(14, 203, 129);
  }

  .order-p2p-trade-download-icon {
    font-size: 35px;
    background-color: rgb(214, 214, 214) !important;
  }

  .faq .card {
    border: none;
    background: none;
  }

  .faq .card .card-header {
    padding: 0px;
    border: none;
    background: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .faq .card .card-header:hover {
    background: #ffd70026;
    /* padding-left: 10px; */
  }

  .faq .card .card-header .faq-title {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
    color: inherit;
    text-decoration: none !important;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .faq .card .card-header .faq-title .badge {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 20px;
    float: left;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    background: var(--primary-color);
    color: var(--primary-text-color);
    font-size: 16px;
    margin-right: 10px;
  }

  .faq .card .card-body {
    padding: 30px;
    padding-left: 35px;
    font-weight: 400;
    font-size: 16px;
    color: inherit;
    letter-spacing: 1px;
  }

  .hero-top-section {
    background-image: url("../assets/images/p2p/p2p-trade-background.png");
    background-size: 100% 100%;
  }

  .p2p-bottom-buy-sell-tabs-content-image {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 80px;
    max-height: 80px;
    margin-bottom: 24px;
    margin-top: 20px;
  }

  .p2p-bottom-buy-sell-tabs-content-title-paragrah {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: inherit;
    font-size: 14px;
  }

  .p2p-bottom-buy-sell-tabs-content-title {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  .p2p-second-nav-content-title {
    font-size: 13px;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: #000000;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.1s;
    font-weight: 500;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--primary-text-color) !important;
    background-color: var(--primary-color) !important;
  }

  .p2p-bottom-buy-sell-tabs {
    margin-top: 40px !important;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    width: 100%;
  }

  .p2p-bottom-section-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .p2p-bottom-section-heading {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 40px;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -ms-flexbox;
    display: flex;
    /* margin-left: auto;
    margin-right: auto; */
    max-width: 1200px;
    /* color: #1E2329; */
    font-weight: 550;
  }

  .enter-amount-section .btn {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    color: rgb(14, 203, 129);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section .btn:hover {
    color: #e0ad06;
  }

  .enter-amount-text-section {
    border-right: 1px solid #eaecef;
  }

  .enter-amount-text-1 {
    font-size: 15px;
    color: #aeb4bc;
  }

  .enter-amount-section .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section {
    border: 1px solid #f0bb0b50;
    padding: 0.175rem 0rem;
    border-radius: 6px;
  }

  /* input::-webkit-outer-spin-button,

input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
} */
  /* ::placeholder {
  color: #cccdcf !important;
  font-size: 14px;
}
.btn:focus {
  box-shadow: none !important;
}
input:focus {
  box-shadow: none !important;
} */
  .enter-amount-heading {
    font-size: 11px;
    color: #76808f;
  }

  .enter-amount-text-2 {
    font-size: 11px;
    color: #76808f;
    margin-left: -10px;
  }

  .f-dropdown {
    --max-scroll: 3;
    position: relative;
    z-index: 10;
  }

  .f-dropdown select {
    display: none;
  }

  .f-dropdown > span {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: #bbb;
    border: 1px solid #f0bb0b50 !important;
    background: #f5f5f5;
    transition: color 0.2s ease, border-color 0.2s ease;
  }

  .f-dropdown > span > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }

  .f-dropdown > span img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown > span:before,
  .f-dropdown > span:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 2px;
    border-radius: 1px;
    top: 50%;
    right: 12px;
    background: #000;
    transition: all 0.3s ease;
  }

  .f-dropdown > span:before {
    margin-right: 4px;
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown > span:after {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-height: calc(var(--max-scroll) * 46px);
    top: 40px;
    left: 0;
    z-index: 1;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: 0 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: translate(0, 5px);
  }

  .f-dropdown ul li {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  .f-dropdown ul li a {
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    outline: none;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }

  .f-dropdown ul li a img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown ul li a:hover {
    color: #000 !important;
  }

  .f-dropdown ul li.active a {
    color: #000;
    background: gray;
  }

  .f-dropdown ul li.active a:before,
  .f-dropdown ul li.active a:after {
    --scale: 0.6;
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    position: absolute;
    right: 12px;
    top: 50%;
    opacity: 0;
    background: #fff;
    transition: all 0.2s ease;
  }

  .f-dropdown ul li.active a:before {
    transform: rotate(45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:after {
    transform: rotate(-45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:hover:before,
  .f-dropdown ul li.active a:hover:after {
    --scale: 0.9;
    opacity: 1;
  }

  .f-dropdown ul li:first-child a {
    border-radius: 3px 3px 0 0;
  }

  .f-dropdown ul li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  .f-dropdown.disabled {
    opacity: 0.7;
  }

  .f-dropdown.disabled > span {
    cursor: not-allowed;
  }

  .f-dropdown.filled > span {
    color: #000;
  }

  .f-dropdown.open {
    z-index: 15;
  }

  .f-dropdown.open > span {
    border-color: #aaa;
  }

  .f-dropdown.open > span:before,
  .f-dropdown.open > span:after {
    background: #000;
  }

  .f-dropdown.open > span:before {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown.open > span:after {
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown.open ul {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 12px);
    transition: opacity 0.3s ease, visibility 0.3s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  }

  .f-group {
    max-width: 250px;
    margin: 0 auto;
    text-align: left;
  }

  .f-group select {
    width: 100%;
  }

  .f-control {
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    border: #ccc 1px solid;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }

  .nav-tabs-buy-sell-section {
    border: 1px solid #e6e8ea;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell-section .nav-tabs .nav-link:hover {
    border-color: #e9ecef00 #e9ecef00 #dee2e600;
    isolation: isolate;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .green-tab-active {
    background-color: rgb(14, 203, 129) !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .red-tab-active {
    background-color: rgb(203, 13, 13) !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs-buy-sell .nav-tabs {
    border: 0px solid #0582ff !important;
  }

  .nav-tabs-buy-sell .nav-link {
    display: block;
    padding: 0.3rem 0rem !important;
    color: inherit;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .nav-pills-buy-sell .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0rem;
  }

  .nav-pills-buy-sell .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: inherit !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .hero-top-section-text-1 {
    font-size: 32px;
  }

  .advantages-p2p-section-text-1 {
    font-size: 24px;
    font-weight: 500;
  }

  .hero-top-section-text-2 {
    font-size: 14px;
    width: 50%;
    text-align: center;
    margin: auto;
    font-weight: 200;
    color: rgb(214, 214, 214) !important;
  }

  .advantages-p2p-section-text-2 {
    font-size: 14px;
    font-weight: 200;
    color: rgb(37, 37, 37) !important;
  }

  .advantage-p2p-images {
    width: 300px;
    height: 150px;
    border-radius: 0.5em;
    margin-bottom: 1em;
  }

  .modal-2-overflow-scroll {
    overflow: hidden auto !important;
    height: 432px;
  }

  .deposit-modal-list-sizing {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0%;
    -webkit-box-pack: justify;

    -webkit-box-align: center;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
  }

  .deposit-modal-row-sizing {
    margin: 13px 9px 13px 0px;
    min-width: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-coin-heading {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: rgb(30, 35, 41);
  }

  .deposit-modal-coin-muted-text {
    margin: 0px;
    min-width: 0px;
    color: rgb(71, 77, 87);

    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
  }

  .deposit-modal-coin-images {
    width: 30px !important;
    height: 30px !important;
  }

  #myUL2 {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #myUL2 li a {
    margin-bottom: 1px;
    background-color: transparent;
    padding: 4px;
    padding-left: 10px;
    text-decoration: none;
    color: black;
    display: block;
  }

  #myUL2 li a:hover:not(.header) {
    background-color: #eee;
  }

  #myInput2 {
    margin: 0 auto;
    width: 100%;
    height: 45px;
    padding: 2px 20px;
    font-size: 1rem;
    border-radius: 15px;
    outline: none;
    margin-bottom: 15px;
  }

  .deposit-crypto-modal .modal-content {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: relative;
    box-shadow: rgb(20 21 26 / 16%) 0px 8px 16px,
      rgb(71 77 87 / 16%) 0px 16px 32px, rgb(20 21 26 / 10%) 0px 0px 1px;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    animation: 0.3s ease-in-out 0s 1 normal none running animation-1wqz9z0;
  }

  .deposit-crypto-modal .modal-header {
    border-bottom: none;
  }

  .no-record-image {
    width: 120px;
  }

  .no-records-found td {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
  }

  .table-view-all-deposit-button-1,
  .table-view-all-deposit-button-2 {
    background-color: transparent;
    color: var(--btnClr);
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .dest-addres-link {
    margin-left: 4px;
    font-size: 18px;
  }

  .dest-addres-ellipsis {
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .deposit-fourth-section .table > :not(caption) > * > * {
    padding: 0.85rem 0.85rem;
  }

  .deposit-fourth-section .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .deposit-fourth-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: rgb(112, 122, 138);
    background-color: rgb(245, 245, 245);
    border: 0px;
  }

  .deposit-fourth-section th:first-child {
    border-top-left-radius: 4px;
  }

  .deposit-fourth-section th:last-child {
    border-top-right-radius: 4px;
  }

  .deposit-fourth-section tr {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    padding: 30px;
  }

  .deposit-text-11 {
    font-size: 24px;
  }

  .deposit-text-9 {
    font-weight: 800;
  }

  .deposit-text-8 {
    text-decoration: underline;
  }

  .deposit-text-7 {
    color: var(--btnClr);
  }

  .deposit-icon-1 {
    margin-right: 10px;
    font-size: 24px;
    color: rgb(112, 122, 138);
  }

  .deposit-text-6 {
    font-size: 14px;
  }

  .deposit-select-coin-section {
    width: 100%;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(234, 236, 239);
    cursor: pointer;
  }

  .deposit-select-coin-section:hover {
    border-color: var(--btnClr);
  }

  .deposit-text-5 {
    font-size: 15px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: sans-serif;
  }

  .deposit-text-4 {
    font-size: 15px;
    font-weight: 500;
    margin-right: 6px;
  }

  .deposit-logo-image {
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }

  .deposit-third-right-section {
    width: 100%;
  }

  .deposit-third-left-section {
    width: 250px !important;
  }

  .deposit-third-section {
    padding-top: 10px;
    padding-bottom: 10px;
    /* max-height: 760px; */
  }

  .deposit-text-3 {
    font-weight: 300;
    font-size: 15px;
    font-family: sans-serif;
  }

  .deposit-text-33 {
    font-weight: 300;
    font-size: 14px;
    font-family: sans-serif;
  }

  .deposit-crupto-explain-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .deposit-text-2 {
    font-weight: 300;
    font-size: 14px;
    font-family: sans-serif;
  }

  .crypto-workflow-section-close-button {
    font-size: 30px;
    cursor: pointer;
  }

  .deposit-second-section {
    padding: 15px 20px 30px 20px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .base-timeline {
    list-style-type: none;
    counter-reset: number;
    position: relative;
    display: block;
    z-index: 2;
    width: 100%;
  }

  .base-timeline::before {
    content: "";
    width: 100%;
    border-top: 2px solid var(--btnClr);
    display: inline-block;
    position: absolute;
    top: 20px;
    z-index: -1;
    width: 75%;
  }

  .base-timeline__item {
    position: relative;
    display: inline-block;
    width: calc(100% / 4 - 5px);
  }

  .base-timeline__item::before {
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: number;
    content: counter(number) "";
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--btnClr);
    color: white;
    font-weight: bold;
    transition: all 0.6s ease-in-out;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .base-timeline__item--data::before {
    width: 60px;
    height: 60px;
  }

  .deposit-fiat-button {
    background-color: rgb(242, 243, 245);
    padding: 6px 16px;
    border: none;
    font-size: 14px;
  }

  .deposit-fiat-button:hover {
    background-color: transparent;
  }

  .deposit-text-1 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .deposit-back-button-icon {
    font-size: 34px;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
    position: relative;
    top: -3px;
  }

  .deposit-back-button-icon:hover {
    background-color: rgb(242, 243, 245);
  }

  .deposit-hero-section {
    background-color: rgb(250, 250, 250);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .deposit-page-top-banner {
    margin-top: 65px;
  }

  /* / ===============================================Harish-css-start================================================ / */
  .terms-text-3 {
    font-size: 14px;
    font-family: sans-serif;
    margin-bottom: 4px;
  }

  .terms-text-2 {
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .terms-text-1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    font-family: sans-serif;
  }

  .accordian-dot-icons {
    font-size: 20px;
    margin-right: 8px;
  }

  .faq-second-section .accordion-body {
    font-size: 15px;
    font-family: sans-serif;
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }

  .faq-second-section .accordion-button {
    font-family: sans-serif;
    box-shadow: none !important;
    width: 100%;
    padding: 1rem 1.25rem;
    color: inherit !important;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    background-color: transparent;
  }

  .faq-second-section .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.068);
  }

  .market-trade-button {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--color-1);
    font-size: 12px;
    padding: 4px 20px;
    border-radius: 4px;
  }

  .market-trade-button:hover {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--goldColor);
    font-size: 12px;
    /* padding: 4px 30px; */
  }

  .card-col {
    height: 300px;
  }
  .markets-second-section tbody {
    border: none !important;
  }

  .markets-second-section table {
    font-family: sans-serif;
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 12px;
  }

  .markets-second-section .table > :not(caption) > * > * {
    padding: 1rem 0.5rem;
  }

  .markets-second-section table tr {
    font-family: sans-serif;
    border-bottom: 1px solid #dee2e654 !important;
  }

  .markets-second-section table tbody tr:last-child {
    border-bottom: 0 solid #dee2e6 !important;
  }

  .markets-second-section table tbody tr:hover {
    background-color: #f5f8fb;
  }

  .markets-second-section table th {
    /* background-color: var(--btnClr) !important; */
    background-color: var(--primary-color);
    color: var(--primary-text-color) !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
  }

  .markets-second-section table th:first-child {
    border-top-left-radius: 12px;
    padding-left: 14px !important;
  }

  .markets-second-section table th:last-child {
    border-top-right-radius: 12px;
  }

  .markets-second-section table td {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
    vertical-align: middle;
  }

  .transaction-history-select-section .transaction-form-control {
    display: block;
    width: 100%;
    height: 35px;
    padding-left: 8px;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(112, 122, 138, 0.336);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .custom-dropdown-trans-section .select-dropdown-btn {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.8rem;
    color: inherit;
    background-color: transparent;
    width: 120px !important;
    height: 35px;
    border: 1px solid rgba(112, 122, 138, 0.336) !important;
    border-radius: 4px;
  }

  .custom-dropdown-trans-section .select-dropdown-btn:hover {
    font-size: 0.8rem;
    color: inherit;
    background-color: transparent;
    border: 1px solid rgb(112, 122, 138) !important;
  }

  .transaction-text-1 {
    font-family: sans-serif;
    font-size: 12px;
  }

  .transaction-history-tabs-section .nav-pills .nav-link.active,
  .transaction-history-tabs-section .nav-pills .show > .nav-link {
    color: #000;
    background-color: var(--btnClr);
  }

  .transaction-history-tabs-section .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: rgb(112, 122, 138);
    text-decoration: none;
    font-size: 14px;
    font-family: sans-serif;
  }

  .trading-page-order-table-details-section tbody {
    border: none !important;
  }

  .trading-page-order-table-details-section tbody td {
    font-weight: 900;
    align-items: center;
    font-size: 10px;
    letter-spacing: 1px;
  }

  .trading-page-order-table-details-section .table > :not(caption) > * > * {
    border: 0px;
    padding: 0.4rem 0.3rem !important;
  }

  .trading-page-order-table-details-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: rgb(112, 122, 138);
    background-color: transparent;
    border: 0px;
    padding: 4px 4px;
    letter-spacing: 0.28px;
    vertical-align: middle;
    border-bottom: 1px solid #dbdde6;
  }

  .trading-page-order-table-details-section .select-dropdown-btn {
    font-size: 13px;
  }

  .trading-page-order-table-details-section .dropdown-item {
    font-size: 13px !important;
  }

  .withdraw-crypto-network-modal .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }

  .withdraw-crypto-network-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-network-modal .modal-header {
    border: none;
  }

  .withdraw-modal-coin-muted-text-1 {
    color: rgb(71, 77, 87);
  }

  .withdraw-modal-coin-muted-text {
    font-weight: 300;
    font-size: 14px;
    color: rgb(112, 122, 138);
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .withdraw-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 20px 10px !important;
    padding: 5px;
    border-radius: 4px;
  }

  .withdraw-modal-network-sizing:hover {
    background-color: #eee;
  }

  .withdraw-nav-tabs ::placeholder {
    font-size: 15px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: sans-serif;
  }

  .withdraw-nav-tabs .form-control {
    width: 100%;
    padding: 0.375rem 1.2rem !important;
    font-size: 0.9rem;
    height: 48px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 1px solid #dee2e6;
  }

  .withdraw-crypto-modal-text-4 {
    font-size: 14px;
    font-weight: 200;
    margin-left: 15px;
  }

  .withdraw-crypto-modal-text-3 {
    font-size: 14px;
    font-weight: 200;
    font-family: sans-serif;
  }

  .withdraw-crypto-list-items {
    padding: 6px 10px;
    margin-bottom: 4px;
    border-radius: 4px;
  }

  .withdraw-crypto-list-items:hover {
    background-color: #eee;
  }

  .withdraw-crypto-modal-text-2 {
    font-size: 14px;
    font-weight: 200;
    position: relative;
    top: -4px;
    font-family: sans-serif;
  }

  .withdraw-crypto-modal-text-1 {
    font-size: 18px;
    position: relative;
    top: 4px;
  }

  .withdraw-crypto-modal-images {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .withdraw-crypto-modal-list {
    list-style: none;
    padding: 0px;
  }

  .withdraw-crypto-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-modal .modal-header {
    border: none;
  }

  .select-network-text-1 {
    font-size: 14px;
  }

  .modal-2-overflow-scroll {
    overflow: hidden auto !important;
    height: 432px;
  }

  .deposit-modal-list-sizing {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
  }

  .deposit-modal-row-sizing {
    margin: 3px 9px 3px 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-coin-heading {
    box-sizing: border-box;
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
  }

  .deposit-modal-coin-muted-text {
    color: rgb(71, 77, 87);
    font-size: 12px;
    margin-bottom: 0px;
  }

  .deposit-modal-coin-images {
    width: 25px !important;
    height: 25px !important;
  }

  #myUL2 {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #myUL2 li a {
    margin-bottom: 1px;
    background-color: transparent;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    text-decoration: none;
    color: black;
    display: block;
  }

  #myUL2 li a:hover:not(.header) {
    background-color: #eee;
  }

  .deposit-crypto-modal .modal-header {
    border-bottom: none;
  }

  .wallet-info-icon-1 {
    font-size: 12px;
    margin-left: 4px;
  }

  .qr-code-tooltip-image {
    width: 100%;
  }

  .tooltip-text-3 {
    font-size: 13px;
    font-weight: 700;
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .tooltip-text-2 {
    font-size: 12px;
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .tooltip-text-1 {
    font-size: 11px;
    font-family: sans-serif;
  }

  .tooltip-text-datatable {
    font-size: 14px;
    font-family: sans-serif;
  }

  .tooltip-text-Theme {
    padding-bottom: 10px !important;
    width: 250px;
    color: #fff !important;
    background-color: rgb(0, 0, 0) !important;
  }

  .tooltip-text-Theme::after {
    background-color: rgb(0, 0, 0) !important;
  }

  .no-record-image {
    width: 120px;
  }

  .no-records-found td {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
  }

  .table-view-all-deposit-button-1,
  .table-view-all-deposit-button-2 {
    background-color: transparent;
    color: var(--btnClr);
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .dest-addres-link {
    margin-left: 4px;
    font-size: 18px;
  }

  .dest-addres-ellipsis {
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .deposit-fourth-section .table > :not(caption) > * > * {
    padding: 0.85rem 0.85rem;
  }

  .deposit-fourth-section .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .deposit-fourth-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: rgb(112, 122, 138);
    background-color: rgb(245, 245, 245);
    border: 0px;
  }

  .deposit-fourth-section th:first-child {
    border-top-left-radius: 8px;
  }

  .deposit-fourth-section th:last-child {
    border-top-right-radius: 8px;
  }

  .deposit-fourth-section tr {
    font-weight: 500;
    align-items: center !important;
    vertical-align: middle;
    font-size: 12px;
    padding: 30px;
  }

  .deposit-text-11 {
    font-size: 24px;
  }

  .deposit-text-9 {
    font-weight: 800;
  }

  .deposit-text-8 {
    text-decoration: underline;
  }

  .deposit-text-7 {
    color: var(--btnClr);
  }

  .deposit-icon-1 {
    margin-right: 10px;
    font-size: 24px;
    color: rgb(112, 122, 138);
  }

  .deposit-text-6 {
    font-size: 14px;
  }

  .deposit-text-66 {
    font-size: 13px;
  }

  .deposit-select-coin-section {
    width: 100%;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(234, 236, 239);
    cursor: pointer;
  }

  .deposit-select-coin-section:hover {
    border-color: var(--btnClr);
  }

  .deposit-text-5 {
    font-size: 15px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: sans-serif;
  }

  .deposit-text-4 {
    font-size: 15px;
    font-weight: 500;
    margin-right: 6px;
  }

  .deposit-logo-image {
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }

  .deposit-third-right-section {
    width: 100%;
  }

  .deposit-third-left-section {
    width: 250px !important;
  }

  .deposit-third-section {
    padding-top: 10px;
    padding-bottom: 10px;
    /* max-height: 760px; */
  }

  .deposit-text-3 {
    font-weight: 300;
    font-size: 15px;
    font-family: sans-serif;
  }

  .deposit-text-33 {
    font-weight: 300;
    font-size: 14px;
    font-family: sans-serif;
  }

  .deposit-crupto-explain-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .crypto-workflow-section-close-button {
    font-size: 30px;
    cursor: pointer;
  }

  .base-timeline {
    list-style-type: none;
    counter-reset: number;
    position: relative;
    display: block;
    z-index: 2;
    width: 100%;
  }

  .base-timeline::before {
    content: "";
    width: 100%;
    border-top: 2px solid var(--btnClr);
    display: inline-block;
    position: absolute;
    top: 20px;
    z-index: -1;
    width: 75%;
  }

  .base-timeline__item {
    position: relative;
    display: inline-block;
    width: calc(100% / 4 - 5px);
  }

  .base-timeline__item::before {
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: number;
    content: counter(number) "";
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--btnClr);
    color: white;
    font-weight: bold;
    transition: all 0.6s ease-in-out;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .base-timeline__item--data::before {
    width: 60px;
    height: 60px;
  }

  .deposit-fiat-button {
    background-color: rgb(242, 243, 245);
    padding: 6px 16px;
    border: none;
    font-size: 14px;
  }

  .deposit-fiat-button:hover {
    background-color: transparent;
  }

  .deposit-text-1 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .deposit-back-button-icon {
    font-size: 34px;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
    position: relative;
    top: -3px;
  }

  .deposit-back-button-icon:hover {
    background-color: rgb(242, 243, 245);
  }

  .deposit-hero-section {
    background-color: rgb(250, 250, 250);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .deposit-page-top-banner {
    margin-top: 65px;
  }

  .trading-page-order-tabs-section {
    height: 120px;
    margin: auto;
    text-align: center;
  }

  .trade-bottom-login-text-1 {
    color: gray;
    font-size: 14px;
  }

  .trading-page-order-details-section {
    border-top: 5px solid #f5f8fb;
    margin-top: 0px !important;
    /* width: 1200px;
    margin: auto; */
  }

  .activities-button-1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 13px;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    border: none !important;
    color: #fff;
    width: 40px;
  }

  .activities-text-4 {
    position: relative;
    top: -4px;
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
    margin: 0px;
  }

  .activities-text-3 {
    color: rgb(112, 122, 138);
  }

  .activities-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .activities-text-1 {
    font-size: 14px;
  }

  .trading-table-right-activities-section {
    margin-top: 5px;
    border-top: 1px solid rgb(238, 240, 242);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .trading-table-right-bottom-section {
    height: 290px !important;
    overflow-y: scroll;
  }

  .paired-trade-table-section {
    /* margin-left: 10px !important;
    margin-right: 10px !important; */
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-bottom: 0px;
    /* border-bottom: 1px solid rgb(238, 240, 242); */
    padding-top: 10px;
    /* margin-bottom: 10px; */
  }

  .paired-trade-table-section .nav-pills .nav-link.active,
  .paired-trade-table-section .nav-pills .show > .nav-link {
    color: var(--color-1) !important;
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid var(--primary-color);
    padding-left: 0px;
  }

  .paired-trade-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 15px !important;
    color: #727581 !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    /* margin-right: 30px; */
    border-radius: 0px;
    padding: 0px 20px;
    padding-bottom: 8px !important;
    padding-left: 0px;
  }

  .trading-table-right-inner-section {
    height: 310px !important;
    overflow-y: scroll;
  }

  .paired-text-3 {
    box-sizing: border-box;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    margin-left: 4px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(254, 246, 216);
  }

  .pared-fav-button {
    color: rgb(112, 122, 138);
    margin-bottom: 4px;
    margin-right: 1px;
  }

  .trading-table-right-section .flex-row {
    height: 22px;
  }

  .trading-table-right-section {
    /* width: 95%; */
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin: auto;
  }

  .paired-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .paired-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .paired-table-1 {
    width: 110px;
  }

  .paired-table-2 {
    width: 80px;
    text-align: end;
  }

  .paired-table-3 {
    width: 90px;
    text-align: end;
  }

  .paired-price-table-section {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .paired-price-table-section .nav-pills .nav-link.active,
  .paired-price-table-section .nav-pills .show > .nav-link {
    color: var(--btnClr) !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-price-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: rgb(112, 122, 138) !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 6px;
  }

  /* .trading-page-right-section {
    width: 290px !important;
  } */

  .trade-search-section {
    width: 90%;
    margin: auto;
    border-radius: 2px;
    line-height: 1.5;
    background-color: rgb(242, 243, 245);
  }

  .trade-search-btn {
    border: none;
    color: rgb(112, 122, 138);
    background: transparent;
  }

  .trade-search-section .form-control {
    padding: 0.215rem 0.15rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .trade-search-section .form-control:focus {
    background-color: transparent;
  }

  .progress-bar {
    position: absolute;
    right: 0px;
    z-index: 1;
    height: 23px;
  }

  .ask-bar {
    background-color: rgba(255, 0, 0, 0.07);
  }

  .progress-bar-bg-color {
    position: relative;
  }

  .progress-bar-bg-color .flex-row {
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center !important;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: sans-serif;
    line-height: 1.5;
    font-size: 75%;
    font-weight: 600;
    letter-spacing: 0.26px;
  }

  .progress-table-1 {
    width: 33%;
  }

  .progress-table-2 {
    width: 33%;
    text-align: right;
  }

  .progress-table-3 {
    width: 33%;
    text-align: end;
  }

  .orderbook-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: #727581;
    letter-spacing: 0.015em;
    padding-bottom: 0rem !important;
  }

  .trailing-delta-btn {
    background-color: transparent;
    font-size: 0.8rem;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
  }

  .custom-dropdown,
  .dropdown-menu {
    box-shadow: none !important;
  }

  .custom-dropdown .dropdown-item {
    color: inherit;
    font-size: 14px;
  }

  .select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(112, 122, 138);
    background-color: transparent;
  }

  .select-dropdown-btn:hover {
    border: none !important;
  }

  .limit-market-section-bottom .select-amount-button {
    background-color: transparent;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
    font-size: 0.9rem;
  }

  .limit-market-section-bottom .dropdown-item {
    padding: 0.5rem 1rem;
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-item:hover {
    background-color: rgb(234, 236, 239);
  }

  .limit-market-section-bottom .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-menu {
    border-radius: 0rem !important;
    padding: 0px !important;
    left: -20px !important;
  }

  .trade-text-15 {
    color: var(--btnClr);
  }

  .trade-buy-sell-button-1 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(230, 232, 234);
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }

  .trade-buy-sell-button-11 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(14, 203, 129);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .trade-buy-sell-button-111 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .total-value-price-section {
    /* position: relative;
    top: -30px; */
    font-size: 11px;
    color: #000;
  }

  .percentage-line-section {
    height: 5px;
    width: 100%;
    border-radius: 4px;
    align-self: center;
  }

  .percentage-display-section {
    background-color: var(--btnClr) !important;
    height: 32px;
    width: 32px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px #aaa;
    outline: none !important;
  }

  .trade-text-14 {
    font-size: 0.8rem;
  }

  .trade-text-13 {
    font-size: 0.8rem;
    color: rgb(112, 122, 138);
  }

  /* ::placeholder {
    color: #212529 !important;
  } */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .limit-market-section-bottom .form-control {
    display: block;
    width: 100%;
    padding: 0rem 0.75rem;
    font-size: 0.8rem;
    float: right;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    text-align: right;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .limit-button-width {
    width: 40px !important;
    text-align: center;

    margin-left: 6px;
  }

  .limit-price-form {
    align-items: center;
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 0.365rem 0.465rem;
    background-color: rgba(230, 232, 234, 0.6);
  }

  .limit-price-form:hover {
    border: 1px solid var(--btnClr);
  }

  .limit-price-form:focus {
    border: 1px solid var(--btnClr) !important;
  }

  .trade-text-12 {
    font-size: 12px;
    color: rgb(0, 0, 0);
  }

  .trade-text-11 {
    font-size: 12px;
    color: rgb(112, 122, 138);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active {
    border-top: 2px solid var(--btnClr) !important;
    border-bottom: 0px solid transparent;
    color: rgb(30, 35, 41);
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active:hover {
    border-top: 2px solid var(--btnClr);
    border-bottom: 0px solid transparent;
    border-left: 1px solid rgb(238, 240, 242);
    border-right: 1px solid rgb(238, 240, 242);
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    color: rgba(112, 122, 138, 0.815);
    letter-spacing: 0.4px;
    width: 130px;
    /* height: 50px; */
    padding: 10px 20px;
    font-weight: 500;
  }

  .limit-market-section-bottom .nav-link:hover {
    border-color: transparent;
    outline: none;
  }

  .trading-page-top-section-3 {
    max-height: 420 !important;
    overflow-x: auto;
  }

  .trading-table-left-recent-trade-height {
    height: var(--ordebookHeight) !important;
    overflow-y: auto;
  }

  .place-order-section-top {
    border: none !important;
  }

  .trading-table-total-height {
    max-height: var(--ordebookHeight) !important;
    height: var(--ordebookHeight) !important;
    border-bottom: 5px solid #f5f8fb;
    overflow-y: hidden;
  }

  /* .list-hover-tooltip {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(255, 0, 0, 0.205);
  } */

  .trading-table-total-height {
    max-height: var(--ordebookHeight) !important;
    border-bottom: 5px solid #f5f8fb;
    overflow-y: hidden;
  }

  /* .list-hover-tooltip {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(255, 0, 0, 0.205);
  } */

  /* .trading-inner-right-section {
    width: 650px;
  } */

  .trading-inner-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* width: 260px !important; */
  }

  .trading-page-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* background-color: red; */
    width: 920px !important;
    overflow: hidden;
  }

  .trade-text-10 {
    font-size: 12px;
    font-weight: 100;
    font-family: "Poppins", sans-serif;
  }

  .trade-icon-2 {
    font-size: 14px;
    position: relative;
    top: -1px;
  }

  .trade-text-9 {
    font-size: 16px;
    margin-right: 4px;
  }

  .hide-content-only {
    visibility: hidden;
  }

  .trading-page-top-section-3 .table > :not(caption) > * > * {
    padding: 0rem 0rem;
  }

  .trading-page-top-section-3 th {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .trading-page-top-section-3 tr {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
  }

  .trading-page-top-section-3 th:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 th:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-2 .form-check {
    min-height: 0rem;
    margin-bottom: 0rem;
  }

  .trading-page-top-section-2 .dropdown-item {
    padding: 0rem 1rem;
  }

  .trading-page-top-section-2 .dropdown-menu {
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(24 26 32 / 10%) 0px 0px 1px, rgb(71 77 87 / 8%) 0px 7px 14px,
      rgb(24 26 32 / 8%) 0px 3px 6px;
    border-radius: 4px;
    transform: translate(-10rem, 28px) !important;
    font-size: 11px;
  }

  .trading-page-top-section-2 .dropdown-toggle::after {
    margin-left: 0em;
    content: none;
  }

  .trading-page-top-section-2 {
    padding: 6px 0px;
  }

  .average-icon-display-1 {
    background-color: transparent;
    color: rgb(198, 202, 208);
    border: none;
  }

  .trading-page-top-section-2 .form-select {
    display: block;
    width: 100%;
    padding: 0.075rem 1.05rem 0.075rem 0.25rem;
    background-position: right 0.25rem center;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  .trading-page-top-section-2 .form-select option {
    font-size: 0.875rem;
  }

  .trading-page-top-section-2 .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.675rem;
    border-radius: 0.2rem;
    text-align: center;
  }

  .trading-page-top-section-1 {
    /* padding: 18px 0px; changed by amaithi*/
    padding: 0px 10px !important;
    border-bottom: 1px solid rgb(238, 240, 242);
    height: 100%;
    width: 100%;
    /* background-color: red; */
    /* overflow: scroll; */
  }

  .trading-page-top-section-1::-webkit-scrollbar {
    display: none;
  }

  .overflow-x-scroll-arrow-section {
    overflow: scroll !important;
  }

  .overflow-x-scroll-arrow-section::-webkit-scrollbar {
    display: none;
  }

  .order-icon-3 {
    width: 16px;
    height: 16px;
  }

  .order-icon-2 {
    width: 16px;
    height: 16px;
  }

  .order-icon-1 {
    width: 16px;
    height: 16px;
  }

  .trading-page-section-5 {
    margin-right: 20px;
  }

  .trading-page-section-4 {
    padding-right: 15px;
  }

  .trade-text-8 {
    font-size: 11px;
    color: rgb(112, 122, 138);
    margin-bottom: 0px;
  }

  .trading-page-section-3 {
    padding-right: 15px;
    margin-right: 10px;
  }

  .trade-text-7 {
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    white-space: nowrap;
    letter-spacing: 0.24px;
    margin-bottom: 0px;
  }

  .trade-text-6 {
    color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 6px;
    letter-spacing: 0.2px;
  }

  .trading-page-section-2 {
    border-right: 1px solid rgb(238, 240, 242);
    padding-right: 15px;
    margin-right: 10px;
    font-family: "Poppins", sans-serif;
    text-align: start;
  }

  .trade-text-5 {
    font-size: 15px;
    font-weight: 1000;
    margin-bottom: 0px;
    letter-spacing: 0.045em;
  }

  .trade-text-4 {
    font-size: 14px;
    font-weight: 1000;
    margin-bottom: 2px;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    letter-spacing: 1px;
  }

  .trading-page-section-1:nth-child(1) {
    /* border-right: 1px solid #dbdde6; */
    margin-right: 10px;
  }

  .trading-page-section-1 {
    padding-right: 9px;
    margin-right: 9px;
  }

  .trading-page-top-section-1 {
    /* margin: 10px 0px; */
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
  }

  .trade-text-3 {
    margin-left: 6px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 9px;
    border-radius: 2px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(249, 244, 226);
    position: relative;
    top: -2px;
  }

  .trade-icon-1 {
    font-size: 11px;
    margin-right: 2px;
  }

  .trade-text-2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);
    text-decoration: underline;
    font-size: 9px;
  }

  .trade-text-1 {
    font-size: 0.85rem;
    font-weight: 1000;
    margin-bottom: 3px;
    margin-bottom: 3px;
  }

  .order-trade-container {
    /* margin-left: .5rem !important; */
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }

  .history-container {
    /* margin: .5rem!important; */
    margin-top: -12px !important;
  }

  .TVChartContainer_TVChartContainer__R0L-L {
    height: 100% !important;
  }

  .placeorder-container {
    height: 802px !important;
  }

  .trading-page-box {
    /* border: 5px solid #f5f8fb; */
    font-family: "Roboto", sans-serif;
    /* margin-top:50px; */
  }

  .trading-page-box-left-inner-section {
    border-left: 5px solid var(--border) !important;
    /* width: 58%; */
  }

  .trading-page-box-right-inner-section {
    padding-left: 0px !important;
    border-right: 5px solid var(--border);
    /* width: 42%; */
    /* height: 800px; */
  }

  .orderbook-table-heading-section {
    /* border-bottom: 1px solid #dbdde6; */
    /* border-top: 1px solid #dbdde6; */
    height: 30px !important;
    vertical-align: middle !important;
    align-items: center !important;
  }

  .spot-page-top-banner {
    padding-top: 22px;
    padding-bottom: 100px;
  }

  .img-fluid-size {
    height: 35px;
    width: 35px;
  }

  .text-spot {
    font-size: 11px !important;
  }

  .place-order-buy-button {
    color: #fff;
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .place-order-login-button {
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    border: none;
    background-color: transparent;
  }

  .place-order-buy-sell-percentage-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-percentage-tabs .nav-pills .show > .nav-link {
    color: #fff;
    background-color: var(--btnClr);
    border: 1px solid var(--btnClr);
  }

  .place-order-buy-sell-percentage-tabs .nav-link {
    display: block;
    padding: 0.375rem 0.75rem;
    color: #727581;
    text-decoration: none;
    font-size: 13px;
    border: 1px solid #dbdde6;
    width: 90% !important;
  }

  .place-order-section-top .form-control {
    /* padding: 1rem 0.75rem; */
    font-size: 12px;
    font-weight: 400;
    color: inherit;
    background-color: transparent;
    border: 1px solid #dbdde6;
    border-right: 0px;
    border: none;
  }

  .offcanvas-header .btn-close {
    color: var(--color-1) !important;
  }

  .place-order-section-top .input-group-text {
    color: var(--input-box-text-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.26px;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-left: 0px;
    border: none;
  }

  .select-dropdown-btn-buysell {
    /* border: 1px solid #dbdde6; */
    cursor: pointer;
    height: 35px;
    display: flex;
    padding: 0px 16px;
    font-size: 14px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    align-items: center;
    font-weight: 500;
    line-height: 21px;
    border-radius: 2px;
    justify-content: space-between;
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-pills {
    padding: 5px !important;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-tabs .nav-pills .show > .nav-link {
    color: var(--tab-text-color) !important;
    background-color: #14b269;
    border: none;
    font-weight: 600;
  }

  .place-order-buy-sell-tabs button {
    color: var(--input-box-text-color) !important;
    border-radius: 5px !important;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link-sell.active,
  .place-order-buy-sell-tabs .nav-pills .show > .nav-link {
    color: var(--tab-text-color) !important;
    border: none;
    font-weight: 600;
    background-color: #ff4b5e;
  }

  .usdt-perpetual-container .nav-pills .nav-link.active,
  .usdt-perpetual-container .nav-pills .show > .nav-link {
    border-bottom: 1px solid var(--primary-color);
    border-radius: 0px;
    color: var(--color-1) !important;
  }

  .place-order-buy-sell-tabs .nav-pills .order-trade-ul {
    /* border: 1px solid #dbdde6; */
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-pills {
    background-color: var(--tv-color-toolbar-button-text, #787b86) !important;
    color: var(--color-1);
    border: none;
  }

  .order-trade-ul button {
    font-size: 15px !important;
    font-weight: 900 !important;
  }

  .place-order-buy-sell-tabs .nav-link .order-trade-ul {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.055rem;
    border-radius: 2px;
    display: block;
    padding: 0.5rem 1rem;
    color: #727581;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .top-heading-total-height {
    /* height 50 changed by amaithi */
    height: 40px;
    align-items: center;
    /* border-bottom: 1px solid #dbdde6; */
  }

  .orderbook-grid-tabs .nav-link {
    display: block;
    padding: 0.2rem 0.3rem;
    margin-left: 2px;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    border: 1px solid transparent;
  }

  .orderbook-grid-tabs .nav-pills .nav-link.active,
  .orderbook-grid-tabs .nav-pills .show > .nav-link {
    border: 1px solid #dbdde6;
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    background-color: #f5f8fb;
  }

  .fixed-top-padding-top {
    padding-top: 150px;
  }

  .footer-bottom-section {
    background-color: rgb(37, 23, 74);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: "Roboto", sans-serif;
  }

  .footer-social-icons a {
    margin-right: 10px;
    color: #fff;
    padding: 12px;
    transition: all 0.5s ease-in-out;
    border-radius: 50%;
    background-color: #3a3150;
    padding-bottom: 11px;
    padding-top: 8px;
    position: relative;
    font-size: 20px !important;
    background: rgb(37, 23, 74);
  }

  .footer-section a {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: "Roboto", sans-serif;
  }

  .footer-section h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
    color: rgb(114, 117, 129);
    letter-spacing: 0.5px;
    font-family: "Roboto", sans-serif;
  }

  .footer-section {
    padding-top: 50px;
    padding-bottom: 30px;
    background: var(--homepageBgLinear);
  }

  .ready-text-2 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .ready-text-1 {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-2 {
    font-family: sans-serif;
    color: inherit;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-1 {
    color: #242b3a;
    font-size: 22px;
    font-weight: 700;
  }

  .benefits-icons-1 {
    background-color: #00dbaf44;
    margin-right: 20px;
    border-radius: 3px;
    padding: 8px;
    font-size: 50px;
    color: var(--btnClr);
  }

  .top-banner-eight-section .card {
    height: 100%;
    padding: 20px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 15px 50px 0px rgb(11 11 11 / 8%);
    box-shadow: 0px 15px 50px 0px rgb(11 11 11 / 8%);
    border: none;
    transition: 1.5s;
  }

  .staking-image-banner {
    width: 60%;
    float: right;
  }

  .app-play-button {
    width: 167px;
    height: 60px;
  }

  .top-inner-fifth-bg-image {
    background-image: url("./images/glow1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .banner-image-bg {
    background-image: url("./images/glow.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .top-inner-fifth-section {
    background: linear-gradient(160deg, #27187b, #16014e);
    color: #fff;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 80px 40px;
    border-radius: 20px;
  }

  .top-banner-seventh-section {
    background: var(--homepageBgLinear);
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    /* clip-path: polygon(0 12%, 100% 0, 100% 89%, 0% 100%); */
  }

  .top-banner-fourth-section {
    background: var(--homepageBgLinear);
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .inner-third-section {
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 40px;
  }

  .view-more-icon {
    position: relative;
    top: -2px;
    margin-left: 4px;
  }

  .table-view-all-button-1,
  .table-view-all-button-2 {
    background-color: transparent;
    color: var(--btnClr);
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .top-banner-third-section table tbody tr:nth-child(-n + 6) {
    display: table-row;
    width: 100%;
  }

  .top-banner-third-section table tbody tr {
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr:hover {
    box-shadow: 0px 4px 8px rgb(220 226 238 / 40%);
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr {
    display: none;
  }

  .table-view-all-button-2 {
    display: none;
  }

  .top-banner-third-section .table {
    font-family: sans-serif;
  }

  .top-banner-third-section .table thead tr th {
    color: var(--primary-text-color) !important;
    background-color: var(--primary-color) !important;
    padding: 14px 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
  }

  .top-banner-third-section .table tbody td {
    padding: 14px 8px !important;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.32px;
  }

  .top-banner-third-section .table tbody img {
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }

  .top-banner-third-section .table thead {
    border-bottom: 1px solid #edeef2;
  }

  .top-banner-third-section .table thead th:last-child {
    text-align: end;
  }

  .top-banner-third-section .table tbody td:last-child {
    text-align: end;
    padding-right: 10px !important;
  }

  .top-banner-third-section .table thead th {
    width: 25%;
  }

  .top-banner-third-section .table tbody {
    border: 2px solid #edeef2;
  }

  .top-banner-third-section .table > :not(:first-child) {
    border-top: 0px;
  }

  .top-banner-third-section .nav-link {
    color: #727581;
    opacity: 1;
    padding: 0px 16px;
    font-size: 14px;
    min-width: 90px;
    min-height: 35px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .top-banner-third-section .nav-pills .nav-link.active,
  .top-banner-third-section .nav-pills .show > .nav-link {
    color: var(--primary-text-color) !important;
    background-color: var(--primary-color);
    font-weight: 600;
  }

  .market-trade-tabs {
    border-radius: 2px;
    background-color: rgb(200 205 223 / 16%);
    width: fit-content;
  }

  .banner-image-1 {
    width: 6px;
    position: relative;
    top: -4px;
    left: -12px;
  }

  .banner-text-7 {
    font-size: 1rem;
    font-weight: 100;
    color: #727581;
    letter-spacing: 1px;
  }

  .banner-text-6 {
    font-size: 1.4rem;
    font-weight: 100;
    letter-spacing: 2px;
  }

  .banner-text-5 {
    font-size: 1.1rem;
    font-weight: 100;
    letter-spacing: 1px;
  }

  .banner-text-4 {
    font-size: 2.2rem;
    font-weight: 600;
  }

  .banner-text-3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px !important;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet-active {
    background: var(--btnClr) !important;
  }

  .swiper-pagination-bullet {
    width: 25px !important;
    border-radius: 12px !important;
    height: 6px !important;
  }

  .main-banner-background-styling {
    background: var(--homepageBgLinear);
    color: #fff;
    /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 88%); */
    padding-bottom: 20vh;
    margin-bottom: 5vh;
  }

  .bg-poligon {
    background: var(--homepageBgLinear);
    /* clip-path: polygon(0 11%, 100% 32%, 100% 100%, 0 100%); */
    padding-top: 3vh;
    margin-top: 20vh;
    z-index: 1;
  }

  .relative-ajustment {
    position: relative;
    margin-bottom: -10vh;
    z-index: 99;
  }

  .banner-top-icons-1 {
    margin-left: 6px;
    font-size: 20px;
    margin-bottom: 2px;
  }

  .banner-top-button-4 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 15px 40px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    background: transparent;
    border: 1px solid var(--btnClr);
    color: var(--btnClr) !important;
  }

  .banner-top-button-3 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 15px 40px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
  }

  .banner-top-button-2 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 20px 40px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
  }

  .banner-top-button-login {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 40px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
    border: none;
  }

  .click-here-button {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 5px 15px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
    border: none;
  }

  .banner-top-button-copy {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 40px;
    position: relative;
    border-radius: 2em;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
    border: none;
  }

  .banner-top-button-reset {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 40px;
    position: relative;
    border-radius: 2em;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background-color: rgb(194, 194, 194);
    border: none;
  }

  .banner-top-button-copy-full {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 40px;
    position: relative;
    border-radius: 2em;
    font-weight: 500;
    font-size: 16px;
    /* background: #20fdee; */
    background: var(--btnClr);
    border: none;
  }

  .banner-top-button-1 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 20px 40px;
    position: relative;
    border-radius: 52px;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
  }

  .banner-text-2 {
    font-size: 0.95rem;
    font-weight: 100;
    width: 75%;
    letter-spacing: 2px;
  }

  .banner-text-1 {
    font-size: 3.6rem;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .top-banner-hero-section {
    padding-top: 140px;
    padding-bottom: 80px;
  }

  #classy-navbar-mobile {
    height: 50px;
    background-color: var(--homepageBgColor);
    color: var(--navFontColor);
    /* background-color: #11003b; */
  }

  .nav-register-button {
    color: var(--primary-text-color) !important;
    /* width: 90%; */
    /* margin-top: 25px; */
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    padding: 5px 10px !important;
    /* margin-top: 10px; */
    justify-content: center;
    background-color: var(--primary-color);
    box-shadow: #ffd748 0px 0px 10px, rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -3px 0px inset, #f7a600 0px 0px 15px inset;
  }

  #classy-navbar-mobile .nav-link {
    /* box-shadow: none !important; */
    font-size: 14px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    letter-spacing: 0.08em;
    /* padding-top: 20px;
    padding-bottom: 20px; */
  }

  #classy-navbar-mobile .navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .navbar-nav li:hover > ul.dropdown-menu {
    display: block;
  }

  #classy-navbar-mobile .dropdown-menu {
    top: 95%;
    /* left: auto !important; */
    left: -2px;
    margin-top: 0rem;
  }

  /* .successfullyposted-modal {
    top: 20% !important;
  } */

  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: 0px;
  }

  #classy-navbar-mobile .dropdown-menu {
    min-width: 22rem;
    padding: 10px 10px;
  }

  #classy-navbar-mobile .transaction-history-dropdown {
    min-width: 22rem;
    margin-left: -16rem;
    padding: 10px 10px;
  }

  .top-nav-dropdown-item-icons-1 {
    font-size: 18px;
    margin-right: 20px;
  }

  .top-nav-dropdown-item-icons-2 {
    color: transparent;
  }

  .top-nav-dropdown-item-text-1 {
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 0px;
    font-family: "Roboto", sans-serif;
  }

  .top-nav-dropdown-item-text-2 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    position: relative;
    top: -3px;
    color: #707a8a;
    font-family: "Roboto", sans-serif;
  }

  .dropdown-item-hover {
    padding: 10px 20px;
  }

  .navbar-top-modes-dropdown {
    width: 40rem;
    margin-left: -20rem;
    padding: 20px 13px !important;
    /* background-color: red; */
  }

  .navbar-top-hover-dropdown .dropdown-item-hover:hover {
    border-radius: 4px;
  }

  .themes-dropdown-text-1 {
    font-size: 22px;
  }

  .mode-image-dropdown-1,
  .mode-image-dropdown-2,
  .mode-image-dropdown-3,
  .mode-image-dropdown-4,
  .mode-image-dropdown-5,
  .mode-image-dropdown-6 {
    width: 90px;
    margin-right: 8px;
  }

  .switch-modes-section {
    padding: 20px 20px;
  }

  .switch-modes-section label {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
  }

  .form-check-input:checked[type="radio"] {
    background-image: none;
  }
}

/* =========================================== MOBILE-VIEW =================================== */

@media (max-width: 991px) {
  .token-price-container {
    min-height: 150px;
    overflow-y: auto;
  }
  /*======================Simple-earn CSS Start ===============================*/
  .simpleearn-history-filter-status {
    background-color: #7cffe5;
    padding: 10px;
    color: #000 !important;
  }
  .switch-ul {
    margin: 30px;
  }

  .grid-body-container {
    /* min-height: 500px; */
    /* max-height: 500px;
    overflow-y: auto; */
  }

  .convet-container {
    padding: 20px 30px !important;
  }

  .swap-data {
    font-size: 20px !important;
  }

  .web-hide {
    display: inline-block;
  }

  .main-container div,
  .main-container span {
    font-size: 10px;
  }

  .model-popup .modal-content {
    width: 100% !important;
  }

  .grid-btn {
    font-size: 10px !important;
    text-align: center;
    height: 45px;
  }

  .simpleearn-history-filter-1 {
    background-color: #00dbae;
    font-size: 14px;
  }

  .accountearn-staking-table-tabs-section .form-check {
    margin-bottom: 0px;
  }

  .simpleearn-redeem {
    color: #00dbae !important;
  }

  .coin-price-container {
    padding-left: 20px;
  }

  .coin-price-container img {
    height: 70%;
    width: 250%;
  }

  .accountearn-staking-table-tabs-section .nav-link {
    font-size: 14px;
  }

  .accountearn-text-4 {
    font-size: 18px;
  }

  .accountearn-staking-tabs-section .nav-link {
    font-size: 14px;
  }

  .accountearn-staking-tabs-section .nav-pills .nav-link.active,
  .accountearn-staking-tabs-section .nav-pills .show > .nav-link {
    color: #000000 !important;
    background-color: #7cffe5;
  }

  .accountearn-top-value-section-3 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .accountearn-all-assest {
    padding: 6px 10px;
    border-radius: 4px;
    position: relative;
    top: -4px;
    font-size: 12px;
  }

  .accountearn-text-3 {
    font-size: 16px;
  }

  .accountearn-top-value-section-2 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .accountearn-text-2 {
    font-size: 14px;
  }

  .accountearn-text-1 {
    font-size: 1.6rem;
  }

  .accountearn-top-banner-section {
    padding-top: 65px;
  }

  .simpleearn-staking-subscribe-table #row-1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .simpleearn-subscribe-button {
    background-color: #00dbae;
    border: none;
    padding: 8px;
    color: #000;
    border-radius: 4px;
    font-size: 14px;
  }

  .simpleearn-text-17 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .simpleearn-text-16 {
    font-size: 10px;
  }

  .simpleearn-text-15 {
    font-size: 13px;
  }

  .simpleearn-enough-crypto {
    background-color: #78f5dc;
    padding: 4px;
    border-radius: 4px;
    margin-top: 20px;
    box-shadow: rgba(24, 26, 32, 0.1) 0px 0px 1px,
      rgba(71, 77, 87, 0.08) 0px 7px 14px, rgba(24, 26, 32, 0.08) 0px 3px 6px;
  }

  .simpleearn-text-14 {
    font-size: 12px;
    text-decoration: underline;
  }

  .simpleearn-text-13 {
    font-size: 20px;
  }

  .simpleearn-text-12 {
    color: #00dbae;
    text-decoration: underline;
  }

  .subscription-amount-group-1 {
    border-left: none;
    border-right: none;
  }

  .subscription-amount-group-2 {
    border-left: none;
  }

  .simpleearn-subscription-amount .form-control {
    background-color: transparent !important;
    border-right: 0px;
    border-right: 0px;
    font-size: 12px;
  }

  .simpleearn-subscription-amount .form-control:focus {
    border-color: #ced4da;
  }

  .simpleearn-subscription-amount .input-group-text {
    background-color: transparent !important;
  }

  .simpleearn-max-button {
    background-color: transparent;
    border: none;
    color: #00dbae !important;
    padding-right: 14px !important;
    border-right: 1px solid #ced4da;
    padding: 0px;
  }

  .simpleearn-text-11 {
    font-size: 12px;
  }

  .simpleearn-subscribe-img {
    width: 20px;
    height: 20px;
  }

  .btn-simpleearn-filter-table {
    background-color: #00dbae;
    color: #000 !important;
    padding: 6px 10px;
    border-radius: 4px;
    border: 0px;
  }

  .simpleearn-table-tabs-duration .nav-link {
    position: relative;
  }

  .simpleearn-table-tabs-duration .nav-link.active::before {
    transition: 0.15s;
    content: "✔";
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 6px;
    width: 8px;
    height: 8px;
    background-color: #00dbae;
    border-bottom-left-radius: 10px;
    color: #000000;
  }

  .simpleearn-table-tabs-duration.nav-pills .nav-link.active,
  .simpleearn-table-tabs-duration.nav-pills .show > .nav-link {
    color: #fff;
    background-color: transparent !important;
    border: 1px solid #00dbae;
  }

  .simpleearn-text-10 {
    font-size: 14px;
  }

  .simpleearn-text-9 {
    font-size: 16px;
  }

  .simpleearn-table-coin-img {
    width: 25px;
    height: 25px;
  }

  .simpleearn-text-7 {
    font-size: 26px;
  }

  .simpleearn-text-8 {
    font-size: 14px;
  }

  .simpleearn-top-filter-section .searchBox {
    font-size: 14px;
  }

  .simpleearn-top-filter-section .chip {
    align-items: center;
    background: #00dbae !important;
    margin-bottom: 0px !important;
  }

  .simpleearn-top-filter-section .multiSelectContainer li:hover {
    background: #00dbae !important;
    color: #fff;
    cursor: pointer;
  }

  .simpleearn-top-filter-section .highlightOption {
    background: #00dbae !important;
    color: #fff;
  }

  .simpleearn-top-filter-section .Multiselect-simple-earn {
    background-color: transparent;
    border-color: #ced4da;
    font-size: 14px;
  }

  .simpleearn-top-filter-section .search-wrapper {
    padding: 7px 10px !important;
  }

  .simpleearn-top-filter-section .input-group-text-1 {
    background-color: transparent !important;
    border-right: 0px;
  }

  .simpleearn-top-filter-section .input-group-text-2 {
    background-color: transparent !important;
    border-left: 0px;
    font-size: 12px;
  }

  .simpleearn-top-filter-section .form-control {
    background-color: transparent !important;
    color: #fff !important;
    border-left: 0px;
    border-right: 0px;
    font-size: 14px;
    padding: 9px 0px;
  }

  .simpleearn-top-filter-section .form-control:focus {
    border-color: #ced4da;
  }

  .simpleearn-text-6 {
    font-size: 10px;
  }

  .simpleearn-text-5 {
    font-size: 16px;
    font-weight: 700;
  }

  .simpleearn-text-4 {
    font-size: 14px;
  }

  .simpleearn-text-3 {
    font-size: 20px;
    cursor: pointer;
  }

  .simpleearn-top-value-section .card {
    border: 1px solid #00dbaf60;
    padding: 18px;
  }

  .simpleearn-text-1 {
    font-size: 1.6rem;
    margin-bottom: 0px;
  }

  .simpleearn-text-2 {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .simpleearn-top-value-section {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .simpleearn-top-banner-section {
    padding-top: 65px;
  }

  /*========================Simple-earn CSS End ====================================*/

  /*======================prediction Bear and Bull CSS Start==========================*/
  .prediction-bets-table-section {
    overflow-x: scroll;
  }

  .prediction-bets-table-section table {
    width: 900px;
  }

  .prediction-trade-right-section .f-control {
    font-size: 12px !important;
  }

  .prediction-trade-right-section .f-dropdown {
    border: 1px solid transparent !important;
  }

  .trade-right-section-table-anchor {
    font-size: 14px;
    color: #00dbae;
  }

  .trade-right-section-table-anchor:hover {
    color: #00dbae;
    text-decoration: underline;
  }

  .bets-table-trade-1 {
    width: 10%;
  }

  .bets-table-trade-2 {
    width: 6%;
  }

  .bets-table-trade-3 {
    width: 14%;
  }

  .bets-table-trade-4 {
    width: 10%;
  }

  .bets-table-trade-5 {
    width: 10%;
  }

  .bets-table-trade-6 {
    width: 10%;
  }

  .bets-table-trade-7 {
    width: 10%;
  }

  .bets-table-trade-8 {
    width: 14%;
  }

  .bets-table-trade-9 {
    width: 10%;
  }

  .bets-table-trade-10 {
    width: 6%;
  }

  .prediction-bets-table-section .bets-trade-tablebd-1 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .prediction-bets-table-section .bets-trade-tablebd-2 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .prediction-trade-right-section-table-section {
    padding: 10px 20px;
  }

  .prediction-bet-button-up {
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    color: rgb(20, 23, 34) !important;
    background: rgb(134, 244, 84);
    box-shadow: rgba(118, 255, 25, 0.4) 0px 0px 10px,
      rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
      rgb(59, 198, 14) 0px 0px 15px inset;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    letter-spacing: 0.5px;
  }

  .prediction-bet-button-down {
    font-weight: 800;
    font-style: normal;
    font-size: 16px;
    color: rgb(20, 23, 34) !important;
    background: rgb(255, 106, 106);
    box-shadow: rgba(255, 73, 73, 0.42) 0px 0px 10px,
      rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -3px 0px inset,
      rgb(255, 55, 55) 0px 0px 15px inset;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    letter-spacing: 0.5px;
  }

  .prediction-trade-text-6 {
    font-size: 12px;
  }

  .prediction-trade-updown-input {
    border-radius: 4px;
  }

  .prediction-updown-button {
    border: none !important;
    margin: 6px;
    padding: 4px 12px;
    font-size: 11px;
    background-color: #00dbae !important;
    color: #000 !important;
    border-radius: 4px !important;
  }

  .prediction-trade-updown-input .input-group-text {
    background-color: transparent;
    border: none !important;
  }

  .prediction-trade-updown-input .form-control {
    border-right: 0px;
    border-left: 0px;
    border: none !important;
    background-color: transparent !important;
    font-size: 13px;
  }

  .prediction-trade-updown-input .form-control:active {
    border-right: 0px;
    border-left: 0px;
    border: none !important;
  }

  .prediction-trade-updown-input .form-control:focus {
    border-color: #ced4da !important;
  }

  .prediction-manual-coin {
    width: 16px;
    height: auto;
  }

  .earn-col-main {
    padding: 10px 0px !important;
  }

  .mobile-hide {
    display: none;
  }

  .prediction-manual-updown-tabs .nav-link {
    font-size: 14px;
    padding: 10px 1px;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 0px;
  }

  .prediction-trade-text-5 {
    font-size: 18px;
    position: relative;
    top: -1px;
  }

  .prediction-trade-text-4 {
    font-size: 12px;
    font-weight: 1000;
  }

  .prediction-manual-auto-tabs .nav-pills .nav-link.active,
  .prediction-manual-auto-tabs .nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #00dbae;
  }

  .prediction-manual-auto-tabs .nav-link {
    font-size: 12px;
    padding: 6px 12px;
    text-transform: uppercase;
  }

  .prediction-trade-settings-section label {
    font-size: 13px;
  }

  .prediction-trade-settings-section .dropdown-menu {
    left: 0% !important;
    right: 0px !important;
    width: 100vw !important;
    padding: 6px 16px;
  }

  .prediction-trade-tick-button {
    border: none;
    font-size: 18px;
    padding: 4px 12px;
    border-radius: 4px;
  }

  .prediction-trade-right-1 .f-control {
    border: none;
    font-size: 14px;
    padding: 6px 12px !important;
    border-radius: 4px;
    border: none !important;
  }

  .prediction-trade-text-3 {
    opacity: 0.8;
  }

  .prediction-trade-text-2 {
    font-size: 12px;
  }

  .prediction-trade-text-1 {
    font-size: 1rem;
    margin-right: 2px;
  }

  .prediction-trade-image-1 {
    width: 40px;
    height: auto;
  }

  .prediction-trade-right-1 {
    padding: 10px 16px;
  }

  .prediction-trade-right-2 {
    padding: 10px 16px;
  }

  .prediction-trade-top-banner {
    padding-top: 65px;
  }

  .prediction-trade-top-banner ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

  .prediction-trade-top-banner ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .prediction-trade-left-section {
    /* height: calc(100vh); */
    padding: 10px 12px;
    /* overflow-y: scroll; */
  }

  .prediction-trade-right-section {
    /* height: calc(100vh - 65px);
    overflow-y: scroll; */
  }

  .prediction-bets-table-section .bets-tablebd-1 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .prediction-bets-table-section .bets-tablebd-2 img {
    width: 18px;
    height: auto;
    margin-right: 4px;
  }

  .bets-table-1 {
    width: 30%;
  }

  .bets-table-2 {
    width: 20%;
  }

  .bets-table-3 {
    width: 8%;
  }

  .prediction-bets-table-section .table > :not(caption) > * > * {
    border: none !important;
  }

  .prediction-bets-table-section tr {
    background: rgba(203, 215, 255, 0.075) !important;
    border-bottom: 3px solid #000 !important;
  }

  .prediction-bets-table-section table {
    background-color: #30323600 !important;
    font-size: 11px;
  }

  .prediction-bets-table-section table thead {
    opacity: 0.7;
    text-transform: uppercase;
    font-size: 10px;
  }

  .prediction-bets-table-section th {
    border: none !important;
  }

  .prediction-bets-table-section .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .prediction-bets-tabs-section .nav-pills .nav-link.active,
  .prediction-bets-tabs-section .nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #3cffd8;
  }

  .prediction-bets-tabs-section .nav-link {
    display: block;
    padding: 0.2rem 0.5rem;
    color: #0d6efd;
    text-decoration: none;
    font-size: 10px !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .prediction-page-top-banner ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

  .prediction-page-top-banner ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .prediction-page-left-section {
    display: none;
  }

  .prediction-page-middle-section {
    width: 100%;
    padding: 10px 22px;
  }

  .prediction-page-right-section {
    display: none;
  }

  .prediction-swiper-card-button {
    font-size: 10px;
    padding: 6px 8px;
    color: #ffffffc2;
    margin-top: 4px;
  }

  .prediction-text-9 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .prediction-text-10 {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .swiper-card-logo img {
    width: 32px;
    height: auto;
    margin: auto;
  }

  .prediction-text-8 {
    padding: 8px 0px 0px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .prediction-page-middle-section .swiper-card {
    background: rgba(203, 215, 255, 0.075);
    padding: 12px 4px;
    border-radius: 4px;
  }

  .prediction-caro-icon {
    color: #e9e9e986;
  }

  .prediction-caro-2 img {
    width: 50%;
  }

  .prediction-text-7 {
    font-size: 12px;
  }

  .prediction-text-6 {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 4px;
  }

  .prediction-caro-icon {
    background: rgba(203, 215, 255, 0.075);
    padding: 8px;
    font-size: 30px;
    border-radius: 8px;
    cursor: pointer;
  }

  .prediction-caro-1 {
    width: 10%;
    text-align: center;
  }

  .prediction-caro-2 {
    width: 80%;
  }

  .prediction-caro-3 {
    width: 10%;
    text-align: center;
  }

  .prediction-total-rewards {
    background: rgba(203, 215, 255, 0.075);
    padding: 8px 14px;
    width: fit-content;
    border-radius: 4px;
  }

  .prediction-image-medal {
    width: 16px;
    height: auto;
  }

  .prediction-text-5 {
    color: #e9e9e9b6;
  }

  .prediction-text-4 {
    font-size: 0.86rem;
    letter-spacing: 1px;
  }

  .prediction-text-3 {
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .prediction-page-middle-slider {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://rollbit.com/static/media/banner-welcome.2c365efcff25a405dcff.jpg");
    border-radius: 8px;
  }

  .prediction-page-middle-slider-inner {
    background: radial-gradient(
      circle at 79.5% 88%,
      rgb(17, 51, 103) 8%,
      transparent 90%
    );
    padding: 20px 40px;
    border-radius: 8px;
  }

  .prediction-chat-section {
    background-color: #00000059;
    margin-bottom: 6px;
    padding: 2px 8px;
    border-radius: 4px;
  }

  .prediction-text-1 {
    font-size: 13px;
    margin-right: 6px;
    font-weight: 600;
  }

  .prediction-text-2 {
    font-size: 10px;
  }

  .prediction-image-1 {
    width: 22px;
    height: auto;
    margin-right: 6px;
  }

  .prediction-page-right-2 {
    padding-top: 10px;
    height: calc(100vh - 159px);
    overflow-y: scroll;
  }

  .prediction-page-right-1 {
    height: 44px;
  }

  .prediction-page-right-3 {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
  }

  .prediction-page-right-section .input-group-text {
    background-color: transparent !important;
    font-size: 14px !important;
    border-left: 0px !important;
    color: #fff;
  }

  .prediction-page-features-section-2 {
    padding: 8px 8px;
    border-radius: 4px;
    padding-bottom: 14px !important;
  }

  .prediction-page-right-section .form-control {
    background-color: transparent !important;
    font-size: 14px !important;
    border-right: 0px !important;
  }

  .prediction-page-right-section ::placeholder {
    font-size: 12px !important;
  }

  .prediction-page-right-section .nav-pills .nav-link.active,
  .prediction-page-right-section .nav-pills .show > .nav-link {
    color: #000 !important;
    background-color: #3cffd8;
  }

  .prediction-page-right-section .nav-link {
    padding: 4px 8px;
    font-size: 13px;
  }

  #features-dropdown-close {
    display: none;
  }

  .prediction-page-text-5 {
    font-size: 20px;
    position: relative;
    top: -2px;
    margin-left: 6px;
  }

  .prediction-features-list {
    padding: 4px 8px;
    border-radius: 4px;
  }

  .prediction-page-text-4 {
    font-size: 13px;
    vertical-align: middle;
    opacity: 0.9;
  }

  .prediction-page-image-1 {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .prediction-page-top-banner li {
    list-style: none;
  }

  .prediction-page-features-section {
    padding: 8px 8px;
    border-radius: 4px;
  }

  .prediction-page-text-3 {
    font-size: 20px;
  }

  .prediction-page-text-2 {
    font-size: 13px;
  }

  .prediction-page-text-1 {
    font-size: 16px;
  }

  .prediction-padding-right {
    padding: 10px 12px;
  }

  .prediction-page-top-banner {
    padding-top: 65px;
  }

  /*======================prediction Bear and Bull CSS end==========================*/

  .hJEigz > input {
    position: absolute !important;
    overflow: hidden;
  }

  .hJEigz {
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
  }

  .f-35 {
    font-size: 20px;
  }

  .f-12 {
    font-size: 10px !important;
  }

  .f-16 {
    font-size: 13px !important;
  }

  .f-35 {
    font-size: 20px;
  }

  .f-25 {
    font-size: 15px;
  }

  .popup {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    background-color: transparent;

    z-index: 999;
  }

  #scrollmodalopen {
    width: 400px;
    margin: auto;
    position: relative;
    top: 20vh;
  }

  .scrollmodalopenimg {
    width: 400px;
    height: 200px;
  }

  .crossclose-img {
    width: 20px;
    height: 20px;
  }

  .scrollmodal-bg {
    background-color: #03125e;
    width: 400px;
    height: 200px;
    color: #ffffff !important;
  }

  .copytrading-portfolio-nav-tabs-css .nav-tabs .nav-link.active {
    font-size: 14px !important;
    padding: 1em;
    background-color: transparent !important;
  }

  .copytrading-portfolio-nav-tabs-css .nav-tabs .nav-link {
    font-size: 14px !important;
    padding: 1em;
    background-color: transparent !important;
  }

  .p2p-trade-table {
    border: none !important;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    /* table-layout: fixed; */
  }

  .market-trade-tabs-banner-section {
    overflow-x: scroll;
  }

  .market-trade-tabs-banner-section table {
    width: 400px;
  }

  .css-b62m3t-container {
    width: 140px !important;
  }

  .scroll-overflow-table {
    overflow: scroll !important;
  }

  .overflow-x-scroll-arrow-section {
    overflow: scroll !important;
  }

  .overflow-x-scroll-arrow-section::-webkit-scrollbar {
    display: none !important;
  }

  .dashboard-spot-trade-voucher-section-scroll {
    width: 100%;
    overflow-x: scroll;
  }

  .dashboard-spot-trade-voucher-section-scroll table {
    width: 700px;
  }

  .markets-search-group-section .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 5rem;
    border-right: 0px;
  }

  .markets-search-group-section .form-control {
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5rem;
    border-left: 0px;
  }

  .trade-circle-flex {
    margin-left: auto !important;
  }

  .spot_history_pagination {
    margin-left: 100px !important;
  }

  .chatbox-buy-modal-1-styling {
    background-color: var(--chatPopupBg) !important;
    position: absolute;
    top: 90%;
    width: 320px;
    z-index: 999;
  }

  .chatbox-sell-modal-1-styling {
    background-color: var(--chatsellPopupBg) !important;
    position: absolute;
    top: 90%;
    width: 320px;
    z-index: 999;
  }

  .add-payment-method-cancel-button {
    background-color: #eaecef;
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: sans-serif;
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .add-payment-method-confirm-button {
    background-color: var(--btnClr);
    color: #1e2329;
    height: 40px;
    border: none;
    font-family: sans-serif;
    font-size: 14px;
    border-radius: 0.25rem;
  }

  .paired-trade-dropdown-table tbody {
    display: block;
    height: 350px;
    overflow: auto;
  }

  .paired-trade-dropdown-table thead,
  .paired-trade-dropdown-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .paired-trade-dropdown-table .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .paired-trade-dropdown-table .table > :not(caption) > * > * {
    border: 0px !important;
    padding: 0.4rem 0.5rem;
  }

  .paired-trade-dropdown-table .table tbody tr {
    border-bottom: 1px solid #ced4da;
  }

  .paired-trade-dropdown-table .table tbody tr:hover {
    background-color: #ced4da;
  }

  .paired-trade-dropdown-table .table tbody tr td:first-child {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .paired-trade-dropdown-table .table tbody tr td {
    padding-left: 20px;
    font-size: 13px;
    font-weight: 400;
  }

  .paired-trade-dropdown-table th {
    padding-left: 20px !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    letter-spacing: 00.045em;
  }

  .paired-trade-dropdown-tabs-1 .form-control {
    padding: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 .input-group-text {
    padding: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .paired-trade-dropdown-tabs-1 {
    border: 1px solid #dbdde6;
    padding: 0.775rem 0.75rem;
  }

  .paired-trade-dropdown-tabs .nav-pills .nav-link.active,
  .paired-trade-dropdown-tabs .nav-pills .show > .nav-link {
    color: var(--btnClr) !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-trade-dropdown-tabs .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: #727581 !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    border-radius: 0px;
    margin-right: 10px;
  }

  .spot-page-pair-select-dropdown .dropdown-item {
    min-width: 100vw !important;
  }

  .spot-page-pair-select-dropdown .dropdown-item:hover {
    background-color: transparent !important;
  }

  .spot-page-pair-select-dropdown .dropdown-toggle {
    padding-bottom: 30px;
  }

  .spot-table-search-button {
    color: #000;
    border: 0px;
    width: 100%;
    padding: 0.2rem 1rem;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid rgb(238, 240, 242);
  }

  .paired-refresh-button {
    color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 0px;
    letter-spacing: 0.2px;
    border: 0px;
    padding: 0px;
    background: transparent;
  }

  .trading-page-order-tabs-section {
    height: 120px !important;
  }

  .p2p-trade-buy-sell-collapse-container {
    border: none;
    /* background-color: rgb(48, 48, 48); */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .p2p-trade-buy-sell-collapse-container .form-select:focus {
    border: 1px solid rgb(114, 114, 114);
    outline: 0;
    box-shadow: none !important;
  }

  .p2p-trade-buy-sell-collapse-container .form-control {
    background-color: transparent !important;
  }

  .p2p-trade-buy-sell-collapse-container .form-select {
    background-color: transparent;
  }

  .buynow-btn {
    background-color: var(--btnClr);
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .sellnow-btn {
    background-color: rgba(241, 55, 55);
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2p-trade-hero-section-two-nav-bg .dropdown-toggle::after {
    display: none;
  }

  .p2p-second-nav-icons-more-button {
    font-size: 1.4em;
  }

  .noresponse-image-styling {
    width: 90px;
  }

  .profile-images-letter-styling {
    background-color: #000000 !important;
    border-radius: 1em !important;
    color: #ffffff;
    padding: 3px;
    font-size: 1.3em !important;
  }

  /* .scrollspy-payments-row-bg-styling {
    background: rgb(250, 250, 250);
  } */

  .font-size-style {
    font-size: 17px;
    font-weight: 600;
  }

  .p2puser-center-button {
    background-color: rgb(14, 203, 129);
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
  }

  .p2puser-center-scrollspy-styling-active {
    border-bottom: 3px solid #00dbae !important;
  }

  .p2puser-center-scrollsp-border {
    border-bottom: 1px solid #dee2e6;
  }

  .p2puser-center-scrollspy-button {
    border: none;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: 500;
    color: inherit;
    letter-spacing: 0.08em;
    padding: 10px 10px;
    background-color: transparent;
    border-bottom: 3px solid transparent;
  }

  .p2puser-center-scrollspy-styling .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: inherit;
    font-weight: 500;
  }

  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-item.show .nav-link,
  .p2p-user-center-nav-tabs-styling .nav-tabs .nav-link.active {
    color: #495057 !important;
    background-color: #fff;
    border-bottom: 3px solid var(--btnClr) !important;
  }

  .p2puser-center-scrollspy-styling .dropdown-toggle::after {
    /* display: none; */
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .p2p-user-scrollspy-button {
    color: rgb(30, 35, 41);
    background-color: rgb(234, 236, 239);
    font-size: 14px;
  }

  .p2p-user-center-scrollspy-tops-heading {
    min-width: 0px;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .border-line-styling {
    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    width: 4px;
    height: 14px;
    border-radius: 4px;
    background-color: rgb(95, 37, 159) !important;
  }

  .scrollspy-tops-heading-paragraph {
    min-width: 0px;
    max-width: 100%;
    font-size: 14px !important;
  }

  #scrollspy-section-1-para {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px, rgb(0 0 0 / 8%) 0px 2px 4px;
    margin: 0px 0px 32px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgb(234, 236, 239);
  }

  #scrollspy-section-2-para {
    margin: 0px 0px 32px;
    padding: 10px;
    border-radius: 4px;
  }

  #scrollspy-section-3-para {
    margin: 0px 0px 32px;
    padding: 10px;
    border-radius: 4px;
  }

  .p2p-usercenter-progressbar {
    position: relative !important;
    right: 0px;
    z-index: 1;
    height: 9px;
    width: 100px;
    background-color: var(--btnClr);
  }

  .p2p-usercenter-progressbar-top {
    height: 9px;
    width: 100px;
  }

  .faq .card {
    border: none;
    background: none;
  }

  .faq .card .card-header {
    padding: 0px;
    border: none;
    background: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .faq .card .card-header:hover {
    background: #ffd70026;
    /* padding-left: 10px; */
  }

  .faq .card .card-header .faq-title {
    width: 100%;
    text-align: left;
    padding: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
    color: inherit;
    text-decoration: none !important;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .faq .card .card-header .faq-title .badge {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 20px;
    float: left;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    background: var(--primary-color);
    color: var(--primary-text-color);
    font-size: 16px;
    margin-right: 10px;
  }

  .faq .card .card-body {
    padding: 30px;
    padding-left: 35px;
    font-weight: 400;
    font-size: 16px;
    color: inherit;
    letter-spacing: 1px;
  }

  .datepicker-css-styling {
    border: none !important;
    width: 100px !important;
    outline: none !important;
    font-size: 0.9rem;
    background-color: transparent;
  }

  .datepicker-css-styling input {
    border: none !important;
    box-shadow: none !important;
  }

  .order-p2p-trade-dropdowns.select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    background-color: transparent;
  }

  .order-p2p-reset-content-title {
    font-size: 12px;
    font-weight: 500;
    color: rgb(14, 203, 129);
  }

  .order-p2p-trade-download-icon {
    font-size: 35px;
    background-color: rgb(214, 214, 214) !important;
  }

  .advantage-p2p-images {
    width: 200px;
    height: 150px;
    border-radius: 0.5em;
    margin-bottom: 1em;
  }

  .p2p-second-nav-content-title {
    font-size: 10px;
  }

  .hero-top-section {
    background-image: url("../assets/images/p2p/p2p-trade-background.png");
    background-size: 400% 100%;
  }

  .p2p-bottom-buy-sell-tabs-content-image {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    max-width: 100%;
    height: auto;
    width: 80px;
    max-height: 80px;
    margin-bottom: 24px;
    margin-top: 20px;
  }

  .p2p-bottom-buy-sell-tabs-content-title-paragrah {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: inherit;
    font-size: 14px;
  }

  .p2p-bottom-buy-sell-tabs-content-title {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: #000000;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.1s;
    font-weight: 500;
  }

  .p2p-bottom-buy-sell-tabs .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--primary-text-color) !important;
    background-color: var(--primary-color) !important;
  }

  .p2p-bottom-buy-sell-tabs {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    width: 100%;
  }

  .p2p-bottom-section-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .p2p-bottom-section-heading {
    padding-left: 0;
    padding-right: 0;
    font-size: 30px;
    line-height: 40px;
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -ms-flexbox;
    display: flex;
    max-width: 1200px;
    /* color: #1e2329; */
    font-weight: 550;
  }

  .enter-amount-section .btn {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    color: rgb(14, 203, 129);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0rem;
    font-size: 0.9rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section .btn:hover {
    color: #e0ad06;
  }

  .enter-amount-text-section {
    border-right: 1px solid #eaecef;
  }

  .enter-amount-text-1 {
    font-size: 15px;
    color: #aeb4bc;
  }

  .enter-amount-section .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .enter-amount-section {
    border: 1px solid #f0bb0b50;
    padding: 0.175rem 0rem;
    border-radius: 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #cccdcf !important;
    font-size: 14px;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  input:focus {
    box-shadow: none !important;
  }

  .enter-amount-heading {
    font-size: 11px;
    color: #76808f;
  }

  .enter-amount-text-2 {
    font-size: 11px;
    color: #76808f;
    margin-left: -10px;
  }

  .f-dropdown {
    --max-scroll: 3;
    position: relative;
    z-index: 10;
  }

  .f-dropdown select {
    display: none;
  }

  .f-dropdown > span {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    color: #bbb;
    border: 1px solid #f0bb0b50 !important;
    background: #f5f5f5;
    transition: color 0.2s ease, border-color 0.2s ease;
  }

  .f-dropdown > span > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }

  .f-dropdown > span img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown > span:before,
  .f-dropdown > span:after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 2px;
    border-radius: 1px;
    top: 50%;
    right: 12px;
    background: #000;
    transition: all 0.3s ease;
  }

  .f-dropdown > span:before {
    margin-right: 4px;
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown > span:after {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    max-height: calc(var(--max-scroll) * 46px);
    top: 40px;
    left: 0;
    z-index: 1;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: 0 0;
    transition: opacity 0.2s ease, visibility 0.2s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
    transform: translate(0, 5px);
  }

  .f-dropdown ul li {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  .f-dropdown ul li a {
    cursor: pointer;
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    outline: none;
    position: relative;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
  }

  .f-dropdown ul li a img {
    width: 25px;
    margin-right: 10px;
  }

  .f-dropdown ul li a:hover {
    color: #000 !important;
  }

  .f-dropdown ul li.active a {
    color: #000;
    background: gray;
  }

  .f-dropdown ul li.active a:before,
  .f-dropdown ul li.active a:after {
    --scale: 0.6;
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    position: absolute;
    right: 12px;
    top: 50%;
    opacity: 0;
    background: #fff;
    transition: all 0.2s ease;
  }

  .f-dropdown ul li.active a:before {
    transform: rotate(45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:after {
    transform: rotate(-45deg) scale(var(--scale));
  }

  .f-dropdown ul li.active a:hover:before,
  .f-dropdown ul li.active a:hover:after {
    --scale: 0.9;
    opacity: 1;
  }

  .f-dropdown ul li:first-child a {
    border-radius: 3px 3px 0 0;
  }

  .f-dropdown ul li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  .f-dropdown.disabled {
    opacity: 0.7;
  }

  .f-dropdown.disabled > span {
    cursor: not-allowed;
  }

  .f-dropdown.filled > span {
    color: #000;
  }

  .f-dropdown.open {
    z-index: 15;
  }

  .f-dropdown.open > span {
    border-color: #aaa;
  }

  .f-dropdown.open > span:before,
  .f-dropdown.open > span:after {
    background: #000;
  }

  .f-dropdown.open > span:before {
    transform: scale(0.96, 0.8) rotate(-50deg);
  }

  .f-dropdown.open > span:after {
    transform: scale(0.96, 0.8) rotate(50deg);
  }

  .f-dropdown.open ul {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 12px);
    transition: opacity 0.3s ease, visibility 0.3s ease,
      transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  }

  .f-group {
    max-width: 250px;
    margin: 0 auto;
    text-align: left;
  }

  .f-group select {
    width: 100%;
  }

  .f-control {
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    border: #ccc 1px solid;
    border-radius: 6px;
    padding: 8px 12px;
    outline: none;
  }

  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }

  .nav-tabs-buy-sell-section {
    border: 1px solid #e6e8ea;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell-section .nav-tabs .nav-link:hover {
    border-color: #e9ecef00 #e9ecef00 #dee2e600;
    isolation: isolate;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: rgb(14, 203, 129) !important;
    color: #ffffff !important;
    margin: 2px;
    border-radius: 6px;
  }

  .nav-tabs-buy-sell .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 0px solid transparent !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-tabs-buy-sell .nav-tabs {
    border: 0px solid #0582ff !important;
  }

  .nav-tabs-buy-sell .nav-link {
    display: block;
    padding: 0.3rem 0rem !important;
    color: inherit !important;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .nav-pills-buy-sell .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0rem;
  }

  .nav-pills-buy-sell .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: transparent !important;
    color: #f0b90b !important;
    border-bottom: 2px solid #f0b90b;
  }

  .nav-pills-buy-sell .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: inherit !important;
    text-decoration: none;
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .hero-top-section-text-1 {
    font-size: 22px;
  }

  .advantages-p2p-section-text-1 {
    font-size: 22px;
    font-weight: 400;
  }

  .hero-top-section-text-2 {
    font-size: 10px;
    width: 100%;
    text-align: center;
    margin: auto;
    font-weight: 200;
    color: rgb(214, 214, 214) !important;
  }

  .advantages-p2p-section-text-2 {
    font-size: 15px;
    font-weight: 200;
    color: rgb(37, 37, 37) !important;
  }

  /* / ==============================Harish-css-start===================================== / */
  .terms-text-3 {
    font-size: 14px;
    font-family: sans-serif;
    margin-bottom: 4px;
  }

  .terms-text-2 {
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .terms-text-1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    font-family: sans-serif;
  }

  .accordian-dot-icons {
    font-size: 20px;
    margin-right: 8px;
  }

  .faq-second-section .accordion-body {
    font-size: 14px;
    font-family: sans-serif;
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button::after {
    background-image: url("../assets/images/accor-plus.png");
  }

  .faq-second-section .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }

  .faq-second-section .accordion-button {
    font-family: sans-serif;
    box-shadow: none !important;
    width: 100%;
    padding: 1rem 1.25rem;
    color: inherit !important;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    background-color: transparent;
  }

  .faq-second-section .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.068);
  }

  .market-place-table-section {
    width: 100% !important;
    overflow: scroll;
  }

  .market-trade-button {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--color-1);
    font-size: 12px;
    padding: 4px 20px;
    border-radius: 4px;
  }

  .market-trade-button:hover {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    color: var(--goldColor);
    font-size: 12px;
    padding: 4px 30px;
  }

  .markets-second-section tbody {
    border: none !important;
  }

  .markets-second-section {
    overflow: scroll;
  }

  .markets-second-section table {
    width: 700px;
  }

  .markets-second-section table {
    font-family: sans-serif;
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 12px;
  }

  .markets-second-section .table > :not(caption) > * > * {
    padding: 0.8rem 0.5rem;
  }

  .markets-second-section table tr {
    font-family: sans-serif;
    border-bottom: 0.065rem solid #dee2e6 !important;
  }

  .markets-second-section table tbody tr:last-child {
    border-bottom: 0 solid #dee2e6 !important;
  }

  .markets-second-section table tbody tr:hover {
    background-color: #f5f8fb;
  }

  .markets-second-section table th {
    /* background-color: var(--btnClr) !important; */
    background-color: var(--primary-color) !important;
    color: var(--primary-text-color) !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
  }

  .markets-second-section table th:first-child {
    border-top-left-radius: 12px;
    padding-left: 14px !important;
  }

  .markets-second-section table th:last-child {
    border-top-right-radius: 12px;
  }

  .markets-second-section table td {
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: none;
    letter-spacing: 0.28px;
    vertical-align: middle;
  }

  .transaction-history-select-section .transaction-form-control {
    display: block;
    width: 100%;
    height: 35px;
    padding-left: 8px;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(112, 122, 138, 0.336);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .custom-dropdown-trans-section .select-dropdown-btn {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.8rem;
    color: inherit;
    background-color: transparent;
    width: 120px !important;
    height: 35px;
    border: 1px solid rgba(112, 122, 138, 0.336) !important;
    border-radius: 4px;
  }

  .custom-dropdown-trans-section .select-dropdown-btn:hover {
    font-size: 0.8rem;
    color: inherit;
    background-color: transparent;
    border: 1px solid rgb(112, 122, 138) !important;
  }

  .transaction-text-1 {
    font-family: sans-serif;
    font-size: 12px;
  }

  .transaction-history-tabs-section .nav-pills .nav-link.active,
  .transaction-history-tabs-section .nav-pills .show > .nav-link {
    color: #000;
    background-color: var(--btnClr);
  }

  .transaction-history-tabs-section .nav-link {
    display: block;
    padding: 0.2rem 0.5rem;
    color: rgb(112, 122, 138);
    text-decoration: none;
    font-size: 12px;
    font-family: sans-serif;
  }

  .trading-page-order-table-details-section tbody {
    border: none !important;
  }

  .trading-page-order-table-details-section .table > :not(caption) > * > * {
    padding: 0.15rem 0.1rem;
  }

  .trading-page-order-table-details-section tr {
    border: 1px solid rgb(238, 240, 242);
    padding: 15px;
  }

  .trading-page-order-table-details-section tbody td {
    font-weight: 500;
    align-items: center;
    font-size: 10px;
  }

  .trading-page-order-table-details-section th {
    font-weight: 500;
    align-items: center;
    font-size: 10px;
    color: rgb(112, 122, 138);
    background-color: rgb(245, 245, 245);
    border: 0px;
    padding: 4px 8px;
    letter-spacing: 0.28px;
    vertical-align: middle;
  }

  .trading-page-order-table-details-section .select-dropdown-btn {
    font-size: 10px;
  }

  .trading-page-order-table-details-section .dropdown-item {
    font-size: 13px !important;
  }

  .trading-page-order-table-details-section .table thead {
    display: none;
  }

  .trading-page-order-table-details-section .table,
  .trading-page-order-table-details-section .table tbody,
  .trading-page-order-table-details-section .table tr,
  .trading-page-order-table-details-section .table td {
    display: block;
    width: 100%;
  }

  .trading-page-order-table-details-section .table tr {
    margin-bottom: 15px;
  }

  .trading-page-order-table-details-section .table td {
    padding-left: 50%;
    text-align: right;
    position: relative;
  }

  .trading-page-order-table-details-section .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    text-align: left;
  }

  .withdraw-crypto-network-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-network-modal .modal-header {
    border: none;
  }

  .withdraw-modal-coin-muted-text-1 {
    color: rgb(71, 77, 87);
  }

  .withdraw-modal-coin-muted-text {
    font-weight: 300;
    font-size: 14px;
    color: rgb(112, 122, 138);
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .withdraw-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 20px 10px !important;
    padding: 5px;
    border-radius: 4px;
  }

  .withdraw-modal-network-sizing:hover {
    background-color: #eee;
  }

  .withdraw-nav-tabs ::placeholder {
    font-size: 15px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: sans-serif;
  }

  .withdraw-nav-tabs .form-control {
    width: 100%;
    padding: 0.375rem 1.2rem !important;
    font-size: 0.9rem;
    height: 48px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: 1px solid #dee2e6;
  }

  .withdraw-crypto-modal-text-4 {
    font-size: 14px;
    font-weight: 200;
    margin-left: 15px;
  }

  .withdraw-crypto-modal-text-3 {
    font-size: 14px;
    font-weight: 200;
    font-family: sans-serif;
  }

  .withdraw-crypto-list-items {
    padding: 10px 20px;
    margin-bottom: 4px;
    border-radius: 4px;
  }

  .withdraw-crypto-list-items:hover {
    background-color: #eee;
  }

  .withdraw-crypto-modal-text-2 {
    font-size: 14px;
    font-weight: 200;
    position: relative;
    top: -4px;
    font-family: sans-serif;
  }

  .withdraw-crypto-modal-text-1 {
    font-size: 18px;
    position: relative;
    top: 4px;
  }

  .withdraw-crypto-modal-images {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .withdraw-crypto-modal-list {
    list-style: none;
    padding: 0px;
  }

  .withdraw-crypto-modal .modal-title {
    font-size: 18px;
  }

  .withdraw-crypto-modal .modal-header {
    border: none;
  }

  .select-network-text-1 {
    font-size: 14px;
  }

  .modal-2-overflow-scroll {
    overflow: hidden auto !important;
    height: 232px;
  }

  .deposit-modal-list-sizing {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    cursor: pointer;
  }

  .deposit-modal-row-sizing {
    margin: 3px 9px 3px 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-network-sizing {
    margin: 3px 9px 3px 0px;
    padding: 5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: auto;
    flex-shrink: 0;
  }

  .deposit-modal-coin-heading {
    box-sizing: border-box;
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
  }

  .deposit-modal-coin-muted-text {
    color: rgb(71, 77, 87);
    font-size: 12px;
    margin-bottom: 0px;
  }

  .deposit-modal-coin-images {
    width: 25px !important;
    height: 25px !important;
  }

  #myUL2 {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #myUL2 li a {
    margin-bottom: 1px;
    background-color: transparent;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    text-decoration: none;
    color: black;
    display: block;
  }

  #myUL2 li a:hover:not(.header) {
    background-color: #eee;
  }

  #myInput2 {
    margin: 0 auto;
    width: 100%;
    height: 45px;
    padding: 2px 20px;
    font-size: 1rem;
    border-radius: 15px;
    outline: none;
    margin-bottom: 15px;
  }

  .deposit-crypto-modal .modal-content {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    position: relative;
    box-shadow: rgb(20 21 26 / 16%) 0px 8px 16px,
      rgb(71 77 87 / 16%) 0px 16px 32px, rgb(20 21 26 / 10%) 0px 0px 1px;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    animation: 0.3s ease-in-out 0s 1 normal none running animation-1wqz9z0;
  }

  .deposit-crypto-modal .modal-header {
    border-bottom: none;
  }

  .wallet-info-icon-1 {
    font-size: 12px;
    margin-left: 4px;
  }

  .qr-code-tooltip-image {
    width: 100%;
  }

  .tooltip-text-3 {
    font-size: 15px;
    font-weight: 700;
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .tooltip-text-2 {
    font-size: 14px;
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  .tooltip-text-1 {
    font-size: 11px;
    font-family: sans-serif;
  }

  .tooltip-text-Theme {
    padding-bottom: 10px !important;
    width: 250px;
    color: #fff !important;
    background-color: rgb(0, 0, 0) !important;
  }

  .tooltip-text-Theme::after {
    background-color: rgb(0, 0, 0) !important;
  }

  .no-record-image {
    width: 120px;
  }

  .no-records-found td {
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
  }

  .table-view-all-deposit-button-1,
  .table-view-all-deposit-button-2 {
    background-color: transparent;
    color: var(--btnClr);
    text-decoration: underline;
    border: none;
    font-size: 12px;
  }

  .dest-addres-link {
    margin-left: 4px;
    font-size: 12px;
  }

  .dest-addres-ellipsis {
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .deposit-fourth-section .table > :not(caption) > * > * {
    padding: 0.55rem 0.55rem;
  }

  .deposit-fourth-section .table > :not(:first-child) {
    border-top: 0px solid currentColor;
  }

  .deposit-fourth-section th {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
    color: rgb(112, 122, 138);
    background-color: rgb(245, 245, 245);
    border: 0px;
  }

  .deposit-fourth-section th:first-child {
    border-top-left-radius: 4px;
  }

  .deposit-fourth-section th:last-child {
    border-top-right-radius: 4px;
  }

  .deposit-fourth-section tr {
    font-weight: 500;
    align-items: center;
    font-size: 10px;
    padding: 20px 10px;
  }

  .deposit-fourth-section .table thead {
    display: none;
  }

  .deposit-fourth-section .table,
  .deposit-fourth-section .table tbody,
  .deposit-fourth-section .table tr,
  .deposit-fourth-section .table td {
    display: block;
    width: 100%;
  }

  .deposit-fourth-section .table td {
    text-align: right;
    position: relative;
    border-bottom: 1px solid rgb(234, 236, 239);
  }

  .deposit-fourth-section .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    text-align: left;
  }

  .deposit-fourth-section .table .no-records-found td {
    text-align: center;
    /* position: relative; */
  }

  .deposit-text-11 {
    font-size: 20px;
  }

  .deposit-text-9 {
    font-weight: 800;
  }

  .deposit-text-8 {
    text-decoration: underline;
  }

  .deposit-text-7 {
    color: var(--btnClr);
  }

  .deposit-icon-1 {
    margin-right: 10px;
    font-size: 22px;
    color: rgb(112, 122, 138);
  }

  .deposit-text-6 {
    font-size: 12px;
  }

  .deposit-text-66 {
    font-size: 13px;
  }

  .deposit-select-coin-section {
    width: 100%;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(234, 236, 239);
    cursor: pointer;
  }

  .deposit-select-coin-section:hover {
    border-color: var(--btnClr);
  }

  .deposit-text-5 {
    font-size: 13px;
    font-weight: 300;
    color: rgb(112, 122, 138);
    font-family: sans-serif;
  }

  .deposit-text-4 {
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }

  .deposit-logo-image {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .deposit-third-right-section {
    width: 100%;
  }

  /* .deposit-third-left-section {
    width: 250px !important;
  } */

  .deposit-third-section {
    padding-top: 10px;
    padding-bottom: 10px;
    /* max-height: 760px; */
  }

  .deposit-text-3 {
    font-weight: 300;
    font-size: 13px;
    font-family: sans-serif;
  }

  .deposit-text-33 {
    font-weight: 300;
    font-size: 13px;
    font-family: sans-serif;
  }

  .deposit-crupto-explain-image {
    display: none;
  }

  .deposit-text-2 {
    font-weight: 300;
    font-size: 14px;
    font-family: sans-serif;
  }

  .crypto-workflow-section-close-button {
    font-size: 24px;
    cursor: pointer;
  }

  .deposit-second-section {
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .base-timeline {
    list-style-type: none;
    counter-reset: number;
    position: relative;
    display: block;
    z-index: 2;
    width: 100%;
  }

  .base-timeline__item {
    position: relative;
    /* display: inline-block; */
    /* width: calc(100% / 4 - 5px); */
  }

  .base-timeline__item::before {
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: number;
    content: counter(number) "";
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: var(--btnClr);
    color: white;
    font-weight: bold;
    transition: all 0.6s ease-in-out;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .base-timeline__item--data::before {
    width: 30px;
    height: 30px;
  }

  .deposit-fiat-button {
    background-color: rgb(242, 243, 245);
    padding: 6px 10px;
    border: none;
    font-size: 12px;
  }

  .deposit-fiat-button:hover {
    background-color: transparent;
  }

  .deposit-text-1 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .deposit-back-button-icon {
    font-size: 28px;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
    position: relative;
    top: -3px;
  }

  .deposit-back-button-icon:hover {
    background-color: rgb(242, 243, 245);
  }

  .deposit-hero-section {
    background-color: rgb(250, 250, 250);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .deposit-page-top-banner {
    margin-top: 65px;
  }

  .trading-page-order-tabs-section {
    height: 120px;
    margin: auto;
    text-align: center;
  }

  .trade-bottom-login-text-1 {
    color: gray;
    font-size: 14px;
  }

  .activities-button-1 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 13px;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    border: none !important;
    color: #fff;
    width: 40px;
  }

  .activities-text-4 {
    position: relative;
    top: -4px;
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
    margin: 0px;
  }

  .activities-text-3 {
    color: rgb(112, 122, 138);
  }

  .activities-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .activities-text-1 {
    font-size: 14px;
  }

  .trading-table-right-activities-section {
    margin-top: 5px;
    border-top: 1px solid rgb(238, 240, 242);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .trading-table-right-bottom-section {
    height: 290px !important;
    overflow-y: scroll;
  }

  .paired-trade-table-section {
    /* margin-left: 10px !important;
    margin-right: 10px !important; */
    /* padding-left: 10px; */
    padding-bottom: 0px;
    border-top: 1px solid rgb(238, 240, 242);
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .paired-trade-table-section .nav-pills .nav-link.active,
  .paired-trade-table-section .nav-pills .show > .nav-link {
    color: var(--color-1) !important;
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid var(--primary-color);
  }

  .paired-trade-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: #727581 !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 10px;
    border-radius: 0px;
    padding-bottom: 8px;
  }

  .trading-table-right-inner-section {
    height: 310px !important;
    overflow-y: scroll;
  }

  .paired-text-3 {
    box-sizing: border-box;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    margin-left: 4px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(254, 246, 216);
  }

  .pared-fav-button {
    color: rgb(112, 122, 138);
    margin-bottom: 4px;
    margin-right: 1px;
  }

  .trading-table-right-section .flex-row {
    height: 22px;
  }

  .trading-table-right-section {
    /* width: 95%; */
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin: auto;
  }

  .paired-text-2 {
    font-weight: 500;
    font-size: 10px;
    padding: 0px !important;
  }

  .paired-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .mobile-justify-content {
    justify-content: space-around;
  }

  .offcanvas .dropdown-toggle::after {
    position: absolute !important;
    top: 20px !important;
    right: 35px !important;
  }

  /* .paired-table-1 {
    width: 20%;
  } */

  .paired-table-2 {
    /* width: 20%; */
    text-align: end;
  }

  .paired-table-3 {
    /* width: 20%; */
    text-align: end;
  }

  .paired-price-table-section {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .paired-price-table-section .nav-pills .nav-link.active,
  .paired-price-table-section .nav-pills .show > .nav-link {
    color: var(--btnClr) !important;
    background: transparent;
    font-weight: 700;
  }

  .paired-price-table-section .nav-link {
    display: block;
    padding: 0rem 0rem;
    font-size: 12px !important;
    color: rgb(112, 122, 138) !important;
    text-decoration: none;
    font-weight: 600 !important;
    letter-spacing: 0px !important;
    margin-right: 6px;
  }

  /* .trading-page-right-section {
    width: 260px !important;
  } */

  .trade-search-section {
    width: 90%;
    margin: auto;
    border-radius: 2px;
    line-height: 1.5;
    background-color: rgb(242, 243, 245);
  }

  .trade-search-btn {
    border: none;
    color: rgb(112, 122, 138);
    background: transparent;
  }

  .trade-search-section .form-control {
    padding: 0.215rem 0.15rem;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .trade-search-section .form-control:focus {
    background-color: transparent;
  }

  .progress-bar {
    position: absolute;
    right: 0px;
    z-index: 1;
    height: 20px;
  }

  .ask-bar {
    background-color: rgba(255, 0, 0, 0.07);
  }

  .bid-bar {
    background-color: rgba(255, 0, 0, 0.07);
  }

  .progress-bar-bg-color {
    position: relative;
  }

  .progress-bar-bg-color .flex-row {
    height: 25px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center !important;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.26px;
  }

  .progress-table-1 {
    width: 33%;
  }

  .progress-table-2 {
    width: 33%;
    text-align: right;
  }

  .progress-table-3 {
    width: 33%;
    text-align: end;
  }

  .orderbook-text-1 {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: #727581;
    padding-bottom: 0rem !important;
  }

  .trailing-delta-btn {
    background-color: transparent;
    font-size: 0.8rem;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
  }

  .custom-dropdown,
  .dropdown-menu {
    box-shadow: none !important;
  }

  .custom-dropdown .dropdown-item {
    color: inherit;
    font-size: 14px;
  }

  .select-dropdown-btn {
    border: none !important;
    font-size: 0.9rem;
    color: rgb(112, 122, 138);
    background-color: transparent;
  }

  .select-dropdown-btn:hover {
    border: none !important;
  }

  .limit-market-section-bottom .select-amount-button {
    background-color: transparent;
    padding: 0px;
    border: none;
    color: rgb(112, 122, 138);
    font-size: 0.9rem;
  }

  .limit-market-section-bottom .dropdown-item {
    padding: 0.5rem 1rem;
    background-color: transparent;
  }

  .limit-market-section-bottom .dropdown-item:hover {
    background-color: rgb(234, 236, 239);
  }

  .limit-market-section-bottom .dropdown-menu {
    border-radius: 0rem !important;
    padding: 0px !important;
    left: -20px !important;
  }

  .trade-text-15 {
    color: var(--btnClr);
  }

  .trade-buy-sell-button-1 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(230, 232, 234);
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }

  .trade-buy-sell-button-11 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(14, 203, 129);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .trade-buy-sell-button-111 {
    padding: 0.665rem 0.465rem;
    background-color: rgb(246, 70, 93);
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #fff;
  }

  .total-value-price-section {
    /* position: relative;
    top: -30px; */
    font-size: 11px;
  }

  .percentage-line-section {
    height: 5px;
    width: 100%;
    border-radius: 4px;
    align-self: center;
  }

  .percentage-display-section {
    background-color: var(--btnClr) !important;
    height: 32px;
    width: 32px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 6px #aaa;
    outline: none !important;
  }

  .trade-text-14 {
    font-size: 0.8rem;
  }

  .trade-text-13 {
    font-size: 0.8rem;
    color: rgb(112, 122, 138);
  }

  /* ::placeholder {
    color: #212529 !important;
  } */

  /* input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */

  .limit-market-section-bottom .form-control {
    display: block;
    width: 100%;
    padding: 0rem 0.75rem;
    font-size: 0.8rem;
    float: right;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    -webkit-appearance: none;
    text-align: right;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .limit-button-width {
    width: 40px !important;
    text-align: center;

    margin-left: 6px;
  }

  .limit-price-form {
    align-items: center;
    border: 1px solid rgba(230, 232, 234, 0.1);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 0.365rem 0.465rem;
    background-color: rgba(230, 232, 234, 0.6);
  }

  .limit-price-form:hover {
    border: 1px solid var(--btnClr);
  }

  .limit-price-form:focus {
    border: 1px solid var(--btnClr) !important;
  }

  .trade-text-12 {
    font-size: 12px;
    color: rgb(0, 0, 0);
  }

  .trade-text-11 {
    font-size: 12px;
    color: rgb(112, 122, 138);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active {
    border-top: 2px solid var(--btnClr) !important;
    border-bottom: 0px solid transparent;
    color: rgb(30, 35, 41);
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-tabs .nav-item.show .nav-link,
  .limit-market-section-bottom .nav-tabs .nav-link.active:hover {
    border-top: 2px solid var(--btnClr);
    border-bottom: 0px solid transparent;
    border-left: 1px solid rgb(238, 240, 242);
    border-right: 1px solid rgb(238, 240, 242);
    border-color: rgb(238, 240, 242) rgb(238, 240, 242) rgb(238, 240, 242);
  }

  .limit-market-section-bottom .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    color: rgba(112, 122, 138, 0.815);
    letter-spacing: 0.4px;
    /* width: 130px; */
    font-size: 13px;
    padding: 10px 20px;
    font-weight: 500;
  }

  .limit-market-section-bottom .nav-link:hover {
    border-color: transparent;
    outline: none;
  }

  .trading-table-left-section {
    max-height: 450px !important;
    overflow-x: scroll;
  }

  .trading-table-left-recent-trade-height {
    height: 340px !important;
    overflow: hidden;
  }

  /* .list-hover-tooltip:hover {
    background-color: rgba(116, 116, 116, 0.205);
    border-bottom: 1px solid rgba(173, 173, 173, 0.205);
  } */

  #tradingviewwidget {
    width: 100% !important;
    height: 100% !important;
  }

  .placeorder-container {
    height: 100% !important;
  }

  /* .trading-inner-right-section {
    width: 180px;
  } */

  .trading-inner-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* width: 260px !important; */
  }

  .trading-page-left-section {
    border-right: 1px solid rgb(238, 240, 242);
    /* width: 940px !important; */
    overflow: hidden;
  }

  .trade-text-10 {
    font-size: 12px;
    font-weight: 100;
    font-family: "Poppins", sans-serif;
  }

  .trade-icon-2 {
    font-size: 14px;
    position: relative;
    top: -1px;
  }

  .trade-text-9 {
    font-size: 16px;
    margin-right: 4px;
  }

  .hide-content-only {
    visibility: hidden;
  }

  .trading-page-top-section-3 .table > :not(caption) > * > * {
    padding: 0rem 0rem;
  }

  .trading-page-top-section-3 th {
    font-weight: 500;
    align-items: center;
    font-size: 11px;
    color: rgb(112, 122, 138);
    padding-bottom: 0rem !important;
  }

  .trading-page-top-section-3 tr {
    font-weight: 500;
    align-items: center;
    font-size: 12px;
  }

  .trading-page-top-section-3 {
  }

  .trading-page-top-section-3 th:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(2) {
    text-align: end;
  }

  .trading-page-top-section-3 th:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-3 td:nth-child(3) {
    text-align: end;
  }

  .trading-page-top-section-2 .form-check {
    min-height: 0rem;
    margin-bottom: 0rem;
  }

  .trading-page-top-section-2 .dropdown-item {
    padding: 0rem 1rem;
  }

  .trading-page-top-section-2 .dropdown-menu {
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(24 26 32 / 10%) 0px 0px 1px, rgb(71 77 87 / 8%) 0px 7px 14px,
      rgb(24 26 32 / 8%) 0px 3px 6px;
    border-radius: 4px;
    transform: translate(-10rem, 28px) !important;
    font-size: 11px;
  }

  .trading-page-top-section-2 .dropdown-toggle::after {
    margin-left: 0em;
    content: none;
  }

  .trading-page-top-section-2 {
    padding: 6px 0px;
  }

  .average-icon-display-1 {
    background-color: transparent;
    color: rgb(198, 202, 208);
    border: none;
  }

  .trading-page-top-section-2 .form-select {
    display: block;
    width: 100%;
    padding: 0.075rem 1.05rem 0.075rem 0.25rem;
    background-position: right 0.25rem center;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  .trading-page-top-section-2 .form-select option {
    font-size: 0.875rem;
  }

  .trading-page-top-section-2 .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.675rem;
    border-radius: 0.2rem;
    text-align: center;
  }

  .trading-page-top-section-1 {
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    /* width: 920px; */
  }

  .order-icon-3 {
    width: 16px;
    height: 16px;
  }

  .order-icon-2 {
    width: 16px;
    height: 16px;
  }

  .order-icon-1 {
    width: 16px;
    height: 16px;
  }

  .trading-page-section-5 {
    margin-right: 20px;
  }

  .trading-page-section-4 {
    padding-right: 15px;
  }

  .trade-text-8 {
    font-size: 11px;
    color: rgb(112, 122, 138);
    margin-bottom: 0px;
  }

  .trading-page-section-3 {
    padding-right: 15px;
    margin-right: 10px;
  }

  .trade-text-7 {
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    white-space: nowrap;
    letter-spacing: 0.24px;
    margin-bottom: 0px;
  }

  .trade-text-6 {
    color: #727581;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    white-space: nowrap;
    margin-bottom: 6px;
    letter-spacing: 0.2px;
  }

  .trading-page-section-2 {
    border-right: 1px solid rgb(238, 240, 242);
    padding-right: 15px;
    margin-right: 10px;
    font-family: "Poppins", sans-serif;
    text-align: start;
  }

  .trade-text-5 {
    font-size: 15px;
    font-weight: 1000;
    margin-bottom: 0px;
    letter-spacing: 0.045em;
  }

  .trade-text-4 {
    font-size: 12px;
    margin-bottom: 2px;
    font-weight: 1000;
  }

  .trading-page-section-1 {
    border-right: 1px solid #dbdde6;
    padding-right: 9px;
    margin-right: 9px;
    margin-bottom: 18px;
  }

  .trading-page-top-section-1 {
    /* margin: 10px 0px; */
    padding: 18px 0px;
    border-bottom: 1px solid rgb(238, 240, 242);
    /* overflow: scroll; */
  }

  .trade-text-3 {
    margin-left: 6px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 9px;
    border-radius: 2px;
    font-weight: 400;
    color: rgb(201, 148, 0);
    background-color: rgb(249, 244, 226);
    position: relative;
    top: -2px;
  }

  .trade-icon-1 {
    font-size: 11px;
    margin-right: 2px;
  }

  .trade-text-2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);
    text-decoration: underline;
    font-size: 9px;
  }

  .trade-text-1 {
    font-size: 0.86rem;
    font-weight: bolder;
    margin-bottom: 3px;
    margin-bottom: 3px;
  }

  .trading-page-box {
    border: 5px solid #f5f8fb;
    font-family: "Roboto", sans-serif;
    /* width: 1200px; */
  }

  .orderbook-grid-tabs .nav-link {
    display: block;
    padding: 0.2rem 0.3rem;
    margin-left: 2px;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    border: 1px solid transparent;
  }

  .orderbook-grid-tabs .nav-pills .nav-link.active,
  .orderbook-grid-tabs .nav-pills .show > .nav-link {
    border: 1px solid #dbdde6;
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    background-color: #f5f8fb;
  }

  .orderbook-table-heading-section {
    /* border-bottom: 1px solid #dbdde6; */
    /* border-top: 1px solid #dbdde6; */
    height: 30px !important;
    vertical-align: middle !important;
    align-items: center !important;
  }

  .spot-page-top-banner {
    padding-top: 65px;
    padding-bottom: 100px;
  }

  .img-fluid-size {
    height: 35px;
    width: 35px;
  }

  .text-spot {
    font-size: 11px !important;
  }

  .place-order-buy-button {
    color: #fff;
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .place-order-login-button {
    border: 0px;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    border: none;
    background-color: transparent;
  }

  .place-order-buy-sell-percentage-tabs .nav-link {
    display: block;
    padding: 0.375rem 0.75rem;
    color: #727581;
    text-decoration: none;
    font-size: 13px;
    border: 1px solid #dbdde6;
    width: 90% !important;
  }

  .place-order-section-top .form-control {
    padding: 0.675rem 0.75rem;
    font-size: 12px;
    font-weight: 400;
    color: inherit;
    background-color: transparent;
    border: 1px solid #dbdde6;
    border-right: 0px;
    border: none;
  }

  .place-order-section-top .input-group-text {
    color: var(--input-box-text-color);
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.26px;
    font-weight: 400;
    background-color: transparent;
    border: none;
    border-left: 0px;
  }

  .token-container {
    border: none !important;
  }

  .mobile-justify-content-center {
    display: flex;
    justify-content: center;
  }

  .mobile-justify-content-center img {
    width: 20% !important;
    height: 100% !important;
  }

  .mobile-justify-end {
    display: flex;
    justify-content: end;
  }

  .mobile-justify-end > .color-green-price {
    font-size: 12px;
  }

  .mobile-token-container {
    display: flex;
    justify-content: center;
  }

  .select-dropdown-btn-buysell {
    border: 1px solid #dbdde6;
    cursor: pointer;
    height: 35px;
    display: flex;
    padding: 0px 16px;
    font-size: 14px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    align-items: center;
    font-weight: 500;
    line-height: 21px;
    border-radius: 2px;
    justify-content: space-between;
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link.active,
  .place-order-buy-sell-tabs .nav-pills .show > .nav-link {
    color: var(--input-box-text-color);
    background-color: #14b269;
  }

  .place-order-buy-sell-tabs .nav-pills .nav-link-sell.active,
  .place-order-buy-sell-tabs .nav-pills .show > .nav-link {
    color: var(--input-box-text-color);
    background-color: #ff4b5e;
  }

  .place-order-buy-sell-tabs .nav-pills {
    border: 1px solid #dbdde6;
    background-color: #f5f8fb;
  }

  .place-order-buy-sell-tabs .nav-link {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.055rem;
    border-radius: 2px;
    display: block;
    padding: 0.5rem 1rem;
    color: #727581;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .fixed-top-padding-top {
    padding-top: 150px;
  }

  .footer-bottom-section {
    background-color: rgb(37, 23, 74);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: "Roboto", sans-serif;
  }

  .footer-social-icons a {
    margin-right: 10px;
    color: #fff;
    padding: 8px;
    transition: all 0.5s ease-in-out;
    border-radius: 50%;
    background-color: #3a3150;
    padding-bottom: 11px;
    padding-top: 6px;
    position: relative;
    font-size: 16px !important;
    background: rgb(37, 23, 74);
  }

  .footer-section a {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
    letter-spacing: 0.5px;
    font-family: "Roboto", sans-serif;
  }

  .footer-section h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
    color: rgb(114, 117, 129);
    letter-spacing: 0.5px;
    font-family: "Roboto", sans-serif;
  }
  
  .footer-section {
    padding-top: 50px;
    padding-bottom: 30px;
    background: var(--homepageBgLinear);
  }
  
  .ready-text-2 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .ready-text-1 {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-2 {
    font-family: sans-serif;
    color: inherit;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .benefits-text-1 {
    color: #242b3a;
    font-size: 22px;
    font-weight: 700;
  }

  .benefits-icons-1 {
    background-color: #00dbaf44;
    margin-right: 20px;
    border-radius: 3px;
    padding: 8px;
    font-size: 50px;
    color: var(--btnClr);
  }

  .top-banner-eight-section .card {
    height: 100%;
    padding: 20px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 15px 50px 0px rgb(11 11 11 / 8%);
    box-shadow: 0px 15px 50px 0px rgb(11 11 11 / 8%);
    border: none;
    transition: 1.5s;
  }

  .staking-image-banner {
    width: 80%;
    float: left;
  }

  .app-play-button {
    width: 167px;
    height: 60px;
  }

  .top-inner-fifth-bg-image {
    background-image: url("./images/glow1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .banner-image-bg {
    background-image: url("./images/glow.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .top-inner-fifth-section {
    background: linear-gradient(160deg, #27187b, #16014e);
    color: #fff;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 40px;
    border-radius: 6px;
  }

  .top-banner-seventh-section {
    background: var(--homepageBgLinear);
    color: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
    /* clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 89%); */
  }

  .top-banner-fourth-section {
    background: var(--homepageBgLinear);
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .inner-third-section {
    border-radius: 8px;
    background-color: #fff;
    padding: 10px 40px;
  }

  .view-more-icon {
    position: relative;
    top: -2px;
    margin-left: 4px;
  }

  .table-view-all-button-1,
  .table-view-all-button-2 {
    background-color: transparent;
    color: var(--btnClr);
    text-decoration: underline;
    border: none;
    font-size: 14px;
  }

  .top-banner-third-section table tbody tr:nth-child(-n + 6) {
    display: table-row;
    width: 100%;
  }

  .top-banner-third-section table tbody tr {
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr:hover {
    box-shadow: 0px 4px 8px rgb(220 226 238 / 40%);
    transition: all 0.3s ease-in-out;
  }

  .top-banner-third-section table tbody tr {
    display: none;
  }

  .table-view-all-button-2 {
    display: none;
  }

  .top-banner-third-section .table {
    font-family: sans-serif;
  }

  /* .table tbody tr:nth-child(1) {
    display: none;
  } */
  .top-banner-third-section .table thead tr th {
    color: var(--primary-text-color) !important;
    background-color: var(--primary-color) !important;
    padding: 8px 8px;
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
    border-bottom: none;
  }

  .top-banner-third-section .table tbody td {
    padding: 7px 8px !important;
    font-size: 10px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.32px;
  }

  .top-banner-third-section .table tbody img {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }

  .top-banner-third-section .table thead {
    border-bottom: 1px solid #edeef2;
  }

  .top-banner-third-section .table thead th:last-child {
    text-align: end;
  }

  .top-banner-third-section .table tbody td:last-child {
    text-align: end;
    padding-right: 10px !important;
  }

  .top-banner-third-section .table thead th {
    width: 25%;
  }

  .top-banner-third-section .table tbody {
    border: 2px solid #edeef2;
  }

  .top-banner-third-section .table > :not(:first-child) {
    border-top: 0px;
  }

  .top-banner-third-section .nav-link {
    color: #727581;
    opacity: 1;
    padding: 0px 10px;
    font-size: 12px;
    min-width: 70px;
    min-height: 25px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .top-banner-third-section .nav-pills .nav-link.active,
  .top-banner-third-section .nav-pills .show > .nav-link {
    color: var(--primary-text-color) !important;
    background-color: var(--primary-color);
    font-weight: 600;
  }

  .market-trade-tabs {
    border-radius: 2px;
    background-color: rgb(200 205 223 / 16%);
    width: fit-content;
  }

  .banner-image-1 {
    width: 6px;
    position: relative;
    top: -4px;
    left: -12px;
  }

  .banner-text-7 {
    font-size: 1rem;
    font-weight: 100;
    color: #727581;
    letter-spacing: 1px;
  }

  .banner-text-6 {
    font-size: 1.4rem;
    font-weight: 100;
    letter-spacing: 2px;
  }

  .banner-text-5 {
    font-size: 1.1rem;
    font-weight: 100;
    letter-spacing: 1px;
  }

  .banner-text-4 {
    font-size: 2.2rem;
    font-weight: 600;
  }

  .banner-text-3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px !important;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet-active {
    background: var(--btnClr) !important;
  }

  .swiper-pagination-bullet {
    width: 25px !important;
    border-radius: 12px !important;
    height: 6px !important;
  }

  .main-banner-background-styling {
    background: var(--homepageBgLinear);
    color: #fff;
    /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 94%); */
    padding: 5vh;
    margin-bottom: 5vh;
  }

  .banner-top-icons-1 {
    margin-left: 6px;
    font-size: 14px;
    margin-bottom: 2px;
  }

  .banner-top-button-4 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 15px 20px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    background: transparent;
    border: 1px solid var(--btnClr);
    color: var(--btnClr) !important;
  }

  .banner-top-button-3 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
  }

  .banner-top-button-2 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
  }

  .banner-top-button-login {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 5px 10px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 10px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
    border: none;
  }

  .click-here-button {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 5px 20px;
    position: relative;
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
    border: none;
  }

  .banner-top-button-copy {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 40px;
    position: relative;
    border-radius: 2em;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
    border: none;
  }

  .banner-top-button-reset {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 40px;
    position: relative;
    border-radius: 2em;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background-color: rgb(194, 194, 194);
    border: none;
  }

  .banner-top-button-copy-full {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 40px;
    position: relative;
    border-radius: 2em;
    font-weight: 500;
    font-size: 16px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
    border: none;
  }

  .banner-top-button-1 {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    position: relative;
    border-radius: 52px;
    font-weight: 500;
    font-size: 12px;
    background: #20fdee;
    background: linear-gradient(to right, var(--btnClr), #0478e9);
  }

  .banner-text-2 {
    font-size: 0.75rem;
    font-weight: 100;
    letter-spacing: 1px;
  }

  .banner-text-1 {
    font-size: 2.6rem;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .top-banner-hero-section {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  #classy-navbar-mobile {
    /* background-color: #11003b; */
    background-color: var(--navBackground);
  }

  .earn-header,
  .earn-content {
    text-align: center;
  }

  .under-line-effect {
    display: inline-block;
    position: fixed;
    margin-top: 70px;
    opacity: 0.7;
  }

  .earn-banner-container {
    background-image: none;
  }

  .nav-register-button {
    color: var(--primary-text-color) !important;
    width: 90%;
    margin-top: 25px;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    justify-content: center;
    background-color: var(--primary-color);
  }

  #classy-navbar-mobile .nav-link {
    /* box-shadow: none !important; */
    font-size: 14px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    letter-spacing: 0.08em;
  }

  .navbar-nav li:hover > ul.dropdown-menu {
    display: block;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
  }

  .dropdown-submenu2 > .dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -6px;
  }

  .dropdown-menu a {
    font-size: 0.9rem !important;
  }

  .footer-social-desktop {
    display: none;
  }

  #classy-navbar-mobile .dropdown-menu {
    min-width: 22rem;
    padding: 10px 10px;
  }

  .top-nav-dropdown-item-icons-1 {
    font-size: 18px;
    margin-right: 20px;
  }

  .top-nav-dropdown-item-icons-2 {
    color: transparent;
  }

  .top-nav-dropdown-item-text-1 {
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 0px;
    font-family: "Roboto", sans-serif;
  }

  .top-nav-dropdown-item-text-2 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    position: relative;
    top: -3px;
    color: #707a8a;
    font-family: "Roboto", sans-serif;
  }

  .dropdown-item-hover {
    padding: 10px 20px;
  }

  .navbar-top-hover-dropdown .dropdown-item-hover:hover {
    border-radius: 4px;
  }

  .themes-dropdown-text-1 {
    font-size: 18px;
    margin-left: 20px;
  }

  .mode-image-dropdown-1,
  .mode-image-dropdown-2,
  .mode-image-dropdown-3,
  .mode-image-dropdown-4,
  .mode-image-dropdown-5,
  .mode-image-dropdown-6 {
    width: 40px;
    margin-right: 8px;
  }

  .switch-modes-section {
    padding: 10px 18px;
  }

  .switch-modes-section label {
    font-size: 13px;
    font-family: "Roboto", sans-serif;
  }

  #classy-navbar-mobile .form-check-input:checked[type="radio"] {
    background-image: none;
  }
}

/* ===================================COMMON-STARTS===================================================== */
.dropdown-menu a {
  display: block;
  font-size: 0.9rem;
}

/* ====================================TAB VIEW ======================================= */

@media (min-width: 481px) and (max-width: 767px) {
  .price-card-container{
    flex: 30% !important;
  }
}
@media only screen and (max-width: 600px) {
  .price-card-container{
    flex: 100% !important;
  }
}
/* .dropdown-item {
  color: #000000 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: transparent;
  color: #000000 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent;
  color: #000000 !important;
} */

.up-down {
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes up-down {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.p2p-trade-top-section {
  margin-top: 65px;
}

.no-border {
  border: none !important;
}

.f-28 {
  font-size: 28px !important;
}

.referral-page-top-banner-section .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.referral-page-top-banner-section .nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.referral-page-top-banner-section .nav-tabs .nav-item.show .nav-link,
.referral-page-top-banner-section .nav-tabs .nav-link.active {
  color: inherit;
  background-color: var(--btnClr);
  border-color: #dee2e6 #dee2e6 #fff;
}

@media (max-width: 991px) {
  .faq {
    margin-bottom: 30px;
  }

  .faq .card .card-header .faq-title {
    line-height: 26px;
    margin-top: 10px;
  }
}

.p2p-trade-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.p2p-trade-table .p2p-trade-table-tr {
  background-color: #fff;
  border-bottom: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}

.dark-new .p2p-trade-table-thead {
  background-color: var(--btnClr);
}

.p2p-trade-table .p2p-trade-table-thead .p2p-trade-table-tr:first-child {
  border-bottom: 1px solid #e1e5e9;
}

.p2p-trade-table .p2p-trade-table-th,
.p2p-trade-table .p2p-trade-table-td {
  padding-top: 1em;
  padding-bottom: 1rem;
  color: inherit;
  font-size: 14px;
}

.p2p-trade-table .p2p-trade-table-td:nth-child(4) {
  font-size: 14px;
}

.p2p-trade-table .p2p-trade-table-th {
  font-size: 12px;
  color: #76808f;
  font-weight: 400;
}

.p2p-trade-table
  .p2p-trade-table-tbody
  .p2p-trade-table-tr
  .p2p-trade-table-td
  .btn-invoice {
  background-color: rgb(14, 203, 129);
  color: inherit;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
}

.p2p-trade-table
  .p2p-trade-table-tbody
  .p2p-trade-table-tr
  .p2p-trade-table-td
  .btn-invoice-sell {
  background-color: rgba(241, 55, 55);
  color: #fff;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
}

/* 
tbody tr:nth-child(even) {
	background-color: #eee;
}

tbody tr:nth-child(odd) {
	background-color: #fff;
} */

@media screen and (max-width: 600px) {
  .p2p-trade-table {
    border: 0;
  }

  .p2p-trade-table caption {
    font-size: 1.3em;
  }

  .p2p-trade-table .p2p-trade-table-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }

  .p2p-trade-table .p2p-trade-table-tr {
    border-bottom: 5px solid #e1e5e9;
    display: block;
    margin-bottom: 1em;
  }

  .p2p-trade-table .p2p-trade-table-th,
  .p2p-trade-table .p2p-trade-table-td {
    padding: 0.625em;
  }

  .p2p-trade-table .p2p-trade-table-td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }

  .p2p-trade-table .p2p-trade-table-td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }

  .p2p-trade-table .p2p-trade-table-td:last-child {
    border-bottom: 0;
  }

  .p2p-trade-table .p2p-trade-table-td:nth-child(4) {
    font-size: 0.8em;
  }
}

.table-lth-bg {
  color: rgb(201, 148, 0);
  background-color: rgb(254, 246, 216);
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 10px;
}

.table-trade-bg {
  color: rgb(3, 166, 109);
  background-color: rgb(230, 255, 241);
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 10px;
}

.table-data-1 {
  font-size: 14px;
  color: inherit;
}

.table-data-2 {
  font-size: 12px;
  color: inherit;
}

.table-data-3 {
  font-size: 20px;
  color: inherit;
}

.table-data-4 {
  font-size: 11px;
  color: inherit;
}

.table-data-5 {
  font-size: 12px;
  color: inherit;
}

.table-data-6 {
  margin: 0px 4px 0px 0px;
  color: inherit;
  font-size: 11px;
}

.table-data-7 {
  color: rgb(201, 148, 0);
  background-color: rgb(254, 246, 216);
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 11px;
}

.table-data-1 img {
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
  border-radius: 50%;
  margin-right: 4px;
}

.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_asc_disabled:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:after,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc:before,
.p2p-trade-table.dataTable .p2p-trade-table-thead .sorting_desc_disabled:after,
.p2p-trade-table.dataTable
  .p2p-trade-table-thead
  .sorting_desc_disabled:before {
  bottom: 0.5em;
}

/* / ==================================P2P-TRADE-TABLE-END======================== /
/ ==================================P2P-ORDER-TABLE-START====================== / */

.a-tag-link-styling {
  color: royalblue;
  text-decoration: underline;
  box-shadow: none;
}

.a-tag-link-styling:hover {
  color: royalblue;
  text-decoration: none;
}

.order-p2p-trade-table-coin-icon {
  width: 20px;
  height: 20px;
}

.order-p2p-trade-table {
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-wrap: break-word;
}

.order-p2p-trade-table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.order-p2p-trade-table .order-p2p-trade-tr {
  background-color: transparent;
  padding: 0.35em;
  overflow-wrap: break-word;
}

.order-p2p-trade-table .order-p2p-trade-th,
.order-p2p-trade-table .order-p2p-trade-td {
  text-align: center;
}

.order-p2p-trade-table .order-p2p-trade-td {
  font-size: 12px;
  padding: 2em 2em 2em 0.5em;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.order-number-copy-data-css {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-p2p-trade-table .order-p2p-trade-th {
  font-size: 11px;
  color: #252525;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 1.2em;
}

.order-p2p-trade-table thead {
  background-color: var(--btnClr);
}

.order-p2p-trade-table thead th:first-child {
  border-top-left-radius: 2em;
}

.order-p2p-trade-table thead th:last-child {
  border-top-right-radius: 2em;
}

@media screen and (max-width: 600px) {
  .order-p2p-trade-table {
    border: 0;
  }

  .order-p2p-trade-table caption {
    font-size: 1.3em;
  }

  .order-p2p-trade-table .order-p2p-trade-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .order-p2p-trade-table .order-p2p-trade-tr {
    padding: 2px;
  }

  .order-p2p-trade-table .order-p2p-trade-td {
    padding: 1em 1em 1em 0.1em;
  }

  .order-p2p-trade-table .order-p2p-trade-tr {
    border-bottom: 3px solid #ddd;
    display: block;
  }

  .order-p2p-trade-table .order-p2p-trade-td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .order-p2p-trade-table .order-p2p-trade-td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .order-p2p-trade-table .order-p2p-trade-td:last-child {
    border-bottom: 0;
  }
}

/* / ==================================P2P-ORDER-TABLE-END======================== /

/ ==================================P2P-USER-CENTER-BLOCKED-USER-TABLE-START=================================== / */

.p2p-user-center-blocked-user-table table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table caption {
  margin: 0.5em 0 0.75em;
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table tr {
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table th,
.p2p-user-center-blocked-user-table table td {
  align-items: center !important;
}

.p2p-user-center-blocked-user-table table th {
  padding: 13px !important;
  font-size: 12px !important;
  color: inherit;
  font-weight: normal !important;
}

.p2p-user-center-blocked-user-table table td {
  padding: 22px 22px 22px 0 !important;
}

.p2p-user-center-blocked-user-table table th {
  align-items: center !important;
}

@media screen and (max-width: 600px) {
  .p2p-user-center-blocked-user-table table {
    border: 0;
  }

  .p2p-user-center-blocked-user-table table caption {
    font-size: 1.3em;
  }

  .p2p-user-center-blocked-user-table table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .p2p-user-center-blocked-user-table table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .p2p-user-center-blocked-user-table table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right !important;
  }

  .counterparty-text-styling {
    text-align: right !important;
    justify-content: right !important;
  }

  .p2p-user-center-blocked-user-table table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;

    text-transform: uppercase;
  }

  .p2p-user-center-blocked-user-table table td:last-child {
    border-bottom: 0;
  }
}

/* ===================================COMMON-ENDS===================================================== */

/* ===================================LIGHT-CSS-STARTS===================================================== */

/* width */

.light-new
  .navbar-top-hover-dropdown
  .dropdown-item-hover:hover
  .top-nav-dropdown-item-icons-2 {
  color: var(--btnClr);
}

.light-new .dropdown-item {
  color: var(--navFontColor);
}

.light-new ::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

/* Track */
.light-new ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.light-new ::-webkit-scrollbar-thumb {
  background: var(--btnClr);
  border-radius: 10px;
}

.light-new .form-check-input:checked {
  background-color: var(--primary-color);
  border: 3px solid #fff !important;
  outline: 2px solid var(--primary-color);
}

.bg-login-nav,
.btn-col .site-url {
  background-color: var(--background-color-1) !important;
  color: var(--primary-color) !important;
}

.site-url .text-success {
  color: var(--color-1) !important;
}

.site-url .text-white {
  color: var(--color-1) !important;
}

.light-new .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid var(--primary-color);
  background-color: #fff;
}

.light-new .dropdown-menu {
  background-color: var(--navBackground);
  color: var(--navFontColor);
}

.light-new .trading-page-top-section-2 .dropdown-menu {
  background-color: rgba(230, 232, 234, 0.6);
  color: #000 !important;
}

.light-new .themes-dropdown-top-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.795);
}

/* ===================================LIGHT-CSS-ENDS===================================================== */

/* ===================================Dark-New-CSS-STARTS===================================================== */

.dark-new ::placeholder {
  color: #fff;
}

.dark-new .trade-buy-sell-button-1 {
  background-color: transparent;
  color: #fff;
}

.dark-new .trade-text-12 {
  color: #fff;
}

.dark-new .limit-price-form {
  background-color: transparent;
}

.dark-new .limit-market-section-bottom .dropdown-item {
  color: #fff;
}

.dark-new .limit-market-section-bottom .dropdown-item:hover {
  color: #000;
}

.dark-new
  .limit-market-section-bottom
  .nav-tabs
  .nav-link.active
  .select-dropdown-btn {
  color: #fff;
}

.dark-new .limit-market-section-bottom .nav-tabs .nav-link.active {
  background-color: #2c2e37;
  color: #fff !important;
  border-color: rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418)
    rgba(238, 240, 242, 0.418);
}

.dark-new .limit-market-section-bottom .nav-tabs {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trade-search-section {
  background-color: #2c2e37;
}

.dark-new .form-control {
  color: #fff;
}

.dark-new .trading-page-box {
  /* border: 1px solid rgba(238, 240, 242, 0.418); */
}

.dark-new .trading-page-top-section-1 {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .paired-trade-table-section {
  /* border-bottom: 1px solid rgba(238, 240, 242, 0.418); */
}

.dark-new .trading-inner-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trading-page-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .trading-table-right-activities-section {
  border-top: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-new .navbar-top-hover-dropdown .dropdown-item-hover {
  color: #fff;
}

.dark-new .navbar-top-hover-dropdown .dropdown-item-hover:hover {
  background-color: #ebebeb;
  color: #000;
}

.dark-new
  .navbar-top-hover-dropdown
  .dropdown-item-hover:hover
  .top-nav-dropdown-item-icons-2 {
  color: var(--btnClr);
}

/* width */
.dark-new ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dark-new ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.dark-new ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.dark-new .form-check-input:checked {
  background-color: var(--primary-color);
  border: 3px solid #14161a !important;
  outline: 2px solid var(--primary-color);
}

.dark-new .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid var(--primary-color);
  background-color: #14161a;
}

.dark-new .dropdown-menu {
  background-color: var(--navBackground);
  color: #fff;
}

.dark-new .trading-page-top-section-2 .dropdown-menu {
  background-color: #2c2e37;
  color: #fff !important;
}

.dark-new .trading-page-top-section-2 .dropdown-item {
  color: #fff !important;
}

.dark-new .trading-page-top-section-2 .dropdown-item:hover {
  background-color: transparent;
}

.dark-new .themes-dropdown-top-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.26);
}

/* Depsit css */
.dark-new .deposit-hero-section {
  background-color: #14161a;
}

.dark-new .deposit-back-button-icon:hover {
  background-color: rgba(255, 255, 255, 0.158);
}

.dark-new .deposit-fiat-button {
  background-color: rgba(255, 255, 255, 0.158);
  color: #fff;
}

.dark-new .tooltip-text-Theme {
  color: #000 !important;
  background-color: rgb(255, 255, 255) !important;
}

.dark-new .tooltip-text-Theme::after {
  background-color: rgb(255, 255, 255) !important;
}

.dark-new .deposit-fourth-section th {
  background-color: rgba(255, 255, 255, 0.158);
  color: #fff;
}

.dark-new .deposit-fourth-section tr {
  color: #fff;
}

.dark-new .deposit-fourth-section tbody,
.dark-new .deposit-fourth-section td,
.dark-new .deposit-fourth-section tfoot,
.dark-new .deposit-fourth-section th,
.dark-new .deposit-fourth-section thead,
.dark-new .deposit-fourth-section tr {
  border-color: rgba(255, 255, 255, 0.137);
}

.dark-new .deposit-crypto-modal .modal-content {
  background-color: #14161a;
  box-shadow: none;
}

.dark-new .deposit-modal-coin-heading {
  color: #fff;
}

.dark-new #myUL2 li a:hover:not(.header) {
  background-color: #000;
}

.dark-new .btn-close {
  background-image: url("../assets/images/cross.white.svg");
  opacity: 1;
  box-shadow: none !important;
}

.dark-new .withdraw-crypto-modal .modal-content {
  background-color: #14161a;
  box-shadow: none;
}

.dark-new .withdraw-crypto-network-modal .modal-content {
  background-color: #14161a;
  box-shadow: none;
}

.dark-new .withdraw-crypto-list-items:hover {
  background-color: #000;
}

.dark-new .withdraw-modal-network-sizing:hover {
  background-color: #000;
}

.dark-new .transaction-history-select-section .transaction-form-control {
  color: #fff;
}

.dark-new .markets-second-section table td {
  color: #fff;
}

.dark-new .markets-second-section table tbody tr:hover {
  background-color: #70707050;
}

.dark-new .faq-second-section .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.dark-new .dark-new .faq-second-section .accordion-item {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.205);
}

.dark-new .trading-page-box-left-inner-section {
  border-right: 5px solid #000;
}

.place-order-section-top {
  border-left: 5px solid var(--border) !important;
}

.dark-new .trading-page-order-details-section {
  border-top: 5px solid #000;
}

.dark-new .trading-table-total-height {
  border-bottom: 5px solid #000;
}

.dark-new table {
  color: #fff;
}

.dark-new .place-order-buy-sell-tabs .nav-pills {
  /* border: 1px solid #dbdde6; */
  background-color: transparent;
  border-radius: 5px;
  border: none;
}

.dark-new .select-dropdown-btn-buysell {
  background-color: transparent;
  color: #fff;
}

.dark-new .divider,
.dark-new .divider ~ li {
  background-color: rgb(34, 36, 40);
}

.dark-new .form-check-input {
  filter: brightness(90%) !important;
}

/* ===================================Dark-New-CSS-ENDS===================================================== */

/* ===================================Blue-Whale-CSS-STARTS===================================================== */

.blue-whale .ask-bar {
  background-color: rgba(255, 0, 0, 0.158);
}

.blue-whale .activities-text-3 {
  color: #fff;
}

.blue-whale ::placeholder {
  color: #fff;
}

.blue-whale .trade-buy-sell-button-1 {
  background-color: transparent;
  color: #fff;
}

.blue-whale .trade-text-12 {
  color: #fff;
}

.blue-whale .limit-price-form {
  background-color: transparent;
}

.blue-whale .limit-market-section-bottom .dropdown-item {
  color: #fff;
}

.blue-whale .limit-market-section-bottom .dropdown-item:hover {
  color: #000;
}

.blue-whale
  .limit-market-section-bottom
  .nav-tabs
  .nav-link.active
  .select-dropdown-btn {
  color: #fff;
}

.blue-whale .limit-market-section-bottom .nav-tabs .nav-link.active {
  background-color: #0c495c;
  color: #fff !important;
  border-color: rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418)
    rgba(238, 240, 242, 0.418);
}

.blue-whale .limit-market-section-bottom .nav-tabs {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-whale .trade-search-section {
  background-color: #0c495c;
}

.blue-whale .form-control {
  color: #fff;
}

.blue-whale .trading-page-box {
  border: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-whale .trading-page-top-section-1 {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-whale .paired-trade-table-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-whale .trading-inner-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-whale .trading-page-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-whale .trading-table-right-activities-section {
  border-top: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-whale .navbar-top-hover-dropdown .dropdown-item-hover {
  color: #fff;
}

.blue-whale .navbar-top-hover-dropdown .dropdown-item-hover:hover {
  background-color: #50dcff77;
  color: #fff;
}

.blue-whale
  .navbar-top-hover-dropdown
  .dropdown-item-hover:hover
  .top-nav-dropdown-item-icons-2 {
  color: var(--btnClr);
}

.blue-whale .top-nav-dropdown-item-text-2 {
  color: #fff;
}

/* width */
.blue-whale ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.blue-whale ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.blue-whale ::-webkit-scrollbar-thumb {
  background: #50dcff;
  border-radius: 10px;
}

.blue-whale .form-check-input:checked {
  border: 3px solid #0c3040 !important;
  outline: 2px solid #50dcff;
  background-color: #50dcff;
}

.blue-whale .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid #50dcff;
  background-color: #0c3040;
}

.blue-whale .dropdown-menu {
  background-color: #0c3040;
  color: #50dcff !important;
}

.blue-whale .trading-page-top-section-2 .dropdown-menu {
  background-color: #0c495c;
  color: #fff !important;
}

.blue-whale .trading-page-top-section-2 .dropdown-item {
  color: #fff !important;
}

.blue-whale .trading-page-top-section-2 .dropdown-item:hover {
  background-color: transparent;
}

.blue-whale .themes-dropdown-top-section {
  border-bottom: 1px solid #50dcff70;
}

.blue-whale .deposit-hero-section {
  background-color: #0c495c;
  padding-top: 20px;
  padding-bottom: 20px;
}

.blue-whale .deposit-back-button-icon:hover {
  background-color: #0c3040;
}

.blue-whale .markets-second-section table td {
  color: #fff;
}

.blue-whale .markets-second-section table tbody tr:hover {
  background-color: #0c495c;
}

/* ===================================Blue-Whale-CSS-ENDS===================================================== */

/* ===================================blue-dark-CSS-STARTS===================================================== */

.blue-dark .ask-bar {
  background-color: rgba(255, 0, 0, 0.158);
}

.blue-dark .activities-text-3 {
  color: #fff;
}

.blue-dark ::placeholder {
  color: #fff;
}

.blue-dark .trade-buy-sell-button-1 {
  background-color: transparent;
  color: #fff;
}

.blue-dark .trade-text-12 {
  color: #fff;
}

.blue-dark .limit-price-form {
  background-color: transparent;
}

.blue-dark .limit-market-section-bottom .dropdown-item {
  color: #fff;
}

.blue-dark .limit-market-section-bottom .dropdown-item:hover {
  color: #000;
}

.blue-dark
  .limit-market-section-bottom
  .nav-tabs
  .nav-link.active
  .select-dropdown-btn {
  color: #fff;
}

.blue-dark .limit-market-section-bottom .nav-tabs .nav-link.active {
  background-color: #0c495c;
  color: #fff !important;
  border-color: rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418)
    rgba(238, 240, 242, 0.418);
}

.blue-dark .limit-market-section-bottom .nav-tabs {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-dark .trade-search-section {
  background-color: #0c495c;
}

.blue-dark .form-control {
  color: #fff;
}

.blue-dark .trading-page-box {
  border: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-dark .trading-page-top-section-1 {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-dark .paired-trade-table-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-dark .trading-inner-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-dark .trading-page-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-dark .trading-table-right-activities-section {
  border-top: 1px solid rgba(238, 240, 242, 0.418);
}

.blue-dark .navbar-top-hover-dropdown .dropdown-item-hover {
  color: #fff;
}

.blue-dark .navbar-top-hover-dropdown .dropdown-item-hover:hover {
  background-color: #0084ff6b;
  color: #fff;
}

.blue-dark
  .navbar-top-hover-dropdown
  .dropdown-item-hover:hover
  .top-nav-dropdown-item-icons-2 {
  color: var(--btnClr);
}

/* width */
.blue-dark ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.blue-dark ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.blue-dark ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.blue-dark .form-check-input:checked {
  border: 3px solid #060d1b !important;
  outline: 2px solid #0084ff;
  background-color: #0084ff !important;
}

.blue-dark .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid #0084ff;
  background-color: #060d1b;
}

.blue-dark .dropdown-menu {
  background-color: #060d1b;
  color: #0084ff;
}

.blue-dark .trading-page-top-section-2 .dropdown-menu {
  background-color: #0c495c;
  color: #fff !important;
}

.blue-dark .trading-page-top-section-2 .dropdown-item {
  color: #fff !important;
}

.blue-dark .trading-page-top-section-2 .dropdown-item:hover {
  background-color: transparent;
}

.blue-dark .themes-dropdown-top-section {
  border-bottom: 1px solid #0084ff56;
}

.blue-dark .deposit-hero-section {
  background-color: #36424d;
  padding-top: 20px;
  padding-bottom: 20px;
}

.blue-dark .deposit-back-button-icon:hover {
  background-color: #70707050;
}

.blue-dark .markets-second-section table td {
  color: #fff;
}

.blue-dark .markets-second-section table tbody tr:hover {
  background-color: #36424d;
}

/* ===================================blue-dark-CSS-ENDS===================================================== */

/* ===================================brown-CSS-STARTS===================================================== */

.brown .ask-bar {
  background-color: rgba(255, 0, 0, 0.158);
}

.brown .activities-text-3 {
  color: #fff;
}

.brown ::placeholder {
  color: #fff;
}

.brown .trade-buy-sell-button-1 {
  background-color: transparent;
  color: #fff;
}

.brown .trade-text-12 {
  color: #fff;
}

.brown .limit-price-form {
  background-color: transparent;
}

.brown .limit-market-section-bottom .dropdown-item {
  color: #fff;
}

.brown .limit-market-section-bottom .dropdown-item:hover {
  color: #000;
}

.brown
  .limit-market-section-bottom
  .nav-tabs
  .nav-link.active
  .select-dropdown-btn {
  color: #fff;
}

.brown .limit-market-section-bottom .nav-tabs .nav-link.active {
  background-color: #464646;
  color: #fff !important;
  border-color: rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418)
    rgba(238, 240, 242, 0.418);
}

.brown .limit-market-section-bottom .nav-tabs {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.brown .trade-search-section {
  background-color: #464646;
}

.brown .form-control {
  color: #fff;
}

.brown .trading-page-box {
  border: 1px solid rgba(238, 240, 242, 0.192);
}

.brown .trading-page-top-section-1 {
  border-bottom: 1px solid rgba(238, 240, 242, 0.192);
}

.brown .paired-trade-table-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.192);
}

.brown .trading-inner-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.192);
}

.brown .trading-page-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.192);
}

.brown .trading-table-right-activities-section {
  border-top: 1px solid rgba(238, 240, 242, 0.192);
}

.brown .navbar-top-hover-dropdown .dropdown-item-hover {
  color: #fff;
}

.brown .navbar-top-hover-dropdown .dropdown-item-hover:hover {
  background-color: #ebebeb;
  color: #000;
}

.brown
  .navbar-top-hover-dropdown
  .dropdown-item-hover:hover
  .top-nav-dropdown-item-icons-2 {
  color: var(--btnClr);
}

/* width */
.brown ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.brown ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.brown ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.brown .form-check-input:checked {
  background-color: #fff;
  border: 3px solid #000 !important;
  outline: 2px solid #fff;
}

.brown .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid #fff;
  background-color: #141414;
}

.brown .dropdown-menu {
  background-color: #141414;
  color: #fff !important;
}

.brown .trading-page-top-section-2 .dropdown-menu {
  background-color: #464646;
  color: #fff !important;
}

.brown .trading-page-top-section-2 .dropdown-item {
  color: #fff !important;
}

.brown .trading-page-top-section-2 .dropdown-item:hover {
  background-color: transparent;
}

.brown .themes-dropdown-top-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.281);
}

.brown .deposit-hero-section {
  background-color: #373737;
  padding-top: 20px;
  padding-bottom: 20px;
}

.brown .deposit-back-button-icon:hover {
  background-color: #70707050;
}

.brown .markets-second-section table td {
  color: #fff;
}

.brown .markets-second-section table tbody tr:hover {
  background-color: #373737;
}

/* ===================================brown-CSS-ENDS===================================================== */

/* ===================================dark-black-CSS-STARTS===================================================== */

.dark-black .ask-bar {
  background-color: rgba(255, 0, 0, 0.158);
}

.dark-black .activities-text-3 {
  color: #fff;
}

.dark-black ::placeholder {
  color: #fff;
}

.dark-black .trade-buy-sell-button-1 {
  background-color: transparent;
  color: #fff;
}

.dark-black .trade-text-12 {
  color: #fff;
}

.dark-black .limit-price-form {
  background-color: transparent;
}

.dark-black .limit-market-section-bottom .dropdown-item {
  color: #fff;
}

.dark-black .limit-market-section-bottom .dropdown-item:hover {
  color: #000;
}

.dark-black
  .limit-market-section-bottom
  .nav-tabs
  .nav-link.active
  .select-dropdown-btn {
  color: #fff;
}

.dark-black .limit-market-section-bottom .nav-tabs .nav-link.active {
  background-color: #464646;
  color: #fff !important;
  border-color: rgba(238, 240, 242, 0.418) rgba(238, 240, 242, 0.418)
    rgba(238, 240, 242, 0.418);
}

.dark-black .limit-market-section-bottom .nav-tabs {
  border-bottom: 1px solid rgba(238, 240, 242, 0.418);
}

.dark-black .trade-search-section {
  background-color: #464646;
}

.dark-black .form-control {
  color: #fff;
}

.dark-black .trading-page-box {
  border: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-page-top-section-1 {
  border-bottom: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .paired-trade-table-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-inner-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-page-left-section {
  border-right: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .trading-table-right-activities-section {
  border-top: 1px solid rgba(238, 240, 242, 0.192);
}

.dark-black .navbar-top-hover-dropdown .dropdown-item-hover {
  color: #fff;
}

.dark-black .navbar-top-hover-dropdown .dropdown-item-hover:hover {
  background-color: #ebebeb;
  color: #000;
}

.dark-black.navbar-top-hover-dropdown
  .dropdown-item-hover:hover
  .top-nav-dropdown-item-icons-2 {
  color: var(--btnClr);
}

/* width */
.dark-black ::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.dark-black ::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.dark-black ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.dark-black .form-check-input:checked {
  background-color: #fff;
  border: 3px solid #000 !important;
  outline: 2px solid #fff;
}

.dark-black .form-check-input {
  border: 2px solid transparent;
  outline: 2px solid #fff;
  background-color: #000000;
}

.dark-black .dropdown-menu {
  background-color: #000000;
  color: #fff !important;
}

.dark-black .trading-page-top-section-2 .dropdown-menu {
  background-color: #464646;
  color: #fff !important;
}

.dark-black .trading-page-top-section-2 .dropdown-item {
  color: #fff !important;
}

.dark-black .trading-page-top-section-2 .dropdown-item:hover {
  background-color: transparent;
}

.dark-black .themes-dropdown-top-section {
  border-bottom: 1px solid rgba(238, 240, 242, 0.281);
}

.dark-black .deposit-hero-section {
  background-color: #373737;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dark-black .deposit-back-button-icon:hover {
  background-color: #70707050;
}

.dark-black .markets-second-section table td {
  color: #fff;
}

.dark-black .markets-second-section table tbody tr:hover {
  background-color: #373737;
}

/* ===================================dark-black-CSS-ENDS===================================================== */

/* ===================================MODES-CSS-ENDS===================================================== */

/* .light-new .dark-new-widget {
  display: none !important;
}

.dark-new .light-new-widget {
  display: none !important;
}

.dark-new .dark-new-widget {
  display: block !important;
} */
.btn {
  box-shadow: none;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: red !important;
} */
.datatablecss p {
  margin-bottom: 0px;
}

/* body, .chart-controls-bar, #footer-chart-panel {
  background-color: #d20404 !important;
} */
/* .order-book-price-hover-list-section:nth-last-child(2):hover{
  background-color: rgba(36, 43, 58, 0.067);
} */

span.order-released {
  color: green !important;
}

.divider,
.divider ~ li {
  background-color: rgba(36, 43, 58, 0.067);
}

.divider2 {
  background-color: rgba(36, 43, 58, 0.067);
}

.divider2:hover ~ li {
  background-color: transparent;
}

.spot-page-top-banner ul {
  list-style: none;
  margin: 0px;
  padding: 0px !important;
}

.terms-second-section p {
  font-size: 14px;
}

.terms-second-section h4 {
  margin-top: 50px;
}

.terms-second-section h2 {
  margin-top: 50px;
  margin-bottom: 30px;
}

.nav-tabs-dashboard .nav-link {
  color: inherit;
  padding: 8px 2vw !important;
}

.dashboard-spot-trade-history-section th {
  font-weight: 500;
  align-items: center;
  font-size: 12px;
  color: rgb(112, 122, 138);
  background-color: transparent;
  border: 0px;
  padding: 4px 4px;
  letter-spacing: 0.28px;
  vertical-align: middle;
  border-bottom: 1px solid #dbdde6;
}

.dashboard-spot-trade-history-section td {
  font-weight: 400;
  align-items: center;
  font-size: 14px;
  background-color: transparent;
  border: 0px;
  padding: 4px 4px;
  letter-spacing: 0.28px;
  vertical-align: middle;
  border-bottom: 1px solid #dbdde6;
}

.dashboard-spot-trade-width-section th:nth-child(6),
.dashboard-spot-trade-width-section td:nth-child(6) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(7),
.dashboard-spot-trade-width-section td:nth-child(7) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(8),
.dashboard-spot-trade-width-section td:nth-child(8) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(9),
.dashboard-spot-trade-width-section td:nth-child(9) {
  text-align: center !important;
}

.dashboard-spot-trade-width-section th:nth-child(10),
.dashboard-spot-trade-width-section td:nth-child(10) {
  text-align: center !important;
}

.dashboard-spot-trade-history-section .table > :not(:first-child) {
  border-top: 0px solid currentColor;
}

.dashboard-spot-trade-history-section tr {
  height: 70px;
}

.trading-page-order-table-details-section th:nth-child(6),
.trading-page-order-table-details-section td:nth-child(6) {
  text-align: center !important;
}

.trading-page-order-table-details-section th:nth-child(7),
.trading-page-order-table-details-section td:nth-child(7) {
  text-align: center !important;
}

.trading-page-order-table-details-section th:nth-child(8),
.trading-page-order-table-details-section td:nth-child(8) {
  text-align: center !important;
}

.trading-page-order-table-details-section th:nth-child(9),
.trading-page-order-table-details-section td:nth-child(9) {
  text-align: center !important;
}

.trading-page-order-table-details-section th:nth-child(10),
.trading-page-order-table-details-section td:nth-child(10) {
  text-align: center !important;
}

.pagination {
  margin-left: 0px !important;
}

.trading-page-order-table-details-section .table {
  margin-bottom: 0px !important;
}

.navbar-toggler {
  background-color: var(--primary-color);
  border: none !important;
}

.navbar-toggler:focus {
  border: none !important;
}

.offcanvas-body .nav-link {
  color: var(--color-1);
}

.popup-modal-button-my-orders {
  background-color: var(--btnClr);
  color: #fff !important;
  border: none;
  padding: 0px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  top: -2px;
}

.order-bookexchange-modal .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border-top: 5px solid var(--btnClr);
}

.order-bookexchange-modal-text-1 {
  font-size: 18px;
  color: red;
}

.order-bookexchange-modal-text-2 {
  font-size: 14px;
  font-weight: 600;
}

.order-bookexchange-modal-text-3 {
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 10px;
}

.order-bookexchange-modal-text-4 {
  font-size: 12px;
  margin-bottom: 10px;
}

.order-bookexchange-modal table {
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.order-bookexchange-modal table tbody tr {
  border-bottom: 1px solid #ddd;
}

.order-bookexchange-modal table th:last-child {
  text-align: end;
}

.order-bookexchange-modal table td:last-child {
  text-align: end;
}

.order-bookexchange-modal table th,
.order-bookexchange-modal table td {
  padding: 0.425em;
}

.order-bookexchange-modal table th {
  font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: 400;
  opacity: 0.6;
}

.order-bookexchange-modal table td {
  font-size: 13px;
}

@media screen and (max-width: 600px) {
  .order-bookexchange-modal table {
    border: 0;
  }

  .order-bookexchange-modal table caption {
    font-size: 1.3em;
  }

  .order-bookexchange-modal table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .order-bookexchange-modal table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .order-bookexchange-modal table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .order-bookexchange-modal table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.order-bookexchange-modal table {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 180px;
}

.order-bookexchange-modal tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.order-bookexchange-modal tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.dark-new .order-bookexchange-modal .modal-content {
  background-color: #14161a;
}

.dark-new .order-bookexchange-modal .card {
  background-color: #14161a;
  border: 1px solid #c7c7c791;
  border-top: 5px solid var(--btnClr);
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .order-bookexchange-modal .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}

.css-b62m3t-container {
  width: 200px;
}

.css-xc836r-control {
  padding: 4px;
}

.description {
  width: 100%;
}

/*======================MEDIUM-SCREEN-RESPONSIVE=======================*/

@media (min-width: 991px) and (max-width: 1229px) {
  .trading-page-section-1 {
    margin-bottom: 13px;
  }

  .orderbook-text-2 {
    margin: 0px;
    font-family: sans-serif;
    line-height: 1.5;
    font-size: 10px !important;
    font-weight: 600;
    letter-spacing: 0.26px;
  }
}

#content {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: scroll;
}

#content::-webkit-scrollbar {
  display: none;
}

.bars-loading-loader {
  margin-top: 20% !important;
}

.crypto-deposit-loading-loader {
  margin-right: 146px !important;
}

.p2p-user-center-blocked-user-table-sticky thead {
  display: table;
  width: 100%;
}

.p2p-user-center-blocked-user-table-sticky .text-muted:nth-child(2) {
  width: 20% !important;
}

.p2p-user-center-blocked-user-table-sticky tbody {
  display: block;
  /* to enable vertical scrolling */
  max-height: 380px;
  /* e.g. */
  overflow-y: scroll;
  /* keeps the scrollbar even if it doesn't need it; display purpose */
}

.p2p-user-center-blocked-user-table-sticky th,
.p2p-user-center-blocked-user-table-sticky td {
  width: auto;
  /* to enable "word-break: break-all" */
  padding: 5px;
  word-break: break-all;
  /* 4. */
}

.p2p-user-center-blocked-user-table-sticky tr {
  display: table;
  /* display purpose; th's border */
  width: 100%;
  box-sizing: border-box;
  /* because of the border (Chrome needs this line, but not FF) */
}

.payment-qrcode-optional-button {
  border: 1px solid rgb(177, 177, 177);
  font-size: 13px;
  padding: 2px 30px;
  color: gray;
  font-family: sans-serif;
}

.tableFixHead {
  overflow: auto !important;
  height: 350px !important;
}

.tableFixHead thead th {
  position: sticky !important;
  top: 0 !important;
  background-color: #dee2e6 !important;
  z-index: 999;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.loan-page-icon {
  margin: 0.5em;
  color: #00dbae !important;
}

.loan-page-icon-image {
  width: 50px;
  height: 50px;
  color: #00dbae;
}

.trading-page-profile-icon-image {
  width: 50px;
  height: 50px;
}

.loan-page-icon-image-table {
  width: 30px;
  height: 30px;
  color: #00dbae;
}

/*==========================Table-sorting=========================*/
.css-1ygcj2i-MuiTableCell-root {
  border: none !important;
}

.css-1ndpvdd-MuiTableCell-root {
  padding-top: 0px !important;
}

.coin-icon-sizing-data-table {
  width: 30px;
  height: 30px;
}

.text-grey {
  color: #8a8a8a;
}

.text-black-grey {
  color: #495057;
}

.bg-grey {
  background-color: rgb(245, 245, 245) !important;
}

/*===================Search-select===================*/
.css-b62m3t-container {
  position: absolute !important;
  box-sizing: border-box;
  /* z-index: 9999 !important; */
  background-color: var(--background-color-1);
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.css-r2ji3x-control {
  min-width: 40px !important;
}

.css-17h53pk-control {
  min-width: 40px !important;
}

.css-1n6sfyn-MenuList {
  max-height: 200px !important;
  overflow-y: scroll !important;
}

#flexibleratemodal .modal-dialog {
  max-width: 650px !important;
}

#borrowmodal .modal-dialog {
  max-width: 650px !important;
}

#wanttoborrowmodal .modal-dialog {
  max-width: 650px !important;
}

.loan-input {
  box-sizing: border-box;
  margin: 4px 0px 0px;
  min-width: 0px;
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1.6;
  height: 48px;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  width: 100%;
}

.input-amt-field {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
}

.loan-input input {
  color: rgb(30, 35, 41);
  font-size: 14px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
}

.loan-input .lon-suffix {
  flex-shrink: 0;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
}

.loan-input-field {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  outline: none;
  border: none;
  background-color: inherit;
  opacity: 1;
}

.loan-input-select {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}

.loan-select-field {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.loan-line-bar {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(234, 236, 239);
}

.select-coin-field {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  cursor: pointer;
}

.select-coin {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  outline: none;
  pointer-events: none;
}

.coin-dropdown-field {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1.6;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  background-color: transparent;
  height: 48px;
  width: 100%;
  cursor: pointer;
  border-color: transparent !important;
}

.select-coin .bn-sdd-input {
  width: 100px;
}

.coin-dropdown-field input {
  color: rgb(30, 35, 41);
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
}

.field-space {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding-top: 17px;
}

.coin-field {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  outline: none;
  border: none;
  opacity: 1;
  visibility: hidden;
  cursor: pointer;
  background-color: transparent;
}

.coin-dropdown-field .lon-suffix {
  height: 100%;
  margin-right: 8px;
  flex-shrink: 0;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
}

.loan-dropdown-svg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.down-svg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: currentColor;
  width: 16px;
  height: 16px;
  font-size: 16px;
  fill: #1e2329;
  fill: currentColor;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  color: #707a8a;
  width: 1em;
  height: 1em;
}

.max-btn :disabled :not(.inactive) {
  background-color: transparent;
  color: rgb(183, 189, 198);
}

.max-btn :disabled {
  cursor: not-allowed;
  background-image: none;
  background-color: rgb(234, 236, 239);
  color: rgb(183, 189, 198);
}

.max-btn {
  margin: 0px;
  appearance: none;
  user-select: none;
  cursor: not-allowed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  outline: none;
  padding: 6px 12px;
  line-height: 20px;
  min-width: 52px;
  word-break: keep-all;
  /* color: rgb(201, 148, 0); */
  border-radius: 4px;
  min-height: 24px;
  border: none;
  background-color: transparent;
  color: rgb(183, 189, 198);
}

.rate-list {
  box-sizing: border-box;
  margin: 16px 0px 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
}

.annual-rate {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  justify-self: flex-end;
  text-align: right;
}

#input-bg-table .form-control {
  background-color: transparent !important;
  border: none;
}

#wanttoborrowmodal .form-control {
  background-color: transparent !important;
  border: none;
}

#collateralmodal .form-control {
  background-color: transparent !important;
  border: none;
}

.copytrading-search-css .form-control {
  background-color: transparent !important;
  border: none;
}

.wanttoborrow-table-height-css {
  height: 300px;
  overflow-y: scroll;
}

.vertical-line-css {
  width: 150px;
  height: 50px;
}

.favorites-css {
  background-color: #eaecef;
  border-radius: 20px;
  padding: 0.5em;
}

.dark-new .favorites-css {
  background-color: #000000;
  border-radius: 20px;
  padding: 0.5em;
}

.copytrading-card-css-styling {
  border-radius: 16px;
  padding: 2em;
  background-color: #eaecef;
  cursor: pointer;
}

.dark-new .copytrading-card-css-styling {
  border-radius: 16px;
  padding: 2em;
  background-color: #303236;
  cursor: pointer;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00dbae !important;
  background: linear-gradient(
    270deg,
    rgb(242, 246, 250) 0%,
    rgba(245, 248, 252, 0) 100%
  );
  padding: 20px;
}

/* 
.glass-effects-swiper-styling-right:hover {
  background: linear-gradient(
    270deg,
    rgb(242, 246, 250) 0%,
    rgba(245, 248, 252, 0) 100%
  );
  opacity: 1;
  cursor: pointer;
  z-index: 5;
} */

.copy-tradings-tab-2-active .nav-pills .nav-link.active {
  color: #00dbae !important;
  background-color: rgb(209, 255, 209) !important;
}

.dropdown-bg-styling {
  color: #00dbae !important;
  background-color: rgb(209, 255, 209) !important;
}

.copy-tradings-tab-2-active .nav-link {
  color: #495057 !important;
}

.cursor-poniter {
  cursor: pointer;
}

#tradingsettingsModal .modal-header {
  border-bottom: none !important;
}

.copy-tradings-tab-2-active-modal .nav-pills .nav-link.active {
  color: #00dbae !important;
  background-color: rgb(209, 255, 209) !important;
  border-radius: 3em;
}

.copy-tradings-tab-2-active-modal .nav-link {
  color: #000 !important;
  font-size: 13px !important;
}

.table-nav-tabs-css .nav-pills .nav-link.active {
  color: #00dbae !important;
  font-size: 20px !important;
  background-color: transparent;
  font-weight: 550;
}

.dark-new .table-nav-tabs-css .nav-pills .nav-link.active {
  color: #00dbae !important;
  font-size: 20px !important;
  background-color: transparent;
  font-weight: 550;
}

.table-nav-tabs-css .nav-pills .nav-link {
  font-size: 20px !important;
  font-weight: 550;
  color: #495057 !important;
}

.dark-new .table-nav-tabs-css .nav-pills .nav-link {
  font-size: 20px !important;
  font-weight: 550;
  color: #fff !important;
}

.dropdown-after-effects-css .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: #495057 !important;
}

.height-adjustments {
  height: 300px !important;
}

.copytrading-table-section-css table th {
  background-color: transparent !important;
  border-bottom: 1px solid #000000 !important;
}

.previewimagemodal-css {
  /* display: none; */
  position: absolute;
  bottom: 100px;
  border: 3px solid #00dbae;
}

.previewimage-size-css {
  width: 100%;
  height: 100%;
}

.danger-icon-pending-order {
  height: 50px !important;
}

/* #scrollactivatesection { 
  opacity: 0;
  transform: translateX(-300px);
  transition: all 0.5s ease;
}


.animate { 
  transform: translateY(0px);
  opacity: 1 !important;
}
@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 35px); }
  to   { transform: translate(0, -0px); }    
} */

/*==============================THIRD-SIZE-RESPONSIVE==================================*/
@media screen and (max-width: 1800px) and (min-width: 1000px) {
  .f-35 {
    font-size: 16px;
  }

  .f-25 {
    font-size: 13px;
  }

  #scrollmodalopen {
    width: 500px;
    margin: auto;
    position: relative;
    top: 20vh;
  }

  .scrollmodalopenimg {
    width: 500px;
    height: 200px;
  }

  .crossclose-img {
    width: 20px;
    height: 20px;
  }

  .scrollmodal-bg {
    background-color: #03125e;
    width: 500px;
    height: 200px;
    color: #ffffff !important;
  }
}

.loan-history-css-changes .p2p-trade-table .p2p-trade-table-tr {
  background-color: var(--background-color-2) !important;
  border: 1px solid var(--border-color-1);
}

.loan-history-css-changes .table-head-sticky table tbody {
  display: block;
  max-height: 1000px;
  overflow: auto;
  margin-bottom: 2vh;
}

#loanhistorycollapse .p2p-trade-table .p2p-trade-table-tr {
  background-color: transparent !important;
  border: 1px solid var(--border-color-1);
}

.trading-competion-section-1-box-loan-history {
  padding: 20px;
  border-radius: 0px;
  background-color: var(--background-color-3);
}

.trading-competion-section-1-box-loan-history-updated {
  padding: 20px 20px 0px 20px;

  border-radius: 0px;
  background-color: var(--background-color-3);
}

#loanhistorycollapse .card {
  padding: 0px 13px 13px 13px;
  border-radius: 0px;
  background-color: var(--background-color-3);
}

#loanhistorycollapse {
  animation: fade-in 2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loan-history-css-changes table {
  background-color: transparent !important;
}

#loanhistorycollapse {
  display: none;
}

.repay-button-css {
  background-color: var(--btnClr);
  color: inherit;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.banner-top-button-2-repay {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 10px 15px;
  position: relative;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  background: #20fdee;
  color: #ffffff !important;
  background: linear-gradient(to right, var(--btnClr), #0478e9);
}

.trading-competition-dashboard-column-2-bg {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 90% 90% !important;
  padding: 5em 3em 5em 3em;
  object-fit: center !important;
}

.trading-competition-banner-column-2-bg {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% 150px !important;
  padding: 6em 0em 6em 0em;
  object-fit: center !important;
}

.select-color-change {
  color: var(--color-1) !important;
  background-color: var(--background-color-2);
  padding: 0.2em;
}

/* .device-management-table {
  width: 800px !important;

}
.device-management-table table{

  overflow-x: scroll !important;
} */

.add-payment-method-top-banner .form-control-payment-method {
  color: #000;
  background-color: transparent;
}

.line-height-be-a-lead {
  line-height: 3em;
}

.bealead-section-styling .input-group > .form-control::placeholder {
  font-size: 12px !important;
}

.bealead-section-styling .input-group > .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--border-color-1);
}

.bealead-section-styling .input-group > .form-control {
  border: 1px solid var(--border-color-1);
}

.lapman-css-styling {
  width: 400px;
  height: 400px;
}

.bealead-column-bg {
  padding: 4em 4em 1em 4em;
}

.be-a-lead-row-bg-css {
  background-color: var(--background-color-4);
  padding: 3em 3em 0em 3em;
  border-top-left-radius: 0.7em;
  border-top-right-radius: 0.7em;
  border-bottom-left-radius: 0.7em;
  border-bottom-right-radius: 7em;
  border: 1px solid var(--border-color-2);
}

.jqueryavatar {
  opacity: 1;
}

.active-opacity {
  opacity: 1 !important;
}

.inactive-opacity {
  opacity: 0.6;
}

.copytrader-details-section .form-control {
  background-color: transparent !important;
  padding: 0px;
}

.copytrader-details-section .form-control::placeholder {
  color: #000;
}

.plus-minus-css-styling {
  font-size: 23px !important;
  color: #00dbae;
  font-family: 600 !important;
}

/*=========================API-PAGE-SECTION-STARTS=====================================*/

.api-page-top-section {
  padding-top: 65px;
}

.api-page-top-section .nav-pills .nav-link.active {
  color: #fff !important;
  background-color: var(--btnClr);
}

.api-third-column-background-styling {
  background-color: #000;
  color: #f0b90b;
  width: 400px;
  overflow-wrap: break-word;
}

.margin-bottom-api {
  margin-bottom: 5em !important;
}

.api-page-top-section .accordion-item {
  background-color: transparent !important;
  border: none !important;
}

.api-page-top-section .accordion-button {
  background-color: transparent !important;
  border: none !important;
  padding-left: 0px;
  padding-right: 0px;
  text-align: start;
}

.api-page-top-section .accordion-button::after {
  display: none;
}

.api-page-top-section .nav-pills .nav-link {
  color: #ffffff !important;
}

.api-page-top-section pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  padding-bottom: 1em !important;
}

/* Track */
.tab-main-column-2-css ::-webkit-scrollbar-track {
  background: #000000 !important;
}

/* Handle */
.tab-main-column-2-css ::-webkit-scrollbar-thumb {
  background: #141414 !important;
  border-radius: 5px;
}

.column-heading-color {
  background-color: #141414;
}

.column-heading-paragraph-color {
  background-color: #242424;
}

/* / ============ DESKTOP-VIEW ============ / */

@media all and (min-width: 992px) {
  /*=================================SIDENAV-START======================================*/

  .sidenav {
    height: 100vh;
    width: 300px;
    z-index: 9999;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: 0.5s;
  }

  .sidenav .sidenavbar-close {
    display: none;
  }

  .sidenavbar-open {
    display: none;
  }

  .api-page-top-section .tab-main-column-color-theme {
    position: fixed;
    padding-top: 20px;
    max-width: 300px;
    background-color: #11003b;
    left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    overflow-y: scroll;
    height: 100vh !important;
  }

  .api-page-top-section .tab-main-column-2-css {
    margin-left: 320px;
  }

  /*=================================SIDENAV-ENDS=======================================*/
}

/* / ============ MOBILE-VIEW ============ / */

@media (max-width: 991px) {
  .position-fixed-api-doc {
    position: -webkit-sticky;
    position: sticky;
    top: 10vh;
    width: 50px;
  }

  .coin-price-container img {
    width: 100%;
    height: 100%;
  }

  .offcanvas .dropdown-toggle::after {
    top: 27px !important;
    right: -14px !important;
    z-index: -1;
  }

  .right-side,
  .left-side {
    display: flex;
  }

  .right-side {
    justify-content: end;
  }

  .left-side {
    justify-content: start;
  }

  .trading-page-order-table-details-section th:nth-child(6),
  .trading-page-order-table-details-section td:nth-child(6),
  .trading-page-order-table-details-section th:nth-child(7),
  .trading-page-order-table-details-section td:nth-child(7),
  .trading-page-order-table-details-section th:nth-child(8),
  .trading-page-order-table-details-section td:nth-child(8),
  .trading-page-order-table-details-section th:nth-child(9),
  .trading-page-order-table-details-section td:nth-child(9),
  .trading-page-order-table-details-section th:nth-child(10),
  .trading-page-order-table-details-section td:nth-child(10) {
    text-align: right !important;
  }

  .trading-page-order-table-details-section tbody td {
    /* text-align: center !important; */
  }

  .three-dots-nav-styling {
    font-size: 30px !important;
    cursor: pointer !important;
    color: #00dbae !important;
    background-color: #11003b;
  }

  .api-second-column-background-styling {
    width: 100vw;
    overflow-wrap: break-word !important;
    word-wrap: break-word;
  }

  /*===================================SIDENAV-START==========================================*/
  .api-page-top-section .tab-main-column-color-theme {
    position: fixed;
    padding-top: 20px;

    left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    overflow-y: scroll;
    height: 100vh !important;
  }

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #401091;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav .sidenavbar-close {
    position: relative;
    top: 0;
    right: 0px;
    font-size: 36px;
    margin-left: 50px;
    color: #00dbae;
    text-align: end;
  }

  /*===================================SIDENAV-ENDS===========================================*/
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.bell-icon-dropdown-css .dropdown-toggle::after {
  display: none;
}

#classy-navbar-mobile .bell-icon-dropdown-css .dropdown-menu {
  min-width: 30rem !important;
  background-color: var(--background-color-2);
}

.bell-icon-notification-bg {
  background-color: rgb(207, 207, 207) !important;
}

.crypto-loan-input-field {
  display: flex !important;
  justify-content: space-between !important;
  padding: 1rem !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(234, 236, 239);
  cursor: pointer;
}

/* bg-light-green-2 p-1 mx-2 */
.crypto-loan-low-rate {
  background-color: rgb(14, 203, 129);
  border-radius: 0.5em;
}

/* .bell-icon-dropdown-css .dropdown-menu[data-bs-popper]{
  right: 0 !important;
} */

#tradingviewwidget-linechart-bg-image {
  position: absolute;
  top: 0px;
  z-index: 9;
}

/*=========================API-PAGE-SECTION-ENDS=======================================*/

#tradingviewwidget-linechart-bg-image {
  position: absolute;
  top: 0px;
  z-index: 9;
}

.container-timeline {
  position: relative;
  overflow: hidden;
}

.container-timeline:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #ccd1d9;
  z-index: 1;
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 30px;
  height: 30px;
  /* border-radius: 50%; */
  background: #b8b8b8;
  z-index: 9999;
  text-align: center;
  position: relative;
  left: 4px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.marker.active {
  width: 20px;
  height: 20px;
  background: #00dbae;
  left: 0px;
}

.timeline-content {
  width: 95%;
  padding: 0 15px;
}

.timeline-block {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.timeline-block-right {
  float: none;
}

.timeline-block-left {
  float: none;
  direction: ltr;
}


a.launchExchange:hover {
  background-color: #e3b019;
  color: white;
}
