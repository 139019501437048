:root {
  /* common theme */
  --whiteClr: white;
  --blackClr: black;
  --redClr: red;
  --primary-color:#e7c76c;
  --primary-text-color:#000;
  --blueToBlack:#292735;
  /* --homepageBgLinear : linear-gradient(160deg, #27187b, #16014e);
  --homepageBgColor : #11003b; */
  --homepageBgLinear : linear-gradient(160deg,#292735,#332e41);
  --homepageBgColor : #292735;  
  --ordebookHeight: 620px;
  --mapHeight: 620px;
}

:root .light-new {
  --whiteBlack: white;
  --whiteBlackOpp: black;
  --btnClr: #00dbae;
  --btnHoverClr: transparent;
  --resendClr: #00dbae;
  --chatPopupBg: #95ea97;
  --chatsellPopupBg : #ee8181;

  --color-1: #000000;
  --border-color-1: #bbbbbb;
  --background-color-1: rgb(250, 250, 250);
  --background-color-2: #ffffff;
  --background-color-3: rgb(245, 245, 245);
  --background-color-4: rgb(243, 243, 243);
  --border-color-2: #dbdbdb;
  --input-box-bg:rgba(243,245,247,1);
  --input-box-text-color:#000;
  --tab-text-color:#ffffff;
  --std-color: #292735;
}

:root .dark-new {
  --whiteBlack: black;
  --whiteBlackOpp: white;
  --btnClr: #00dbae;
  --btnHoverClr: transparent;
  --resendClr: #00dbae;
  --chatPopupBg: #fef6d8;

  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #14161a;
  --background-color-2: #303236;
  --background-color-3: #14161a;
  --background-color-4: rgb(15, 15, 15);
  --border-color-2: #303236;
  --input-box-bg:rgba(37,40,44,1);
  --input-box-text-color:#ffffff;
  --tab-text-color:#ffffff;
  --std-color: #292735;
}

:root .blue-whale {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #0c495c;
  --background-color-2: #29677a;
}

:root .blue-dark {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #36424d;
  --background-color-2: #45505a;
}

:root .brown {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #373737;
  --background-color-2: #686868;
}

:root .dark-black {
  --color-1: #ffffff;
  --border-color-1: #c7c7c791;
  --background-color-1: #1d1d1d;
  --background-color-2: #111010;
}